import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import ProfileSelector from 'features/profileSelector/ProfileSelector';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ProductSelector from 'features/order/ProductSelector';
import ProductOperationWd from 'features/productOperation/ProductOperationWd';
import ProductOperationDp from 'features/productOperation/ProductOperationDp';
import TransactionSaver from 'features/order/TransactionSaver';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import {  find, get, reduce, times } from 'lodash';
import LastTransactionViewer from 'features/lastTransactionViewer/LastTransactionViewer';
import feathers from 'services/feathers';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import GameIdSelector from 'features/gameIdSelector/GameIdSelector';
import { useAuth } from 'hooks/useAuth';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import ConfirmNavigation from 'features/order/ConfirmNavigation';
import {
  Button,
  ButtonGroup
} from '@mui/material';

// redux store
import { useDispatch, useSelector } from 'react-redux';

import * as tfActions from './store/actions';
import * as tfSelectors from './store/selectors';
import { getProfile } from 'features/profileSelector/store/selectors';
import { resetUi as resetProfileSelector } from 'features/profileSelector/store/actions';

const REDUCER_PREFIX = 'tf';

export default function Transfer () {
  const dispatch = useDispatch();
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ confirmationText, setConfirmationText ] = useState('');
  const selectedProfile = useSelector(getProfile(REDUCER_PREFIX));
  const productCount = useSelector(tfSelectors.getProductCount);
  const selectedSrcProductId = useSelector(tfSelectors.getSelectedSrcProductId);
  const selectedSrcGameId = useSelector(tfSelectors.getSelectedSrcGameId);
  const selectedDestProductIds = useSelector(tfSelectors.getSelectedDestProductIds);
  const selectedDestGameIds = useSelector(tfSelectors.getSelectedDestGameIds);
  const currentDestProductId = useSelector(tfSelectors.getCurrentDestProductId);
  const currentDestGameId = useSelector(tfSelectors.getCurrentDestGameId);
  const amount = useSelector(tfSelectors.getAmount);
  const point = useSelector(tfSelectors.getPoint);
  const voidAmount = useSelector(tfSelectors.getVoidAmount);
  const splitPoint = useSelector(tfSelectors.getSplitPoint);
  const currentSplitPoint = useSelector(tfSelectors.getCurrentSplitPoint);
  const productIndex = useSelector(tfSelectors.getProductIndex);
  const transactionDt = useSelector(tfSelectors.getTransactionDt);
  const remark = useSelector(tfSelectors.getRemark);
  const isIdle = useSelector(tfSelectors.getIsIdle);
  const voidTransactions = useSelector(tfSelectors.getVoidTransactions);
  const expanded = useSelector(tfSelectors.getExpanded);
  const successWdCount = useSelector(tfSelectors.getSuccessWdCount);
  const successDpCount = useSelector(tfSelectors.getSuccessDpCount);

  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const promptOnRouteTransition = get(settings, 'promptOnRouteTransition', true);
  const blocker = useBlocker(!!selectedProfile && promptOnRouteTransition);

  const handleChange = (panel) => (event, isExpanded) => {
    dispatch(tfActions.updateExpanded(isExpanded ? panel : false));
  };

  const {
    kiosks: products,
    kiosksReady: productsReady,
    kiosksError: productsError
  } = useContext(CommonContext);

  useEffect(() => {
    if (!productsError) return;
    if (productsError) {
      setGlobalErrorMessage(productsError);
      return;
    }
  }, [productsError, setGlobalErrorMessage]);

  useEffect(() => {
    if (!selectedProfile) return;
    dispatch(tfActions.updateTransactionDt(new Date()));
  }, [dispatch, selectedProfile]);

  const onProductSelected = (productId) => {
    dispatch(tfActions.updateSelectedSrcProductId(productId));
  };

  const onRemarkChanged = (event) => {
    event.preventDefault();
    dispatch(tfActions.updateRemark(event.target.value));
  };

  const onTransactionDtChanged = (dt) => {
    dispatch(tfActions.updateTransactionDt(dt));
  };

  const onOperationChangedCallback = useCallback(
    (status) => {
      dispatch(tfActions.updateStatus(status));
    }, [dispatch]
  );

  const generateProductData = () => {
    let products = [];

    const fromProduct = getProductFromId(selectedSrcProductId);
    if (!fromProduct) throw new Error('Invalid / undefined product.');
    const fromProductType = get(fromProduct, 'type');
    products.push({
      productType: fromProductType,
      amount: amount,
      point: point,
      action: 'withdrawal',
      username: selectedSrcGameId
    });

    for (var i = 0; i < productCount; i++) {
      const toProduct = getProductFromId(selectedDestProductIds[i]);
      if (!toProduct) throw new Error('Invalid / undefined product.');
      const toProductType = get(toProduct, 'type');
      const toPoint = splitPoint[i];
      const gameId =  selectedDestGameIds[i];
      products.push({
        productType: toProductType,
        amount: toPoint,
        point: toPoint,
        action: 'deposit',
        username: gameId
      });
    }
    return products;
  };
  const onSaveClicked = async (event) => {
    try {
      dispatch(tfActions.updateStatus('saving'));
      const profileId = get(selectedProfile, '_id');
      const productData = generateProductData();

      const data = {
        profileId,
        products: productData,
        amount: 0,
        remark,
        transactionDt,
        action: 'transfer'
      };

      await feathers.service('transactions').create(data);
      setGlobalMessage(t('Transfer saved'), 'success');
      newOrder();
      dispatch(tfActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(tfActions.updateStatus('idle'));
    }
  };

  const tryToSave = (event) => {
    if (successWdCount <= 0) {
      setConfirmationText(t('Save without point withdrawal'));
      setConfirmOpen(true);
    } else if (successDpCount < productCount) {
      setConfirmationText(t('Save without point deposit'));
      setConfirmOpen(true);
    } else {
      onSaveClicked(event);
    }
  };

  function newOrder () {
    dispatch(tfActions.resetUi());
    dispatch(resetProfileSelector(REDUCER_PREFIX));
  };

  const getSelectedProductFromId = () => {
    return find(products, { _id: selectedSrcProductId }) || null;
  };

  const onSplitPointChanged = (event) => {
    event.preventDefault();
    const value = event.target.value;
    dispatch(tfActions.updateCurrentSplitPoint(value));
  };

  const onProductIndexChanged = (event, value) => {
    event.preventDefault();
    dispatch(tfActions.updateProductIndex(value));
  };

  const renderProductTabs = () => {
    if (productCount <= 0) return null;
    let tabs = [];

    const maxProductCount = productCount > 10 ? 10 : productCount;
    for (var i = 1; i <= maxProductCount; i++) {
      tabs.push(<Tab label={`#${i}`} key={`product-tab-${i}`} />);
    }

    return tabs;
  };

  const getRemainingPoint = () => {
    const usedPoint = reduce(splitPoint, function (sum, n) {
      return sum + parseFloat(n);
    }, 0);

    const r = parseFloat(amount) - usedPoint;
    return r.toFixed(2);
  };

  const getSelectedProductByIndex = (index = productIndex) => {
    return get(selectedDestProductIds, `[${index}]`);
  };

  const onSelectedProductChanged = (value) => {
    dispatch(tfActions.updateCurrentDestProductId(value));
  };

  const onVoidAmountChanged = (voidAmount) => {
    dispatch(tfActions.updateVoidAmount(voidAmount));
  };

  const onPointChanged = useCallback(
    (point) => {
      dispatch(tfActions.updatePoint(point));
    }, [dispatch]
  );

  const onVoidTransactionsChanged = useCallback(
    (checked) => {
      dispatch(tfActions.updateVoidTransactions(checked));
    }, [dispatch]
  );

  const handleDefaultGameIdFound = (username, productId) => {
    dispatch(tfActions.updateDefaultGameId(username, productId));
  };

  const getProductFromId = (productId) => {
    if (!productId) return null;
    return find(products, { _id: productId }) || null;
  };

  const onGameIdFromSelectedCallback = useCallback(
    (gameId) => {
      dispatch(tfActions.updateSelectedSrcGameId(gameId));
    }, [dispatch]
  );

  const onGameIdSelectCallback = useCallback(
    (gameId) => {
      dispatch(tfActions.updateCurrentDestGameId(gameId));
    }, [dispatch]
  );

  const onProductCountChanged = useCallback(
    (event) => {
      event.preventDefault();
      const value = event.target.value;
      const intVal = parseInt(value);
      dispatch(tfActions.updateProductCount(intVal));
    }, [dispatch]
  );

  const generateProductCountButtons = useCallback(
    () => {
      let buttons = [];
      for (var i = 1; i <= 5; i++) {
        buttons.push(
          <Button
            size='large'
            key={`product-count-${i}`}
            variant={productCount === i ? 'contained' : 'outlined'}
            value={i}
            onClick={onProductCountChanged}
          >
            {i}
          </Button>
        );
      }
      return buttons;
    }, [productCount, onProductCountChanged]
  );

  const isDisabled = useMemo(
    () => {
      return !selectedProfile || !selectedProfile.isEnabled;
    }, [selectedProfile]
  );

  if (!productsReady) return <Loader open={true} />;

  return (
    <Box>
      <ConfirmNavigation blocker={blocker} />
      <ConfirmDialog
        title={`${t('Incomplete Order Confirmation')}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={onSaveClicked}
      >
        {confirmationText}
      </ConfirmDialog>
      <Loader open={!isIdle} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined'>
                <ProfileSelector
                  reducerPrefix={REDUCER_PREFIX}
                  onDefaultGameIdFound={handleDefaultGameIdFound}
                />
              </Paper>
            </Grid>
            {
              selectedProfile &&
              (
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 1 }}>
                    <LastTransactionViewer
                      profile={selectedProfile}
                      voidAmount={voidAmount}
                      setVoidAmount={onVoidAmountChanged}
                      voidTransactions={voidTransactions}
                      setVoidTransactions={onVoidTransactionsChanged}
                    />
                  </Paper>
                </Grid>
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid item xs={12}>
            <Paper variant='outlined' sx={{ px: 1, py: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DateTimePicker
                    variant='inline'
                    ampm={false}
                    label={t('Datetime')}
                    value={transactionDt}
                    onChange={onTransactionDtChanged}
                    views={['day', 'hours', 'minutes', 'seconds']}
                    inputFormat="YYYY/MM/DD HH:mm:ss"
                    invalidDateMessage={t('Invalid Date Format')}
                    disabled={isDisabled}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id='remark'
                    label={t('Remark')}
                    value={remark}
                    onChange={onRemarkChanged}
                    multiline
                    maxRows={4}
                    InputProps={{
                      disabled: isDisabled,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                   <FormLabel component='legend'>{t('Product Count')}</FormLabel>
                    <ButtonGroup sx={{ mt: 1 }}>
                      {
                        generateProductCountButtons()
                      }
                    </ButtonGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Accordion disableGutters expanded={expanded === 'srcPanel'} onChange={handleChange('srcPanel')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel-from-content'
                  id='panel-from-header'
                >
                  <Typography variant='h6'>{t('Transfer From')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Paper variant='outlined' sx={{ p: 1 }}>
                        <ProductSelector
                          value={selectedSrcProductId}
                          products={products}
                          onChange={onProductSelected}
                          disabled={isDisabled}
                        />
                      </Paper>
                    </Grid>
                  {
                    (selectedSrcProductId && selectedProfile) &&
                    (
                      <Grid item xs={12}>
                        <Paper variant='outlined' sx={{ p: 1 }}>
                          <ProductOperationWd
                            product={getSelectedProductFromId()}
                            profile={selectedProfile}
                            point={point.toString()}
                            setPoint={onPointChanged}
                            onChange={onOperationChangedCallback}
                            gameId={selectedSrcGameId}
                            successCountUpdater={tfActions.updateSuccessWdCount}
                          >
                            <GameIdSelector
                              onChange={onGameIdFromSelectedCallback}
                              value={selectedSrcGameId}
                              product={getSelectedProductFromId()}
                              profile={selectedProfile}
                              disabled={!isIdle}
                            />
                          </ProductOperationWd>
                        </Paper>
                      </Grid>
                    )
                  }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion disableGutters expanded={expanded === 'destPanel'} onChange={handleChange('destPanel')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel-to-content'
                  id='panel-to-header'
                >
                  <Typography variant='h6'>{t('Transfer To')}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Paper variant='outlined' sx={{ p: 1 }}>
                      <Tabs
                        value={productIndex}
                        onChange={onProductIndexChanged}
                        aria-label='product-tab'
                        variant='scrollable'
                        scrollButtons='auto'
                        textColor='primary'
                        indicatorColor='primary'
                      >
                        {
                          renderProductTabs()
                        }
                      </Tabs>
                      <Stack spacing={1} sx={{ mt: 2 }} direction='row'>
                        <TextField
                          id='remainingPoint'
                          label={t('Remaining Point')}
                          type='number'
                          value={getRemainingPoint()}
                          error={getRemainingPoint() !== '0.00'}
                          InputProps={{
                            disabled: true
                          }}
                        />
                        <TextField
                          id='splitPoint'
                          label={t('Split Point')}
                          type='number'
                          value={currentSplitPoint.toString()}
                          onChange={onSplitPointChanged}
                          InputProps={{
                            disabled: false
                          }}
                        />
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      times(productCount, null).map((index) => {
                        return (
                          <Paper variant='outlined' sx={{ p: 1 }}
                            hidden={index !== productIndex}
                            key={`tab-product-${index}`}
                          >
                            <ProductSelector
                              value={currentDestProductId}
                              products={products}
                              onChange={onSelectedProductChanged}
                              disabled={isDisabled}
                            />
                          </Paper>
                        )
                      })
                    }
                  </Grid>
                  {
                    (getSelectedProductByIndex() && selectedProfile) &&
                    (
                      <Grid item xs={12}>
                        <Paper variant='outlined' sx={{ p: 1 }}>
                          <ProductOperationDp
                            product={getProductFromId(currentDestProductId)}
                            profile={selectedProfile}
                            calculatedPoint={currentSplitPoint.toString()}
                            onChange={onOperationChangedCallback}
                            gameId={currentDestGameId}
                            onGameIdSelect={onGameIdSelectCallback}
                            successCountUpdater={tfActions.updateSuccessDpCount}
                          >
                            <GameIdSelector
                              onChange={onGameIdSelectCallback}
                              value={currentDestGameId}
                              product={getProductFromId(currentDestProductId)}
                              profile={selectedProfile}
                              disabled={!isIdle}
                            />
                          </ProductOperationDp>
                        </Paper>
                      </Grid>
                    )
                  }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {
              (expanded === 'destPanel' && productIndex === productCount - 1) &&
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'right' }}>
                  <TransactionSaver onClick={tryToSave} />
                </Box>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};