import React, { useState, useContext, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { get, find } from 'lodash';
import CommonContext from 'features/context/commonContext';
import Decimal from 'decimal.js';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';

const GameIdDialog = ({ open, onClose, products = [] }) => {
  const { t } = useTranslation();
  const { kiosks } = useContext(CommonContext);
  const [ openSnackbar, setOpenSnackBar ] = useState(false);

  const handleCloseSnackbar = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') return;
      setOpenSnackBar(false);
    }, []
  );

  const handleOnCopy = useCallback(
    (event) => {
      setOpenSnackBar(true);
    }, []
  );

  return (
    <>
      <Dialog maxWidth='xs' fullWidth onClose={onClose} open={open}>
        <DialogTitle id="game-id-dialog-title">{t('Game Id', { count: products?.length ?? 0 })}</DialogTitle>
        <DialogContent>
          <List>
          {
            products.map(p => {
              const pid = get(p, '_id');
              const type = get(p, 'productType', '');
              const findKiosk = find(kiosks, { type: type });
              const typeName = get(findKiosk, 'name', '');
              const pointStr = p?.point?.$numberDecimal ?? '0';
              const decPoint = new Decimal(pointStr);
              const username = p?.username ?? '-';
              const action = p?.action ?? '-';

              return (
                <ListItem key={pid}>
                  <ListItemText disableTypography primary={
                    <Box component='span'>
                    {`${typeName} `}
                    {
                      action === 'withdrawal' &&
                      <Box sx={{ color: 'error.dark', fontStyle: 'oblique' }} component='span'>
                        {`${decPoint.toFixed(2)}`}
                      </Box>
                    }
                    {
                      action === 'deposit' &&
                      <Box sx={{ fontStyle: 'oblique' }} component='span'>
                        {`${decPoint.negated().toFixed(2)}`}
                      </Box>
                    }
                    </Box>
                  } secondary={
                    <Box>
                      {`${username}`}
                      <CopyToClipboard text={username} onCopy={handleOnCopy}>
                      <IconButton edge='end' aria-label='copy' size="large">
                        <FileCopyIcon />
                      </IconButton>
                      </CopyToClipboard>
                    </Box>
                  }/>
                </ListItem>
              );
            })
          }
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message={t(`Game id copied`)}
      />
    </>
  );
};

export default GameIdDialog;

