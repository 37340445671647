import React, { useContext, useEffect, useState } from 'react';
import Loader from 'features/loader/Loader';
import TransactionsTable from './TransactionsTable';
import CommonContext from 'features/context/commonContext';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CompanyBankSelector from 'features/companyBankSelector/CompanyBankSelector';
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { get } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

export default function Transactions() {
  const { companyBanks, companyBanksReady, companyBanksError } = useContext(CommonContext);
  const [ selectedCompanyBankId, setSelectedCompanyBankId ] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  useEffect(() => {
    const routeId = get(params, 'id', '');
    if (routeId) setSelectedCompanyBankId(routeId);
  }, [params]);

  useEffect(() => {
    if (!companyBanksError) return;
    setGlobalErrorMessage(companyBanksError);
  }, [companyBanksError, setGlobalErrorMessage]);

  const onCompanyBankSelected = (companyBankId) => {
    navigate(`/transactions/${companyBankId}`, { replace: false });
  };

  return (
    <Box>
      {
        !companyBanksReady ? <Loader open={true} /> : null
      }
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <CompanyBankSelector
            companyBanks={companyBanks}
            onChange={onCompanyBankSelected}
            disabled={false}
            value={selectedCompanyBankId}
            showOthers={true}
          />
        </Grid>
        <Grid item xs={12} sm={10}>
          {
            <TransactionsTable selectedBankId={selectedCompanyBankId} />
          }
        </Grid>
      </Grid>
    </Box>
  );
};
