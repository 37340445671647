import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import feathers from 'services/feathers';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function NewDialog (props) {
  const { t } = useTranslation();
  const {
    open,
    product,
    profile,
    setOpen,
  } = props;

  const productType = get(product, 'type');
  const profileId = get(profile, '_id');
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{t('New Game Id')}</DialogTitle>
        <Formik
          initialValues={{
            type: productType,
            name: '',
            username: '',
            password: '',
            profileId: profileId
          }}
          validate={
            values => {
              const errors = {};
              if (!values.username) errors.username = 'Required';
              else if (!values.password) errors.password = 'Required';
              return errors;
            }
          }
          onSubmit={
            async (values, { setSubmitting }) => {
              try {
                setErrorMessage('');
                await feathers.service('game-ids').create(values);
                setOpen(false);
              } catch (err) {
                setErrorMessage(err.message);
              };
              setSubmitting(false);
            }
          }
        >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form autoComplete='off' onSubmit={handleSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    id='name'
                    label={t('Name')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <TextField
                    id='username'
                    required
                    autoFocus
                    label={t('Username')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    error={errors.username ? true : false}
                    helperText={errors.username && touched.username ? errors.username : ''}
                  />
                  <TextField
                    id='password'
                    required
                    label={t('Password')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={errors.password ? true : false}
                    helperText={errors.password && touched.password ? errors.password : ''}
                  />
                  {
                    errorMessage && <Alert severity='error'>{errorMessage}</Alert>
                  }
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button disabled={isSubmitting} onClick={handleClose} color='primary'>
                  {t('Cancel')}
                </Button>
                <Button type='submit' disabled={isSubmitting} onClick={handleSubmit} color='primary'>
                  {t('Save')}
                </Button>
              </DialogActions>
            </form>
          )
        }
        </Formik>
    </Dialog>
  );
}

NewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
};
