import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  productCount: 1,
  splitAmount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  splitPoint: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  productIndex: 0,
  selectedBonus: null,
  selectedBankId: null,
  amount: 0,
  transactionDt: new Date(),
  remark: '',
  selectedProductIds: [null, null, null, null, null, null, null, null, null, null],
  selectedGameIds: [null, null, null, null, null, null, null, null, null, null],
  status: 'idle',
  pendingMode: false,
  transactionId: null,
  successCount: 0
};

export const slice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    setProductCount: (state, action) => {
      state.productCount = action.payload;
    },
    setSelectedBonus: (state, action) => {
      state.selectedBonus = action.payload;
    },
    setSelectedBankId: (state, action) => {
      state.selectedBankId = action.payload;
    },
    setSelectedGameIdByIndex: (state, action) => {
      state.selectedGameIds[action.payload.index] = action.payload.id;
    },
    setSelectedProductIdByIndex: (state, action) => {
      state.selectedProductIds[action.payload.index] = action.payload.id;
    },
    setTransactionDt: (state, action) => {
      state.transactionDt = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setRemark: (state, action) => {
      state.remark = action.payload;
    },
    setProductIndex: (state, action) => {
      state.productIndex = action.payload;
    },
    setSplitAmountByIndex: (state, action) => {
      state.splitAmount[action.payload.index] = action.payload.amount;
    },
    setSplitPointByIndex: (state, action) => {
      state.splitPoint[action.payload.index] = action.payload.point;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setPendingMode: (state, action) => {
      state.pendingMode = action.payload;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    increaseSuccessCount: (state, action) => {
      state.successCount = state.successCount + 1;
    },
    resetSplit: (state, action) => {
      state.splitAmount = initialState.splitAmount;
      state.splitPoint = initialState.splitPoint;
    },
    reset: (state, action) => {
      const defaultState = { ...initialState };
      defaultState.transactionDt = new Date();
      return defaultState;
    }
  }
});

export const {
  setProductCount,
  setSelectedBonus,
  setSelectedBankId,
  setSelectedGameIdByIndex,
  setSelectedProductIdByIndex,
  setTransactionDt,
  setAmount,
  setRemark,
  setProductIndex,
  setSplitAmountByIndex,
  setSplitPointByIndex,
  setStatus,
  setPendingMode,
  setTransactionId,
  increaseSuccessCount,
  resetSplit,
  reset,
} = slice.actions;

export default slice.reducer;
