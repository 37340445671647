import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { SnackbarProvider, useSnackbar } from 'notistack';
import feathers from 'services/feathers';
import { get } from 'lodash';
import { useAuth } from 'hooks/useAuth';

function SmsNotistack () {
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (userRole !== 'user') return;
    const service = feathers.service(`/text-messages`);
    const onCreated = (data) => {
      const channel = get(data, 'textMessageChannel', '');
      const textBody = get(data, 'message.body', '');
      const textFrom = get(data, 'message.from', '');
      const formattedText = `🔴 ${channel} 🟠 ${textFrom} 🟢 ${textBody}`;
      enqueueSnackbar(formattedText, {
        autoHideDuration: 10000,
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom'
        }
      });
    };

    service.on('created', onCreated);

    return () => {
      service.removeListener('created', onCreated);
    };
  }, [enqueueSnackbar, closeSnackbar, userRole]);

  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default function IntegrationNotistack() {
  const notistackRef = React.createRef();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      maxSnack={5}
      hideIconVariant={true}
      ref={notistackRef}
      action={(key) => (
        <Button color='secondary' onClick={onClickDismiss(key)}>
          Close
        </Button>
      )}
    >
      <SmsNotistack />
    </SnackbarProvider>
  );
}
