import { includes } from 'lodash';

export const toMongoFilter = (columnFilters) => {
  return columnFilters.reduce((acc, { id, value }) => {
    let matchQuery = value;

    if (Array.isArray(value)) {
      matchQuery = {
        $in: value
      }
    } else if (includes(['true', 'false'], value)) {
      matchQuery = value === 'true' ? true : false;
    }

    return {
      ...acc,
      [id]: matchQuery
    };
  }, {});
};

export const toMongoSort = (sorting) => {
  return sorting.reduce((acc, { id, desc }) => {
    const order = desc ? -1 : 1;

    return {
      ...acc,
      [id]: order
    };
  }, {});
};
