import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import feathers from 'services/feathers';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { get, padStart } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { isPhoneNumber, formatPossiblePhoneNumber, sanitizePhoneNumber } from 'utils/phone';

export default function NewProfileDialog (props) {
  const { t } = useTranslation();
  const { onNewProfile, open, setOpen } = props;
  const [ errorMessage, setErrorMessage ] = useState('');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const formikRef = useRef();

  const { user } = useAuth();
  const settings = get(user, 'settings');
  const refIdPrefix = get(settings, 'refIdPrefix', '');
  const refIdPostfixOffset = get(settings, 'refIdPostfixOffset', 0);

  useEffect(() => {
    if (!open || refIdPrefix === '') return;
    let isMounted = true;

    async function fetch () {
      try {
        const result = await feathers.service('/profiles').find({
          query: {
            $limit: 0
          }
        });

        const total = get(result, 'total') + refIdPostfixOffset;
        const serial = padStart((total + 1).toString(), 4, '0');

        if (isMounted && total !== undefined && formikRef.current) {
          formikRef.current.setFieldValue(
            'refId',
            `${refIdPrefix}${serial}`
          );
        }
      } catch (err) {
        if (isMounted) setGlobalErrorMessage(err);
      }
    };

    fetch();

    return () => {
      isMounted = false;
    }
  }, [open, refIdPrefix, refIdPostfixOffset, setGlobalErrorMessage])

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{t('New Profile')}</DialogTitle>
        <Formik
          innerRef={formikRef}
          initialValues={{
            refId: '',
            name: '',
            contact: '',
            whatsApp: '',
            telegram: '',
            remark: ''
          }}
          validate={
            values => {
              const errors = {};
              if (!values.refId) errors.refId = t('Required');
              return errors;
            }
          }
          onSubmit={
            async (values, { setSubmitting }) => {
              try {
                setErrorMessage('');
                const create = await feathers.service('profiles').create(values);
                onNewProfile(create);
                setOpen(false);
              } catch (err) {
                setErrorMessage(err.message);
              };
              setSubmitting(false);
            }
          }
        >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Box component='form' autoComplete='off' onSubmit={handleSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    id='refId'
                    required
                    autoFocus
                    label={t('Reference Id')}
                    type='text'
                    fullWidth
                    onChange={event => {
                      const val = event.target.value;
                      const formattedData = formatPossiblePhoneNumber(val);
                      if (isPhoneNumber(formattedData)) {
                        const phoneNumber = sanitizePhoneNumber(val);
                        setFieldValue('contact', phoneNumber);
                        setFieldValue('whatsApp', phoneNumber);
                      }
                      setFieldValue('refId', formattedData);
                    }}
                    onBlur={handleBlur}
                    value={values.refId}
                    error={errors.refId ? true : false}
                    helperText={errors.refId && touched.refId ? errors.refId : ''}
                  />
                  <TextField
                    id='name'
                    label={t('Name')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name ? true : false}
                    helperText={errors.name && touched.name ? errors.name : ''}
                  />
                  <TextField
                    id='contact'
                    label={t('Contact')}
                    type='text'
                    fullWidth
                    onChange={event => {
                      const val = event.target.value;
                      const formattedData = formatPossiblePhoneNumber(val);
                      if (isPhoneNumber(formattedData)) {
                        const phoneNumber = sanitizePhoneNumber(val);
                        setFieldValue('contact', phoneNumber);
                        setFieldValue('whatsApp', phoneNumber);
                      } else {
                        setFieldValue('contact', val);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.contact}
                  />
                  <TextField
                    id='whatsApp'
                    label={t('WhatsApp')}
                    type='text'
                    fullWidth
                    onChange={event => {
                      const val = event.target.value;
                      const formattedData = formatPossiblePhoneNumber(val);
                      if (isPhoneNumber(formattedData)) {
                        const phoneNumber = sanitizePhoneNumber(val);
                        setFieldValue('whatsApp', phoneNumber);
                        setFieldValue('contact', phoneNumber);
                      } else {
                        setFieldValue('whatsApp', val);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.whatsApp}
                  />
                  <TextField
                    id='telegram'
                    label={t('Telegram')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.telegram}
                  />
                  <TextField
                    id='remark'
                    label={t('Remark')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.remark}
                  />
                  {
                    errorMessage && <Alert severity='error'>{errorMessage}</Alert>
                  }
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button disabled={isSubmitting} onClick={handleClose} color='primary'>
                  {t('Cancel')}
                </Button>
                <Button type='submit' disabled={isSubmitting} onClick={handleSubmit} color='primary'>
                  {t('Save')}
                </Button>
              </DialogActions>
            </Box>
          )
        }
        </Formik>
    </Dialog>
  );
}

NewProfileDialog.propTypes = {
  onNewProfile: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};
