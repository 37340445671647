import {
  setFindText,
  setProfileList,
  setError,
  setProfile,
  resetWithoutFindText,
  reset,
} from './slice';
import { get } from 'lodash';

export const updateFindText = (name, text) => dispatch => {
  dispatch(setFindText({ name, text }));
};

export const updateProfileList = (name, list) => dispatch => {
  dispatch(setProfileList({ name, list }));

  if (list.length === 1) dispatch(setProfile({ name, profile: list[0] }));
};

export const updateError = (name, errorText) => dispatch => {
  dispatch(setError({ name, errorText }));
};

export const updateProfile = (name, profile) => dispatch => {
  dispatch(setProfile({ name, profile }));
};

export const updateDefaultProfile = (name, profile) => dispatch => {
  const refId = get(profile, 'refId', '');
  dispatch(setProfileList({ name, list: [ profile ]}));
  dispatch(setProfile({ name, profile }));
  dispatch(setFindText({ name, text: refId }))
};

export const resetBeforeFind = (name) => dispatch => {
  dispatch(resetWithoutFindText({ name }));
};

export const resetUi = (name) => dispatch => {
  dispatch(reset({ name }));
};
