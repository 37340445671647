import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  selectedBankId: null,
  amount: 0,
  voidAmount: 0,
  point: 0,
  transactionDt: new Date(),
  remark: '',
  selectedProductId: null,
  selectedGameId: null,
  status: 'idle',
  pendingMode: false,
  transactionId: null,
  voidTransactions: [],
  successCount: 0
};

export const slice = createSlice({
  name: 'withdrawal',
  initialState,
  reducers: {
    setSelectedBankId: (state, action) => {
      state.selectedBankId = action.payload;
    },
    setSelectedGameId: (state, action) => {
      state.selectedGameId = action.payload;
    },
    setSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload;
    },
    setTransactionDt: (state, action) => {
      state.transactionDt = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setVoidAmount: (state, action) => {
      state.voidAmount = action.payload;
    },
    setPoint: (state, action) => {
      state.point = action.payload;
    },
    setRemark: (state, action) => {
      state.remark = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setPendingMode: (state, action) => {
      state.pendingMode = action.payload;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    setVoidTransactions: (state, action) => {
      state.voidTransactions = action.payload;
    },
    increaseSuccessCount: (state, action) => {
      state.successCount = state.successCount + 1;
    },
    reset: (state, action) => {
      const defaultState = { ...initialState };
      defaultState.transactionDt = new Date();
      return defaultState;
    }
  }
});

export const {
  setSelectedBankId,
  setSelectedGameId,
  setSelectedProductId,
  setTransactionDt,
  setAmount,
  setVoidAmount,
  setPoint,
  setRemark,
  setStatus,
  setPendingMode,
  setTransactionId,
  setVoidTransactions,
  increaseSuccessCount,
  reset,
} = slice.actions;

export default slice.reducer;
