//import { createSelector } from 'reselect';
import { get } from 'lodash';
import { initialState } from './slice';

export const getProductCount = state => get(state, 'deposit.productCount', initialState.productCount);
export const getSelectedBonus = state => get(state, 'deposit.selectedBonus', initialState.selectedBonus);
export const getSelectedBankId = state => get(state, 'deposit.selectedBankId', initialState.selectedBankId);
export const getTransactionDt = state => get(state, 'deposit.transactionDt', initialState.transactionDt);

export const getAmount = state => get(state, 'deposit.amount', initialState.amount);
export const getRemark = state => get(state, 'deposit.remark', initialState.remark);
export const getProductIndex = state => get(state, 'deposit.productIndex', initialState.productIndex);

export const getCurrentProductId = state => {
  const productIndex = getProductIndex(state);
  return get(state, `deposit.selectedProductIds[${productIndex}]`, null);
};

export const getCurrentGameId = state => {
  const productIndex = getProductIndex(state);
  return get(state, `deposit.selectedGameIds[${productIndex}]`, null);
};

export const getSelectedProductIds = state => get(state, 'deposit.selectedProductIds', initialState.selectedProductIds);
export const getSelectedGameIds = state => get(state, 'deposit.selectedGameIds', initialState.selectedGameIds);

export const getSplitAmount = state => get(state, 'deposit.splitAmount', initialState.splitAmount);
export const getSplitPoint = state => get(state, 'deposit.splitPoint', initialState.splitPoint);

export const getCurrentSplitAmount = state => {
  const productIndex = getProductIndex(state);
  return get(state, `deposit.splitAmount[${productIndex}]`, 0);
};

export const getCurrentSplitPoint = state => {
  const productIndex = getProductIndex(state);
  return get(state, `deposit.splitPoint[${productIndex}]`, 0);
};

export const getIsIdle = state => get(state, 'deposit.status', initialState.status) === 'idle';
export const getPendingMode = state => get(state, 'deposit.pendingMode', initialState.pendingMode);
export const getTransactionId = state => get(state, 'deposit.transactionId', initialState.transactionId);
export const getSuccessCount = state => get(state, 'deposit.successCount', initialState.successCount);

//export const get
/*
export const isIdle = createSelector(
  getStatus,
  (status) => (status === 'idle')
);*/
