import React, { useEffect, useState, useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  useLocation,
  Navigate,
} from "react-router-dom";
import { useFormik } from 'formik';
import useQuery from 'hooks/useQuery';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useUserConfig } from 'hooks/useUserConfig';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Loader from 'features/loader/Loader';
import Relogin from 'features/login/Relogin';

function Copyright(props) {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('Copyright © ')}
      <Link color="inherit" href={'#'}>
        ABT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const { t } = useTranslation();
  const query = useQuery();
  const lang = query.get('lang') || 'en';
  const darkMode = query.get('darkMode', 'false') === 'true' ? true : false;
  const usernameQuery = query.get('username', '');
  const passwordQuery = query.get('password', '');
  const [ isRevealPassword, setIsRevealPassword ] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const location = useLocation();
  const auth = useAuth();
  const { setLang, setDarkMode } = useUserConfig();
  const from = location.state?.from?.pathname || '/';

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(t("Required")),
    password: Yup.string().required(t("Required"))
  });

  const formik = useFormik({
    initialValues: {
      username: usernameQuery,
      password: passwordQuery,
    },
    validationSchema: LoginSchema,
    onSubmit: async values => {
      try {
        const { username, password } = values;
        await auth.login(username, password);
        //navigate(from, { replace: true });
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    },
   });

  useEffect(() => {
    if (auth.user) return;
    setLang(lang);
  }, [lang, auth, setLang]);

  useEffect(() => {
    if (auth.user) return;
    setDarkMode(darkMode);
  }, [darkMode, auth, setDarkMode]);

  const handleRevealToggle = (event) => {
    event.preventDefault();
    setIsRevealPassword(!isRevealPassword);
  };

  const isAuthenticated = useMemo(
    () => {
      return !!auth?.user;
    }, [auth]
  );

  const hasToken = useMemo(
    () => {
      return !!localStorage.getItem('feathers-jwt');
    }, []
  );

  if (isAuthenticated) {
    return (<Navigate to={from} replace={true} />);
  }

  if (hasToken) {
    return (<Relogin from={from} />);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Loader open={!auth.isIdle} />
      <Paper
        elevation={8}
        sx={{
          padding: 2,
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar src='/logo512.png' sx={{ my: 1 }} />
        <Typography component="h1" variant="h5">
          ABT
        </Typography>
        <Typography variant="overline" display="block">
          Login
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth sx={{ my: 1 }} variant="standard">
            <InputLabel htmlFor="username-input" error={formik.errors.username ? true : false} required>{t("Username")}</InputLabel>
            <Input
              id="username"
              name="username"
              type='text'
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.errors.username ? true : false}
            />
            {
              formik.errors.username ?
              <FormHelperText error>{formik.errors.username}</FormHelperText> :
              null
            }
          </FormControl>
          <FormControl fullWidth sx={{ my: 1 }} variant="standard">
            <InputLabel htmlFor="password-input" error={formik.errors.password ? true : false} required>{t("Password")}</InputLabel>
            <Input
              id="password"
              name="password"
              type={isRevealPassword? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password ? true : false}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleRevealToggle}
                    onMouseDown={handleRevealToggle}
                  >
                    {isRevealPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {
              formik.errors.password ?
              <FormHelperText error>{formik.errors.password}</FormHelperText> :
              null
            }
          </FormControl>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('Login')}
          </Button>
        </Box>
      </Paper>
      <Copyright sx={{ mt: 4 }} />
    </Container>
  );
}