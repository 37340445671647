import React, { useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CaptchaBridgeDialog from 'features/captchaBridgeDialog/CaptchaBridgeDialog';
import Grid from '@mui/material/Grid';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { GlobalHotKeys, configure } from "react-hotkeys";
import Tooltip from '@mui/material/Tooltip';

configure({
  ignoreEventsCondition: (event) => {
    return false;
  }
});

export default function Order () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();
  const settings = get(user, 'settings');
  const hideWithdrawal = get(settings, 'hideWithdrawal', false);
  const relocatePendingDp = get(settings, 'relocatePendingDp', false);
  const relocateAdjustment = get(settings, 'relocateAdjustment', false);

  const pathname = useMemo(
    () => {
      return location.pathname;
    }, [location]
  );

  const buttonList = useMemo(
    () => {
      let ret = [];
      ret.push({ path: '/order/deposit', title: t('Order Deposit') });
      !hideWithdrawal && ret.push({ path: '/order/withdrawal', title: t('Order Withdrawal') });
      !relocatePendingDp && ret.push({ path: '/order/pending-deposit', title: t('Order Pending Deposit') });
      ret.push({ path: '/order/pending-withdrawal', title: t('Order Pending Withdrawal') });
      !relocateAdjustment && ret.push({ path: '/order/adjustment', title: t('Order Adjustment') });
      ret.push({ path: '/order/transfer', title: t('Order Transfer') })

      return ret;
    }, [t, hideWithdrawal, relocatePendingDp, relocateAdjustment]
  );

  const setActionCallback = useCallback(
    (index) => (event) => {
      event.preventDefault();
      const buttonIndex = index - 1;
      const path = get(buttonList, `[${buttonIndex}].path`);
      if (path) navigate(path, { replace: false })
    }, [navigate, buttonList]
  );

  const keyMap = {
    TAB_1: ['alt+1'],
    TAB_2: ['alt+2'],
    TAB_3: ['alt+3'],
    TAB_4: ['alt+4'],
    TAB_5: ['alt+5'],
    TAB_6: ['alt+6'],
  };

  const hotkeysHandlers = {
    TAB_1: setActionCallback(1),
    TAB_2: setActionCallback(2),
    TAB_3: setActionCallback(3),
    TAB_4: setActionCallback(4),
    TAB_5: setActionCallback(5),
    TAB_6: setActionCallback(6),
  };

  return (
    <Box>
      {
        buttonList.length ?
        <GlobalHotKeys keyMap={keyMap} handlers={hotkeysHandlers} /> :
        null
      }
      <CaptchaBridgeDialog />
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={1}>
        {
          buttonList.map((button, index) => {
            const isSelected = pathname.indexOf(button.path) === 0 ? true : false;
            return (
              <Grid item xs={6} lg={2} key={button.path}>
                <Link to={button.path} style={{ textDecoration: 'none' }}>
                  <Tooltip title={`Alt + ${index + 1}`}>
                    <Button size='large' fullWidth variant={ isSelected ? 'contained' : 'outlined'}>
                      {button.title}
                    </Button>
                  </Tooltip>
                </Link>
              </Grid>
            )
          })
        }
        </Grid>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};