import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import RefreshIcon from '@mui/icons-material/Refresh';
import DiffIcon from '@mui/icons-material/Difference';
import { get, set, random, toNumber } from 'lodash';
import feathers from 'services/feathers';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { useAuth } from 'hooks/useAuth';
import sound from 'assets/sounds/juntos.mp3';
import soundError from 'assets/sounds/notification_child.mp3';
import { useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import * as captchaActions from 'features/captchaBridgeDialog/store/actions';

export default function ProductOperationDp(props) {
  const dispatch = useDispatch();
  const [ audio ] = useState(new Audio(sound));
  const [ audioError ] = useState(new Audio(soundError));
  const { t } = useTranslation();
  const {
    children,
    product,
    gameId,
    profile,
    calculatedPoint = '0',
    onChange,
    pointUnlock = false,
    onSplitPointChanged = null,
    successCountUpdater = null
  } = props;
  const [ status, setStatus ] = useState('idle');
  const [ alertType, setAlertType ] = useState('info') // error, warning, info, success
  const [ alertMessage, setAlertMessage ] = useState('');
  const [ isGod, setIsGod ] = useState(false);
  const [ point, setPoint ] = useState('0');
  const [ openSnackbar, setOpenSnackBar ] = useState(false);
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const showKioskCredential = get(settings, 'showKioskCredential', false);

  const balance = get(product, 'balance.$numberDecimal', '0');
  const balanceCheck = get(product, 'balanceCheck.$numberDecimal', '0');
  const pointUsed = toNumber(balanceCheck) - toNumber(balance);
  const name = get(product, 'name', '');
  const type = get(product, 'type', '');
  const productId = get(product, '_id');
  const profileId = get(profile, '_id');
  const url = get(product, 'url');
  const username = get(product, 'username');
  const password = get(product, 'password');
  const secondPassword = get(product, 'secondPassword');
  const isIdle = useMemo(
    () => {
      return status === 'idle';
    }, [status]
  );

  useEffect(() => {
    if (isGod) setPoint('0');
    else setPoint(calculatedPoint);
  }, [isGod, calculatedPoint]);

  useEffect(() => {
    onChange(status);
  }, [status, onChange]);

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('action-logs');

    const onPatched = (data) => {
      const eventKioskId = get(data, 'request.kioskSetting._id');
      if (productId !== eventKioskId || !isMounted) return;

      const isError = get(data, 'response.error') ? true : false;

      if (!isError) {
        setAlert('success', t('Success'));
        const kioskAction = get(data, 'action');

        if (successCountUpdater && kioskAction === 'deposit' && !isGod) {
          dispatch(successCountUpdater());
        }
      } else {
        const errorMessage = get(data, 'response.error.message');
        setAlert('error', errorMessage);
        dispatch(captchaActions.resetImage());
      }

      setStatus('idle');
    };

    service.on('patched', onPatched);

    return () => {
      isMounted = false;
      service.removeListener('patched', onPatched);
    };

  }, [productId, dispatch, isGod, successCountUpdater, t]);

  useEffect(() => {
    if (alertType !== 'error') return;
    audioError.play();
    return () => {
      audioError.pause();
    }
  }, [audio, audioError, alertType]);

  async function executeAction (action) {
    resetAlert();

    function randomPassword () {
      const r = random(1000, 9999);
      return `Aaaa${r}`;
    };

    function getRequestData () {
      const username = gameId || '';
      let data = {
        action: action,
        request: {
          kioskSetting: {
            _id: productId,
            isDialogCaptcha: true
          }
        }
      };

      switch (action) {
        case 'productbalance':
          break;
        case 'newuser':
          set(data, 'request.profileId', profileId);
          break;
        case 'deposit':
        case 'withdrawal':
          set(data, 'request.username', username);
          set(data, 'request.amount', point);
          break;
        case 'updateuser':
          set(data, 'request.username', username);
          set(data, 'request.newPassword', randomPassword());
          break;
        case 'balance':
          set(data, 'request.username', username);
          break;
        default:
      };
      return data;
    };

    async function preformAction () {
      setStatus('loading');
      feathers.service('/action-logs').create(getRequestData());
    };
    preformAction();
  };

  const handleRefresh = async (event) => {
    if (event) event.preventDefault();
    executeAction('productbalance');
  };

  const handleGodSwitch = (event) => {
    setIsGod(event.target.checked)
  };

  const resetAlert = () => {
    setAlertType('info');
    setAlertMessage('');
  };

  const setAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
  };

  const onPointChanged = (event) => {
    event.preventDefault();
    setPoint(event.target.value);
    if (!isGod && onSplitPointChanged) onSplitPointChanged(event);
  };

  const handleAction = (action) => (event) => {
    if (event) event.preventDefault();
    executeAction(action);
  };

  const handleKeyPress = (event) => {
    if (isGod) return;
    if (event.key !== 'd' && event.key !== 'b') return;
    if (!gameId) return;

    if (event.key === 'd' && point > 0) {
      executeAction('deposit');
      return;
    } else if (event.key === 'b') {
      executeAction('balance');
      return;
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handleOnCopy = (event) => {
    setOpenSnackBar(true);
  };

  function generateButtonTitle (action) {
    let prefix = 'N/A';
    let postfix = '';

    if (action === 'deposit') prefix = t('DP+');
    else if (action === 'withdrawal') prefix = t('WD-');
    else if (action === 'newuser') prefix = t('NEW');
    else if (action === 'updateuser') prefix = t('PWD');
    else if (action === 'balance') prefix = t('BAL');

    if (isGod) return prefix;
    if (action === 'deposit') postfix = ' (D)';
    else if (action === 'balance') postfix = ' (B)';

    return `${prefix}${postfix}`;
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message={t(`Copied`)}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 3 }}>
          <Stack direction='row' spacing={0.5}>
            <Chip
              size='small'
              icon={<RefreshIcon />}
              label={`${balance}`}
              clickable
              color='success'
              disabled={!isIdle}
              onClick={handleRefresh}
            />
            <Chip
              size='small'
              icon={<DiffIcon />}
              label={`${pointUsed.toFixed(2)}`}
              color='warning'
            />
          </Stack>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 700 }} color='text.secondary'>
            {`🔗 `}
            <Link href={url} target='_blank' rel='noreferrer'>
              {type?.toUpperCase()}
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant='h5' component='h2'>
          {name}
        </Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        <Stack direction='row' spacing={0.5}>
          {
            !!showKioskCredential && <CopyToClipboard text={username} onCopy={handleOnCopy}>
              <Chip
                size='small'
                icon={<PersonIcon />}
                label={username}
                clickable
                color='primary'
              />
            </CopyToClipboard>
          }
          {
            !!showKioskCredential && <CopyToClipboard text={password} onCopy={handleOnCopy}>
              <Chip
                size='small'
                icon={<LockIcon />}
                label={password}
                clickable
                color='primary'
              />
            </CopyToClipboard>
          }
          {
            (!! showKioskCredential && !!secondPassword) &&
            <CopyToClipboard text={secondPassword} onCopy={handleOnCopy}>
              <Chip
                size='small'
                icon={<EnhancedEncryptionIcon />}
                label={secondPassword}
                clickable
                color='primary'
              />
            </CopyToClipboard>
          }
        </Stack>
      </Box>
      <Paper elevation={4}>
        {
          children
        }
      </Paper>
      <Box sx={{ my: 2, width: '50%' }}>
        <TextField
          fullWidth
          id='point'
          label={t('Point')}
          value={point}
          onChange={onPointChanged}
          InputProps={{
            disabled: (!isGod && !pointUnlock) || status !== 'idle',
            type: 'number'
          }}
          onKeyPress={handleKeyPress}
        />
      </Box>
      <Box>
        <ButtonGroup variant='contained' disabled={status !== 'idle'} aria-label='outlined primary button group'>
          <Button color='primary' disabled={!gameId || point <= 0} onClick={handleAction('deposit')}>{generateButtonTitle('deposit')}</Button>
          { isGod && <Button color='secondary' disabled={!gameId || point <= 0} onClick={handleAction('withdrawal')}>{generateButtonTitle('withdrawal')}</Button> }
          <Button onClick={handleAction('newuser')}>{generateButtonTitle('newuser')}</Button>
          <Button disabled={!gameId} onClick={handleAction('updateuser')}>{generateButtonTitle('updateuser')}</Button>
          <Button disabled={!gameId} onClick={handleAction('balance')}>{generateButtonTitle('balance')}</Button>
        </ButtonGroup>
      </Box>
      <FormControl component='fieldset'>
        <FormControlLabel
          control={
            <Switch
              checked={isGod}
              onChange={handleGodSwitch}
              name='isGod'
              color='secondary'
            />
          }
          label={t('God mode')}
          labelPlacement='start'
          disabled={status !== 'idle'}
        />
      </FormControl>
      { alertMessage && <Alert severity={alertType}>{alertMessage}</Alert> }
    </Box>
  );
};

ProductOperationDp.propTypes = {
  onChange: PropTypes.func.isRequired,
  //onSplitPointChanged: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};
