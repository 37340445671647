/*
  update at node_modules/react-scripts/config/webpack.config.js

  // This allows you to set a fallback for where webpack should look for modules.
  resolve: {
    fallback: {
      "crypto": require.resolve("crypto-browserify"),
      "stream": require.resolve("stream-browserify"),
    },
  }
*/

import React, { useContext, useState, useMemo, useCallback } from 'react';
import MaterialReactTable from 'material-react-table';
import feathers from 'services/feathers';
import { get, isEmpty } from 'lodash';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from 'hooks/useAuth';
import CreateModal from 'features/crudModal/Create.js';
import EditModal from 'features/crudModal/Edit.js';
import * as Yup from 'yup';
import { toMongoFilter, toMongoSort } from 'utils/query';
import TicketIcon from '@mui/icons-material/ConfirmationNumber';
import bcrypt from 'bcryptjs';

function LuckySpinSettings() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const ability = useContext(AbilityContext);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const validationSchema = Yup.object().shape({
    url: Yup.string().required(t("Required")),
    rewards: Yup.string().matches(/^([\s]*[\d]+\.?[\d]*[\s]*,){7}([\s]*[\d]+\.?[\d]*[\s]*){1}$/, 'x, x, x, x, x, x, x, x').required(t("Required")),
    rewardWeights: Yup.string().matches(/^([\s]*[\d]+\.?[\d]*[\s]*,){7}([\s]*[\d]+\.?[\d]*[\s]*){1}$/, 'x, x, x, x, x, x, x, x').required(t("Required")),
    ticketKey: Yup.string().nullable(),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const [ createModalOpen, setCreateModalOpen ] = useState(false);
  const [ editModalOpen, setEditModalOpen ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const filters = toMongoFilter(columnFilters);

      const sort = toMongoSort(sorting);
      const query = {
        ...(
          !!globalFilter && {
            '$text': {
              '$search': `${globalFilter}`
            }
          }
        ),
        ...(
          !isEmpty(filters) && {
            ...filters
          }
        ),
        $skip: pagination.pageIndex * pagination.pageSize,
        $limit: pagination.pageSize,
        $populate: [{ path: 'owner', select: ['_id', 'username'] }],
        $sort: sort
      };

      try {
        const response = await feathers.service('lucky-spin-settings').find({ query });
        return response;
      } catch (err) {
        setGlobalErrorMessage(err);
        return null;
      }
    },
    keepPreviousData: true,
  });

  const booleanOptions = useMemo(
    () => {
      return [
        { text: t('true'), value: 'true' },
        { text: t('false'), value: 'false' },
      ];
    }, [t]
  );

  const defaultUrl = useMemo(
    () => {
      const href = window.location.href;
      if (href.indexOf('abt8890.duckdns.org') >= 0) {
        return `https://cuci7.com/8890`
      } else if (href.indexOf('abt1337.duckdns.org') >= 0) {
        return `https://cuci7.com/1337`
      } else {
        return `https://`
      }
    }, []
  );

  const columns = useMemo(
    () => {
      const ownerColumn = (userRole === 'user') ? null : {
        accessorFn: (row) => {
          return get(row, 'owner.username');
        },
        id: 'owner.username',
        header: t('Owner'),
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          enableCreating: false
        }
      };

      const commonColumns = [
        {
          accessorKey: '_id',
          header: t('Id'),
          enableSorting: false,
          enableEditing: false,
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'url',
          header: t('URL'),
          enableColumnFilter: false,
          enableSorting: true,
          meta: {
            initialValue: defaultUrl
          }
        },
        {
          accessorKey: 'rewards',
          header: t('Rewards'),
          enableColumnFilter: false,
          enableSorting: false,
          meta: {
            initialValue: '1, 1, 1, 1, 5, 5, 30, 100'
          }
        },
        {
          accessorKey: 'rewardWeights',
          header: t('Reward Weights'),
          enableColumnFilter: false,
          enableSorting: false,
          meta: {
            initialValue: '1, 1, 1, 1, 1, 1, 1, 1'
          }
        },
        {
          accessorKey: 'ticketKey',
          header: t('Ticket Key'),
          enableColumnFilter: false,
          enableSorting: false,
          meta: {
            enableCreating: false,
          }
        },
        {
          accessorKey: 'isEnabled',
          header: t('Enabled'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            formikEditVariant: 'checkbox',
            initialValue: true
          }
        },
      ];

      return ownerColumn ? [ownerColumn, ...commonColumns] : commonColumns;
    }, [t, userRole, booleanOptions, defaultUrl]
  );

  const handleCreateNewRow = useCallback(
    async (values) => {
      try {
        await feathers.service('lucky-spin-settings').create(values);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleUpdateRow = useCallback(
    async (values) => {
      const { _id, ...data } = values;
      try {
        await feathers.service('lucky-spin-settings').patch(_id, data);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(t('Delete confirmation', { text: row.getValue('name') }))
      ) {
        return;
      }

      const kioskId = row.getValue('_id');
      try {
        await feathers.service('lucky-spin-settings').remove(kioskId);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [t, refetch, setGlobalErrorMessage]
  );

  const handleGenerateTickets = useCallback(
    async (row) => {
      const _id = row.getValue('_id');
      const ticketKey = row.getValue('ticketKey');
      const url = row.getValue('url');

      const ticket = await generateTicket(ticketKey);
      const ticketLink = `${url}/${_id}/${ticket}`;
      navigator?.clipboard.writeText(ticketLink);
    }, []
  );

  function generateTicket(key) {
    return new Promise((resolve, reject) => {
      bcrypt.genSalt(10, function(err, salt) {
        bcrypt.hash(key, salt, function(err, hash) {
          if (err) reject(err);
          else {
            resolve(btoa(hash));
          }
        });
      });
    });
  }

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: t('Actions'),
          },
        }}
        data={data?.data ?? []}
        initialState={{
          columnVisibility: {
            _id: false
          },
          showColumnFilters: false
        }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('Error loading data'),
            }
          : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Stack direction='row' spacing={1}>
            <Tooltip arrow title={t('New Item')}>
              <IconButton onClick={() => setCreateModalOpen(true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={t('Refresh Data')}>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        renderRowActions={({ row, table }) => {
          const canTicketing = ability.can('update', subject('luckySpinSettings', row?.original))
          const canEdit = ability.can('update', subject('luckySpinSettings', row?.original));
          const canDelete = ability.can('delete', subject('luckySpinSettings', row?.original));

          return (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {
                !!canTicketing && <Tooltip arrow placement="right" title={t('Generate Tickets')}>
                  <IconButton onClick={() => handleGenerateTickets(row)}>
                    <TicketIcon />
                  </IconButton>
                </Tooltip>
              }
              {
                canEdit && (
                  <IconButton onClick={() => {
                    setEditData(row?.original);
                    setEditModalOpen(true);
                  }}>
                    <EditIcon />
                  </IconButton>
                )
              }
              {
                canDelete && (
                  <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                    <DeleteIcon />
                  </IconButton>
                )
              }
            </Box>
          );
        }}
        onEditingRowSave={handleUpdateRow}
        rowCount={data?.total ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <CreateModal
        modelName='luckySpinSettings'
        validationSchema={validationSchema}
        open={createModalOpen}
        columns={columns}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <EditModal
        modelName='luckySpinSettings'
        validationSchema={validationSchema}
        editData={editData}
        open={editModalOpen}
        columns={columns}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleUpdateRow}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const LuckySpinSettingsWithReactQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <LuckySpinSettings />
  </QueryClientProvider>
);

export default LuckySpinSettingsWithReactQueryProvider;
