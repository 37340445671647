import React, { useContext, useCallback, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CompanyBankSelector from 'features/companyBankSelector/CompanyBankSelector';
import TransactionSaver from 'features/order/TransactionSaver';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { get } from 'lodash';
import { useParams, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import ConfirmNavigation from 'features/order/ConfirmNavigation';

// redux store
import { useDispatch, useSelector } from 'react-redux';

import * as pdpActions from './store/actions';
import * as pdpSelectors from './store/selectors';

export default function PendingDeposit () {
  const dispatch = useDispatch();
  const selectedBankId = useSelector(pdpSelectors.getSelectedBankId);
  const amount = useSelector(pdpSelectors.getAmount);
  const transactionDt = useSelector(pdpSelectors.getTransactionDt);
  const isIdle = useSelector(pdpSelectors.getIsIdle);
  const remark = useSelector(pdpSelectors.getRemark);
  const params = useParams();

  const { t } = useTranslation();

  const { setGlobalMessage, setGlobalErrorMessage} = useGlobalMessageActionsContext();
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const promptOnRouteTransition = get(settings, 'promptOnRouteTransition', true);
  const blocker = useBlocker(!!selectedBankId && promptOnRouteTransition);

  const {
    companyBanks,
    companyBanksReady,
    companyBanksError
  } = useContext(CommonContext);

   useEffect(() => {
    const bankId = get(params, 'id', null);
    if (bankId) dispatch(pdpActions.updateSelectedBankId(bankId));
  }, [dispatch, params]);

  useEffect(() => {
    if (!companyBanksError) return;
    if (companyBanksError) {
      setGlobalErrorMessage(companyBanksError);
      return;
    }
  }, [companyBanksError, setGlobalErrorMessage]);

  const onCompanyBankSelectedCallback = useCallback(
    (companyBankId) => {
      dispatch(pdpActions.updateSelectedBankId(companyBankId));
    }, [dispatch]
  );

  const onAmountChanged = (event) => {
    event.preventDefault();
    dispatch(pdpActions.updateAmount(event.target.value));
  };

  const onRemarkChanged = (event) => {
    event.preventDefault();
    dispatch(pdpActions.updateRemark(event.target.value));
  };

  const onTransactionDtChanged = (dt) => {
    dispatch(pdpActions.updateTransactionDt(dt));
  };

  const onSaveClicked = async (event) => {
    try {
      dispatch(pdpActions.updateStatus('saving'));
      const data = {
        bankId: selectedBankId,
        action: 'deposit',
        transactionDt,
        amount: parseFloat(amount),
        remark,
        isPending: true
      };

      await feathers.service('transactions').create(data);
      setGlobalMessage(t('Pending DP saved'), 'success');
      newOrder();
      dispatch(pdpActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(pdpActions.updateStatus('idle'));
    }
  };

  function newOrder () {
    dispatch(pdpActions.resetUi());
  };

  if (!companyBanksReady) return <Loader open={true} />;

  return (
    <Box>
      <ConfirmNavigation blocker={blocker} />
      <Loader open={!isIdle} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <CompanyBankSelector
                  disabled={false}
                  companyBanks={companyBanks}
                  value={selectedBankId}
                  onChange={onCompanyBankSelectedCallback}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      variant='inline'
                      ampm={false}
                      label={t('Datetime')}
                      value={transactionDt}
                      onChange={onTransactionDtChanged}
                      views={['day', 'hours', 'minutes', 'seconds']}
                      inputFormat="YYYY/MM/DD HH:mm:ss"
                      invalidDateMessage={t('Invalid Date Format')}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id='amount'
                      label={t('Amount')}
                      value={amount.toString()}
                      onChange={onAmountChanged}
                      InputProps={{
                        disabled: false,
                        type: 'number'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='remark'
                      label={t('Remark')}
                      value={remark}
                      onChange={onRemarkChanged}
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right' }}>
                <TransactionSaver onClick={onSaveClicked} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};