import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import feathers from 'services/feathers';
import Alert from '@mui/material/Alert';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { isPhoneNumber, formatPossiblePhoneNumber, sanitizePhoneNumber } from 'utils/phone';

export default function EditProfileDialog (props) {
  const { t } = useTranslation();
  const { onEditProfile, closeEdit, profile, open } = props;
  const [ errorMessage, setErrorMessage ] = useState('');

  const _id = get(profile, '_id');
  const refId = get(profile, 'refId', '');
  const name = get(profile, 'name', '');
  const contact = get(profile, 'contact', '');
  const whatsApp = get(profile, 'whatsApp', '');
  const telegram = get(profile, 'telegram', '');
  const remark = get(profile, 'remark', '');
  const isEnabled = get(profile, 'isEnabled', false);

  const handleClose = () => {
    closeEdit();
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{t('Edit Profile')}</DialogTitle>
        <Formik
          initialValues={{
            refId: refId,
            name: name,
            contact: contact,
            whatsApp: whatsApp,
            telegram: telegram,
            remark: remark,
            isEnabled: isEnabled
          }}
          validate={
            values => {
              const errors = {};
              if (!values.refId) errors.refId = t('Required');
              return errors;
            }
          }
          onSubmit={
            async (values, { setSubmitting }) => {
              try {
                setErrorMessage('');
                const edit = await feathers.service('profiles').patch(_id, values);
                onEditProfile(edit);
                setSubmitting(false);
                closeEdit();
              } catch (err) {
                setSubmitting(false);
                setErrorMessage(err.message);
              };
            }
          }
        >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Box component='form' autoComplete='off' onSubmit={handleSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    id='refId'
                    required
                    autoFocus
                    label={t('Reference Id')}
                    type='text'
                    fullWidth
                    onChange={event => {
                      const val = event.target.value;
                      const formattedData = formatPossiblePhoneNumber(val);
                      if (isPhoneNumber(formattedData)) {
                        const phoneNumber = sanitizePhoneNumber(val);
                        setFieldValue('contact', phoneNumber);
                        setFieldValue('whatsApp', phoneNumber);
                      }
                      setFieldValue('refId', formattedData);
                    }}
                    onBlur={handleBlur}
                    value={values.refId}
                    error={errors.name ? true : false}
                    helperText={errors.name && touched.name ? errors.name : ''}
                    InputProps={{
                      disabled: false,
                      readOnly: false,
                    }}
                  />
                  <TextField
                    id='name'
                    label={t('Name')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name ? true : false}
                    helperText={errors.name && touched.name ? errors.name : ''}
                  />
                  <TextField
                    id='contact'
                    label={t('Contact')}
                    type='text'
                    fullWidth
                    onChange={event => {
                      const val = event.target.value;
                      const formattedData = formatPossiblePhoneNumber(val);
                      if (isPhoneNumber(formattedData)) {
                        const phoneNumber = sanitizePhoneNumber(val);
                        setFieldValue('contact', phoneNumber);
                        setFieldValue('whatsApp', phoneNumber);
                      } else {
                        setFieldValue('contact', val);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.contact}
                  />
                  <TextField
                    id='whatsApp'
                    label={t('WhatsApp')}
                    type='text'
                    fullWidth
                    onChange={event => {
                      const val = event.target.value;
                      const formattedData = formatPossiblePhoneNumber(val);
                      if (isPhoneNumber(formattedData)) {
                        const phoneNumber = sanitizePhoneNumber(val);
                        setFieldValue('whatsApp', phoneNumber);
                        setFieldValue('contact', phoneNumber);
                      } else {
                        setFieldValue('whatsApp', val);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.whatsApp}
                  />
                  <TextField
                    id='telegram'
                    label={t('Telegram')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.telegram}
                  />
                  <TextField
                    id='remark'
                    label={t('Remark')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.remark}
                  />
                  {
                    errorMessage && <Alert severity='error'>{errorMessage}</Alert>
                  }
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>{t('Enable')}</FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isEnabled}
                          onChange={handleChange}
                          name='isEnabled'
                          color='primary'
                        />
                      }
                      label={values.isEnabled ? t('Yes') : t('No')}
                    />
                  </FormControl>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button disabled={isSubmitting} onClick={handleClose} color='primary'>
                  {t('Cancel')}
                </Button>
                <Button type='submit' disabled={isSubmitting} onClick={handleSubmit} color='primary'>
                  {t('Save')}
                </Button>
              </DialogActions>
            </Box>
          )
        }
        </Formik>
    </Dialog>
  );
}

EditProfileDialog.propTypes = {
  onEditProfile: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeEdit: PropTypes.func.isRequired,
};
