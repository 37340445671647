import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { get, sortBy } from 'lodash';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function BankAccordion (props) {
  const { title, banks, disabled, expanded, onChange, radioValue = null, radioOnChange } = props;
  const theme = useTheme();

  return (
    <Accordion disabled={disabled} key={title} square expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${title}-content`}
        id={`panel-${title}-header`}
      >
        <Typography variant='body1'>{`${title}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <FormControl>
        <RadioGroup aria-label='companyBank' name='companyBank' value={radioValue} onChange={radioOnChange} >
          {
            sortBy(banks, ['name']).map((bank) => {
              if (!bank.isEnabled) return null;
              const balance = get(bank, 'balance.$numberDecimal', '0');
              const bgcolor = disabled ? theme.palette.text.disabled : get(bank, 'colorTag', '#fff');
              const color = disabled ? theme.palette.action.disabledBackground : theme.palette.getContrastText(bgcolor);

              return (
                <FormControlLabel
                  sx={{
                    '& .Mui-checked + .MuiFormControlLabel-label .MuiTypography-root': {
                      fontWeight: 700
                    }
                  }}
                  key={bank._id}
                  disabled={disabled}
                  value={bank._id}
                  control={<Radio />}
                  label={
                    <Box>
                      <Typography sx={{ bgcolor, color, p: 1, borderRadius: 1 }} variant='button'>
                        { bank.name }
                      </Typography>
                      <Typography variant='button'>
                        { ` (${parseFloat(balance).toFixed(2)})` }
                      </Typography>
                    </Box>
                  }
                />
              )
            })
          }
        </RadioGroup>
      </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

BankAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  banks: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  expanded: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  radioOnChange: PropTypes.func.isRequired,
};
