import React, { useEffect, useState } from 'react';
import feathers from 'services/feathers';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useAuth } from 'hooks/useAuth';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { find, get } from 'lodash';
import DateRangePicker from 'features/dateRangePicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UniqTrx () {
  const { t } = useTranslation();
  const [ data, setData ] = useState(null);
  const [ status, setStatus ] = useState('idle');
  const [ selectedStaffIds, setSelectedStaffIds] = useState([]);
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const staffIds = get(user, 'staffIds', []);
  const [ dateRange, onDateRangeChange ] = useState([new Date(), new Date()]);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const handleSelectedStaffIdsChange = (event) => {
    setSelectedStaffIds(event.target.value);
  };

  const onGenerateClicked = async (event) => {
    event.preventDefault();
    try {
      setStatus('loading');
      const report = await feathers.service('/transactions').find({
        query: {
          $aggReport: 'uniqTrx',
          dateRange: dateRange,
          staffIds: selectedStaffIds
        }
      });

      setData(report || []);
      setStatus('idle');
    } catch (err) {
      setStatus('idle');
      setGlobalErrorMessage(err);
    }
  };

  const onResetClicked = (event) => {
    event.preventDefault();
    setData(null);
  };

  useEffect(() => {
    if (!staffIds.length) return;
    let allStaffIds = [];
    staffIds.forEach(s => {
      allStaffIds.push(s._id);
    });
    setSelectedStaffIds(allStaffIds);
  }, [staffIds]);

  function generateContent () {
    if (status !== 'idle') {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color='secondary' />
        </Box>
      );
    }

    if (data !== null) {
      return (
        <Box>
          <Box sx={{ mb: 1 }}>
            <DateRangePicker
              id='date-range-picker-readonly'
              value={dateRange}
              disabled={true}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button variant='contained' color='secondary' onClick={onResetClicked}>
              {t('Reset')}
            </Button>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TableContainer component={Paper}>
                <Table aria-label='uniqtrx table'>
                  <TableHead>
                    <TableRow>
                      {
                        userRole === 'user' ? null : <TableCell>{t('Executer')}</TableCell>
                      }
                      <TableCell align='center'>{t('Unique Deposit Count')}</TableCell>
                      <TableCell align='center'>{t('Unique Withdrawal Count')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    data.length ?
                    data.map(rowData => {
                      const uniqDpCount = get(rowData, 'uniqDpCount', '0');
                      const uniqWdCount = get(rowData, 'uniqWdCount', '0');

                      return (
                        <TableRow key={rowData._id}>
                          {
                            userRole === 'user' ? null : <TableCell>{rowData.executer.name}</TableCell>
                          }
                          <TableCell align='center'>{`${uniqDpCount}`}</TableCell>
                          <TableCell align='center'>{`${uniqWdCount}`}</TableCell>
                        </TableRow>
                      )
                    }) :
                    <TableRow>
                      <TableCell align='center' colSpan={userRole === 'user' ? 2 : 3}>
                        {t('No records to display')}
                      </TableCell>
                    </TableRow>
                  }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <Box>
          <Box sx={{ mb: 1 }}>
            <DateRangePicker
              id='date-range-picker'
              onChange={onDateRangeChange}
              value={dateRange}
              required={true}
            />
          </Box>
          <Box>
            {
              generateStaffIdsSelector()
            }
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button variant='contained' color='primary' onClick={onGenerateClicked}>
              {t('Generate')}
            </Button>
          </Box>
        </Box>
      );
    };
  };

  function generateStaffIdsSelector () {
    if (!staffIds.length) return null;
    return (
      <FormControl>
        <InputLabel id='staffIdsSelectorLabel'>Staff(s)</InputLabel>
        <Select
          labelId='staffIdsSelectorLabel'
          id='staffIdsSelector'
          multiple
          value={selectedStaffIds}
          onChange={handleSelectedStaffIdsChange}
          input={<Input />}
          renderValue={(selected) => {
            let selectedName = [];
            selected.forEach(s => {
              const name = get(find(staffIds, { _id: s }), 'name');
              if (name) selectedName.push(name);
            });
            return selectedName.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {staffIds.map((staffId) => (
            <MenuItem key={staffId._id} value={staffId._id}>
              <Checkbox checked={selectedStaffIds.indexOf(staffId._id) > -1} />
              <ListItemText primary={staffId.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  };

  return (
    <Box>
      {
        generateContent()
      }
    </Box>
  );
};
