import React, {
  useMemo,
  useContext,
  //useEffect,
} from 'react';
import { useField } from 'formik';
import Select from 'features/formikControl/Select';
import {
  MenuItem
} from '@mui/material';
import KioskUrlsContext from 'features/context/kioskUrlsContext';

export default function FormikSelectKioskUrl(props) {
  const { editMode, ...safeProps } = props;

  const { data: kioskUrls } = useContext(KioskUrlsContext);

  const [ , typeMeta ] = useField('type');
  const { value: selectedType } = typeMeta;
  /*const [ , , urlHelpers ] = useField('url');
  const { setValue: setUrl } = urlHelpers;*/

  const options = useMemo(
    () => {
      return kioskUrls.filter(({ type }) => {
        return type === selectedType;
      }).map(({ name, url }) => {
        return {
          text: name,
          value: url,
        }
      });
    }, [kioskUrls, selectedType]
  );

/*  useEffect(
    () => {
      if (selectedType === '' || !!editMode) return;
      setUrl('');
    }, [selectedType, editMode, setUrl]
  );*/

  return (
    <Select
      { ...safeProps }
    >
    {
      options.map(({ text, value }) => {
        return (
          <MenuItem key={`${text}${value}`} value={value}>
            {
              text
            }
          </MenuItem>
        );
      })
    }
    </Select>
  );
}