import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import feathers from 'services/feathers';
import { useAuth } from 'hooks/useAuth';
import Loader from 'features/loader/Loader';
import ReactStickyNotes from '@react-latest-ui/react-sticky-notes';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export default function StickyNotesDialog (props) {
  const { open, setOpen } = props;
  const { user } = useAuth();
  const userId = get(user, '_id');
  const savedNotes = get(user, 'notes', []);
  const [ status, setStatus ] = useState('idle');
  const [ lastUpdate, setLastUpdate ] = useState(new Date().getTime());
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (!savedNotes?.length) return;
    window.localStorage.setItem('react-sticky-notes', savedNotes);
  }, [savedNotes]);

  const handleClose = async () => {
    await saveNotes();
    setOpen(false);
  };

  const isUpdateRequired = () => {
    const now = new Date().getTime();
    const diff = now - lastUpdate;
    if (diff > 3000) return true;
    return false;
  };

  const saveNotes = async () => {
    try {
      setLastUpdate(new Date().getTime());
      setStatus('saving');
      const notes = window.localStorage.getItem('react-sticky-notes');
      await feathers.service('/users').patch(userId, { notes });
    } catch (err) {
      setGlobalErrorMessage(err);
    }
    setStatus('idle');
  };

  const onNoteChange = async () => {
    if (!isUpdateRequired()) return;
    await saveNotes();
  };

  if (!open) return null;

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <Loader open={ status !== 'idle' } />
      <AppBar position='fixed' sx={{
        top: 'auto',
        bottom: 0
      }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant='h6'>
            {t(`My Sticky Notes`)}
          </Typography>
        </Toolbar>
      </AppBar>
      <ReactStickyNotes onChange={onNoteChange} footer={false} />
    </Dialog>
  );
}

StickyNotesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
