import React, { useState, useCallback, useMemo, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoteIcon from '@mui/icons-material/Note';
import StickyNotesDialog from 'features/stickyNotesDialog/StickyNotesDialog';
import { HotKeys } from "react-hotkeys";
import Loader from 'features/loader/Loader';
import useSocketIsDisconnected from 'hooks/useSocketIsDisconnected';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { AbilityContext } from 'casl/Can';
import { useUserConfig } from 'hooks/useUserConfig';
import { startCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import Helmet from 'react-helmet'

// icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import HistoryIcon from '@mui/icons-material/History';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import SettingsIcon from '@mui/icons-material/Settings';
import LuckySpinIcon from '@mui/icons-material/Stars';
import KeyIcon from '@mui/icons-material/VpnKey';
import SmsIcon from '@mui/icons-material/Textsms';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import RedeemIcon from '@mui/icons-material/Redeem';
import LinkIcon from '@mui/icons-material/Link';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AdjustIcon from '@mui/icons-material/Adjust';
import LogoutIcon from '@mui/icons-material/Logout';

// Theme
import SunIcon from '@mui/icons-material/Brightness7';
import MoonIcon from '@mui/icons-material/Brightness4';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © ABT '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const drawerWidth = 240;

const Main = styled('main', {
})(
  ({ theme }) => ({
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(9),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  }),
);

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Layout(props) {
  const { t } = useTranslation();
  const { user, isIdle: isAuthIdle } = useAuth();
  const { name, role, username } = user;
  const [open, setOpen] = useState(true);
  const [ openNotes, setOpenNotes ] = useState(false);
  const isDisconnected = useSocketIsDisconnected();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { darkMode, toggleDarkMode } = useUserConfig();

  const ability = useContext(AbilityContext);

  const pathname = useMemo(
    () => {
      return location.pathname || '';
    }, [location]
  );

  const pageTitle = useMemo(
    () => {
      if (pathname.indexOf('/order') !== 0) return 'ABT';
      const sanitized = pathname?.replace('/order', '') || '';
      const formatted = startCase(sanitized);
      return `ABT - ${formatted}`;
    }, [pathname]
  );

  const layoutTitle = useMemo(
    () => {
      if (pathname === '/') return t('Dashboard');
      if (pathname.indexOf('/transactions') >= 0) return t('Transactions');
      if (pathname.indexOf('/order/deposit/') >= 0) return t(startCase('/order/deposit'));
      if (pathname.indexOf('/order/withdrawal/') >= 0) return t(startCase('/order/withdrawal'));
      if (pathname.indexOf('/order/pending-deposit/') >= 0) return t(startCase('/order/pending-deposit'));
      if (pathname.indexOf('/order/adjustment/') >= 0) return t(startCase('/order/adjustment'));
      return t(startCase(pathname));
    }, [pathname, t]
  );

  const menuItems1 = useMemo(
    () => {
      let ret = [
        { label: t('Dashboard'), icon: <DashboardIcon />, linkTo: '/' },
      ];

      ability.can('create', 'actionLogs') &&
        ret.push({ label: t('Operations'), icon: <AddToQueueIcon />, linkTo: '/operations' });

      ability.can('create', 'users') &&
        ret.push({ label: t('Users'), icon: <PeopleIcon />, linkTo: '/users' });

      ret.push({ label: t('History'), icon: <HistoryIcon />, linkTo: '/history' });

      ability.can('read', 'textMessages') &&
        ret.push({ label: t('Text Messages'), icon: <SmsIcon />, linkTo: '/text-messages' });

      ability.can('read', 'whatsappSessions') &&
        ret.push({ label: t('WhatsApp Sessions'), icon: <WhatsAppIcon />, linkTo: '/whatsapp-sessions' });

      ability.can('read', 'balanceCheckAdjustmentLogs') &&
        ret.push({ label: t('BCA Logs'), icon: <AdjustIcon />, linkTo: '/bca-logs' });

      return ret;
    }, [t, ability]
  );

  const menuItems2 = useMemo(
    () => {
      let ret = [];

      ability.can('read', 'profiles') &&
        ret.push({ label: t('Profiles'), icon: <AddToQueueIcon />, linkTo: '/profiles' });

      ability.can('create', 'transactions') &&
        ret.push({ label: t('Order'), icon: <ReceiptIcon />, linkTo: '/order/deposit' });

      ability.can('read', 'transactions') &&
        ret.push({ label: t('Transactions'), icon: <LocalAtmIcon />, linkTo: '/transactions' });

      ability.can('read', 'luckySpins') &&
        ret.push({ label: t('Lucky Spins'), icon: <LuckySpinIcon />, linkTo: '/lucky-spins' });

      return ret;
    }, [t, ability]
  );

  const menuItems3 = useMemo(
    () => {
      return [
        { label: t('Report Winloss'), icon: <AssessmentIcon />, linkTo: '/report/winloss' },
        { label: t('Report Uniqtrx'), icon: <AssessmentIcon />, linkTo: '/report/uniqtrx' },
        { label: t('Report Opstats'), icon: <AssessmentIcon />, linkTo: '/report/opstats' },
      ];
    }, [t]
  );

  const menuItems4 = useMemo(
    () => {
      let ret = [];

      ability.can('create', 'kioskUrls') &&
        ret.push({ label: t('Kiosk Urls'), icon: <LinkIcon />, linkTo: '/kiosk-urls' });

      ability.can('read', 'kiosks') &&
        ret.push({ label: t('Kiosks'), icon: <StorefrontIcon />, linkTo: '/kiosks' });

      ability.can('read', 'bonuses') &&
        ret.push({ label: t('Bonuses'), icon: <RedeemIcon />, linkTo: '/bonuses' });

      ability.can('read', 'companyBanks') &&
        ret.push({ label: t('Company Banks'), icon: <AccountBalanceIcon />, linkTo: '/company-banks' });


      ability.can('read', 'luckySpinSettings') &&
        ret.push({ label: t('Lucky Spin Settings'), icon: <LuckySpinIcon />, linkTo: '/lucky-spin-settings' });

      return ret;
    }, [t, ability]
  );

  const footerMenuItems = useMemo(
    () => {
      let ret = [];

      ability.can('manage', 'apiKeys') &&
        ret.push({ label: t('API Keys'), icon: <KeyIcon />, linkTo: '/api-keys' });

      ret.push({ label: t('Settings & Privacy'), icon: <SettingsIcon />, linkTo: '/settings' });
      ret.push({ label: `${t('Logout')} ${username}`, icon: <LogoutIcon />, linkTo: '/logout' });

      return ret;
    }, [t, ability, username]
  );

  const openNotesViaHotkey = useCallback(() => {
    if (role !== 'user') return;
    setOpenNotes(true);
  }, [role]);

  const closeNotesViaHotkey = useCallback(() => {
    if (role !== 'user') return;
    setOpenNotes(false);
  }, [role]);

  const keyMap = {
    OPEN_NOTES: ['alt+q'],
    CLOSE_NOTES: ['alt+w']
  };

  const hotkeysHandlers = {
    OPEN_NOTES: openNotesViaHotkey,
    CLOSE_NOTES: closeNotesViaHotkey,
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleUserChipClicked = (event) => {
    if (event) event.preventDefault();
    navigate('/settings', { replace: false });
  };

  const handleDarkModeToggle = useCallback(
    (event) => {
      event.preventDefault();
      toggleDarkMode();
    }, [toggleDarkMode]
  );

  const listItems = (items, subheader) => {
    return (
      <>
        <List subheader={ subheader ? <ListSubheader>{subheader}</ListSubheader> : null }>
          {
            items.map((item) => {
              const isSelected = (item.linkTo === pathname) ? true : false;

              return (
                <Link key={item.linkTo} to={item.linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListItem disablePadding sx={{
                    display: 'block',
                    bgcolor: isSelected ? 'divider' : 'inherit',
                  }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              )
            })
          }
        </List>
      </>
    );
  }

  const children = (
    <React.Fragment>
      <Helmet>
        <title>
          {
            pageTitle
          }
        </title>
      </Helmet>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Loader open={!isAuthIdle || isDisconnected} />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {layoutTitle}
            </Typography>
            <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'right' }}>
              {
                role === 'user' && <Tooltip title={t('Notes Hotkeys Tips')} aria-label="open-alt-q--close-alt-w">
                  <IconButton
                    aria-label='refresh'
                    color='inherit'
                    onClick={() => { setOpenNotes(!openNotes) }}
                    size="large">
                    <NoteIcon />
                  </IconButton>
                </Tooltip>
              }
              {
                darkMode ?
                <Tooltip title={t('Disable Dark Mode')}>
                  <IconButton
                    aria-label='Dark Mode'
                    color='inherit'
                    onClick={handleDarkModeToggle}
                    size="large">
                    <SunIcon />
                  </IconButton>
                </Tooltip> :
                <Tooltip title={t('Enable Dark Mode')}>
                  <IconButton
                    aria-label='Light Mode'
                    color='inherit'
                    onClick={handleDarkModeToggle}
                    size="large">
                    <MoonIcon />
                  </IconButton>
                </Tooltip>
              }
              <Chip
                sx={{ bgcolor: 'background.paper' }}
                icon={<AccountCircleIcon />}
                label={`${name}`}
                onClick={handleUserChipClicked}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {
            listItems(menuItems1)
          }
          <Divider />
          {
            listItems(menuItems2)
          }
          <Divider />
          {
            listItems(menuItems3, t('Reports'))
          }
          <Divider />
          {
            listItems(menuItems4, t('Settings'))
          }
          <Divider />
          {
            listItems(footerMenuItems)
          }
        </Drawer>
        <Main>
          <DrawerHeader />
          <Outlet />
          <Box pt={4}>
            <Copyright />
          </Box>
        </Main>
        {
          role === 'user' && <StickyNotesDialog open={openNotes} setOpen={setOpenNotes} />
        }
      </Box>
    </React.Fragment>
  );

  if (role === 'user') {
    return (
      <HotKeys keyMap={keyMap} handlers={hotkeysHandlers}>
      { children }
      </HotKeys>
    );

  } else {
    return children;
  }
};
