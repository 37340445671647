import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { get, find, sortBy } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

export default function BonusSelector(props) {
  const { t } = useTranslation();
  const {
    bonuses,
    disabled,
    onChange,
    value = null,
  } = props;
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const defaultBonus = get(settings, 'defaultBonus');
  const selectedBonusId = get(value, '_id', null);

  useEffect(() => {
    if (!disabled) return;
    onChange(null);
  }, [disabled, onChange]);

  useEffect(() => {
    if (disabled || value !== null || !bonuses.length || !defaultBonus) return;
    const b = find(bonuses, { _id: defaultBonus });
    onChange(b);
  }, [disabled, bonuses, onChange, defaultBonus, value]);

  const handleChange = (event) => {
    event.preventDefault();
    const bonusId = event.target.value;
    const b = find(bonuses, { _id: bonusId });
    onChange(b);
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{t('Bonus')}</FormLabel>
      <RadioGroup row aria-label='bonus' name='bonus' value={selectedBonusId} onChange={handleChange}>
        {
          sortBy(bonuses, ['name']).map((bonus) => {
            if (!bonus.isEnabled) return null;
            const dateStart = get(bonus, 'dateStart');
            const dateEnd = get(bonus, 'dateEnd');
            const daysAllowed = get(bonus, 'daysAllowed', []);
            if (dateStart && dayjs().isBefore(dayjs(dateStart))) return null;
            if (dateEnd && dayjs().isAfter(dayjs(dateEnd))) return null;
            if (daysAllowed.length && daysAllowed.indexOf(dayjs().isoWeekday()) < 0) return null;
            return (
              <FormControlLabel
                key={bonus._id}
                disabled={disabled}
                value={bonus._id}
                control={<Radio />}
                label={`${bonus.name}`}
              />
            )
          })
        }
      </RadioGroup>
    </FormControl>
  );
};

BonusSelector.propTypes = {
  bonuses: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
