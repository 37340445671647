import { useCallback, useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { get, filter } from 'lodash';

function useFeathersService (serviceName, params = {}, liveUpdatePredicate = undefined) {
  const service = feathers.service(`/${serviceName}`);
  const [ fetchedData, setFectchedData ] = useState([]);
  const [ status, setStatus ] = useState('fetch');
  const [ error, setError ] = useState(null);
  const [ paramsStr, setParamsStr ] = useState(null);

  const predicateCallback = useCallback(
    (data) => {
      if (liveUpdatePredicate === undefined) return true;
      return liveUpdatePredicate(data);
    },
    [liveUpdatePredicate],
  );

  useEffect(() => {
    if (typeof params !== 'object') return;
    setParamsStr(JSON.stringify(params));
  }, [params]);

  useEffect(() => {
    setFectchedData([]);
    setStatus('fetch');
    setError(null);
  }, [paramsStr]);

  useEffect(() => {
    let isMounted = true;
    if (status !== 'fetch' || !paramsStr) return;
    async function fetch () {
      try {
        const p = JSON.parse(paramsStr);
        const find = await service.find(p);
        if (isMounted) {
          const total = get(find, 'total');

          // Fetch all will return [] instead of { total: x...}
          if (total === undefined) setFectchedData(find);
          else setFectchedData(find.data);
        }
      } catch (err) {
        if (isMounted) setError(err);
      }
      if (isMounted) setStatus('idle');
    };

    fetch();
    return () => {
      isMounted = false;
    };
  }, [status, paramsStr, service]);

  useEffect(() => {
    if (status !== 'idle') return;

    const onAddedOrChanged = (data) => {
      // Predicate not matched
      if (!predicateCallback(data)) return;

      let newData = filter(fetchedData, (d) => {
        return d._id !== data._id
      });
      newData.push(data);
      setFectchedData(newData);
    };

    const onDeleted = (data) => {
      // Predicate not matched
      if (!predicateCallback(data)) return;

      let newData = filter(fetchedData, (d) => {
        return d._id !== data._id
      });
      setFectchedData(newData);
    };

    service.on('created', onAddedOrChanged);
    service.on('removed', onDeleted);
    service.on('updated', onAddedOrChanged);
    service.on('patched', onAddedOrChanged);

    return () => {
      service.removeListener('created', onAddedOrChanged);
      service.removeListener('removed', onDeleted);
      service.removeListener('updated', onAddedOrChanged);
      service.removeListener('patched', onAddedOrChanged);
    };
  }, [service, status, fetchedData, predicateCallback]);

  return {
    data: fetchedData,
    ready: status === 'idle',
    error
  };
};

export default useFeathersService;