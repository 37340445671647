import React, { useCallback, createContext, useContext } from 'react';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useAuth } from 'hooks/useAuth';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const GlobalMessageActionsContext = createContext();

/* eslint-disable */
const useContextFactory = (name, context) => {
  return () => {
    const ctx = useContext(context);
    if (ctx === undefined) {
      throw new Error(`use${name}Context must be used within a ${name}ContextProvider.`)
    }
    return ctx;
  }
}

export const useGlobalMessageActionsContext = useContextFactory('GlobalMessageActionsContext', GlobalMessageActionsContext);

/* eslint-enable */
const GlobalMessageContextProvider = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();

  const setGlobalMessage = useCallback(
    (message, severity) => {
      enqueueSnackbar(message, {
        variant: severity,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        action: (key) => (
          <>
            <Button sx={{ color: 'white' }} size='small' onClick={() => closeSnackbar(key)}>
              {t('Dismiss')}
            </Button>
          </>
        )
      });
    }, [enqueueSnackbar, closeSnackbar, t]
  );

  const setGlobalErrorMessage = useCallback(
    async (err) => {
      const message = get(err, 'message', 'Unknown error');
      const code = get(err, 'code');

      enqueueSnackbar(message, {
        variant: message,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        },
        action: (key) => (
          <>
            <Button sx={{ color: 'white' }} size='small' onClick={() => closeSnackbar(key)}>
              {t('Dismiss')}
            </Button>
          </>
        )
      });
      if (code === 401) {
        auth.logout();
      }
    }, [enqueueSnackbar, closeSnackbar, t, auth]
  );

  return (
    <GlobalMessageActionsContext.Provider value={{
      setGlobalMessage,
      setGlobalErrorMessage
    }}>
      {props.children}
    </GlobalMessageActionsContext.Provider>
  )
}

export default GlobalMessageContextProvider;