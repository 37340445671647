import RawDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { styled } from '@mui/material/styles';

const DateRangePicker = styled(RawDateRangePicker, {
})(
  ({ theme }) => ({
    '& .react-daterange-picker__wrapper, & .react-calendar': {
      backgroundColor: theme.palette.background.default
    },
    '& .react-daterange-picker__inputGroup__input, & .react-calendar__navigation > button': {
      color: theme.palette.text.primary
    },
    '& .react-calendar__navigation > button:hover, & .react-calendar__navigation > button:focus': {
      backgroundColor: theme.palette.action.hover
    },
    '& .react-calendar__navigation > button[disabled]': {
      backgroundColor: theme.palette.action.disabled
    },
    '& .react-daterange-picker__button__icon': {
      stroke: theme.palette.text.primary
    },
    '& .react-calendar__tile': {
      color: theme.palette.text.primary
    },
    '& .react-calendar__month-view__days__day--weekend': {
      color: theme.palette.secondary.main
    },
    '& .react-calendar__month-view__days__day--neighboringMonth': {
      color: theme.palette.text.disabled
    },
    '& .react-calendar__tile--active': {
      backgroundColor: theme.palette.info.light
    },
    '& .react-calendar__tile--now': {
      backgroundColor: theme.palette.warning.light
    },
    '& .react-calendar__tile:focus': {
      backgroundColor: theme.palette.info.dark
    },
    '& .react-calendar__tile--hover': {
      backgroundColor: theme.palette.action.selected
    },
    '& .react-calendar__tile:enabled:hover, & .react-calendar__tile:enabled:focus': {
      backgroundColor: theme.palette.info.main
    }
  }),
);

export default function Picker (props) {
  return (
    <DateRangePicker
      id='report-date-range-picker'
      {
        ... props
      }
    />
  );
};