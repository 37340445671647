import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import useFeathersService from 'hooks/useFeathersService';
import { get, find, sortBy, startsWith } from 'lodash';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NewDialog from './NewDialog';
import EditDialog from './EditDialog';
import DeleteDialog from './DeleteDialog';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FaceIcon from '@mui/icons-material/Face';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import Decimal from 'decimal.js';

export default function GameIdSelector (props) {
  const { t } = useTranslation();
  const { value = null, disabled, onChange, product, profile } = props;
  const [ alertType, setAlertType ] = useState('info');
  const [ alertMessage, setAlertMessage ] = useState('');
  const [ openNew, setOpenNew ] = useState(false);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ editId, setEditId ] = useState(null);
  const [ deleteId, setDeleteId ] = useState(null);
  const productType = get(product, 'type');
  const profileId = get(profile, '_id');
  const profileRefId = get(profile, 'refId');
  const [ selectDefault, setSelectDefault ] = useState(false);
  const [ snackbarText, setSnackbarText ] = useState('');
  const [ openSnackbar, setOpenSnackBar ] = useState(false);

  const params = {
    query: {
      profileId: profileId,
      type: productType,
      $limit: -1,
    }
  };

  const predicate = (data) => {
    return data.type === productType && data.profileId === profileId;
  };

  const { data, ready, error } = useFeathersService('game-ids', params, predicate);

  useEffect(() => {
    if (!ready) return;

    if (!data.length) {
      setAlert('info', t('No game id records found'));
    } else {
      setAlert('info', '');
      data.length === 1 && setSelectDefault(true);
    }
  }, [data, ready, t]);

  useEffect(() => {
    if (!selectDefault) return;
    const username = get(data, '[0].username');
    username && onChange(data[0].username);
    setSelectDefault(false);
  }, [selectDefault, data, onChange]);

  useEffect(() => {
    if (!error) return;
    const errorMessage = get(error, 'message', t('Unknown error.'));
    setAlert('error', errorMessage);
  }, [error, t]);

  const setAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
  };

  const handleNewClicked = (event) => {
    event.preventDefault();
    setOpenNew(true);
  };

  const handleSelectedItem = (uid) => () => {
    onChange(getUsernameFromId(uid));
  };

  const generateCredentialText = (gid) => {
    const productName = get(product, 'name');
    const balance = get(gid, 'balance.$numberDecimal', '0');
    const username = get(gid, 'username');
    const password = get(gid, 'password');
    let message = [];

    //if (commonUser) {
      message.push(`👤 ${profileRefId}`);
      message.push(`🎮 ${productName}`);
      message.push(`🆔 ${username}`);
      message.push(`🔑 ${password}`);
      message.push(`💲 ${balance}`);
    /*} else {
      message.push(`[GM] ${productName}`);
      message.push(`[PT] ${balance}`);
      message.push(`[ID] ${username}`);
      message.push(`[PW] ${password}`);
    }*/

    return message.join('\r\n');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handleOnCredentialCopy = (event) => {
    setSnackbarText(t('Game id with balance copied'));
    setOpenSnackBar(true);
  };

  const handleOnUsernameCopy = (event) => {
    setSnackbarText(t('Game id copied'));
    setOpenSnackBar(true);
  };

  const handleCopyUsernameClicked = (gid) => () => {
    const username = get(gid, 'username', '');
    navigator.clipboard.writeText(username);
  };

  const handleEditClicked = (uid) => () => {
    setEditId(uid);
    setOpenEdit(true);
  };

  const handleDeleteClicked = (uid) => () => {
    setDeleteId(uid);
    setOpenDelete(true);
  };

  const getUsernameFromId = (id) => {
    const f = getItemFromId(id);
    return get(f, 'username');
  };

  const getItemFromId = (id) => {
    return find(data, { _id: id }) || null;
  };

  const generatePrimaryLabel = (gameId) => {
    const username = get(gameId, 'username');
    const name = get(gameId, 'name', '');
    const label = name ? `${username} (${name})` : `${username}`;
    return label;
  };

  const generateSecondaryLabel = (gameId) => {
    const balanceStr = get(gameId, 'balance.$numberDecimal', '0');
    const decBalance = new Decimal(balanceStr);
    const password = get(gameId, 'password', '');
    return `${password} ($${decBalance.toFixed(2)})`;
  };

  const renderWhatsApp = (gid) => {
    const whatsApp = get(profile, 'whatsApp');
    if (!whatsApp) return null;

    const numberOnlyArr= whatsApp.match(/\d/g);
    if (!numberOnlyArr) return null;
    const numberOnly = numberOnlyArr.join('');

    const productName = get(product, 'name');
    const balance = get(gid, 'balance.$numberDecimal', '0');
    const username = get(gid, 'username');
    const password = get(gid, 'password');

    let message = [];

    //if (commonUser) {
      message.push(encodeURIComponent(`Profile: ${profileRefId}`));
      message.push(encodeURIComponent(`Game: ${productName}`));
      message.push(encodeURIComponent(`Username: ${username}`));
      message.push(encodeURIComponent(`Password: ${password}`));
      message.push(encodeURIComponent(`Balance: ${balance}`));
    /*} else {
      message.push(encodeURIComponent(`[GM] ${productName}`));
      message.push(encodeURIComponent(`[PT] ${balance}`));
      message.push(encodeURIComponent(`[ID] ${username}`));
      message.push(encodeURIComponent(`[PW] ${password}`));
    }*/

    const formattedText = message.join('%0a');
    let formattedNumber = numberOnly;
    if (!startsWith(numberOnly, '6')) formattedNumber = `6${numberOnly}`;
    return (
      <Link href={`https://wa.me/${formattedNumber}?text=${formattedText}`} target='_blank' rel='noreferrer'>
        <Tooltip title={t('Send credential to WhatsApp')}>
          <span>
            <IconButton disabled={disabled} edge='end' aria-label='whatsapp' size="large">
              <WhatsAppIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Link>
    );
  };

  return (
    <Box>
      <List
        dense
        aria-labelledby='game-ids'
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            {t('Game Ids')}
          <span>
            <IconButton disabled={disabled} size='small' color='secondary' aria-label='new-item' onClick={handleNewClicked}>
              <AddBoxIcon />
            </IconButton>
          </span>
          </ListSubheader>
        }
      >
        {
          sortBy(data, ['name', 'username']).map(gid => {
            const uid = gid._id;
            const uniqueLabel = `gid-${uid}`;
            const username = get(gid, 'username');

            return (
              <ListItem disabled={disabled} key={uid} dense button onClick={handleSelectedItem(uid)}>
                <ListItemIcon>
                  <Radio
                    checked={value === username}
                    value={uid}
                    name='game-id'
                    inputProps={{ 'aria-label': uniqueLabel }}
                  />
                </ListItemIcon>
                <ListItemText id={uid} primary={generatePrimaryLabel(gid)} secondary={generateSecondaryLabel(gid)} />
                <ListItemSecondaryAction>
                  {
                    renderWhatsApp(gid)
                  }
                  <Tooltip title={t('Copy credential')}>
                    <span>
                      <CopyToClipboard text={generateCredentialText(gid)} onCopy={handleOnCredentialCopy}>
                        <IconButton disabled={disabled} edge='end' aria-label='copy' size="large">
                          <FileCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('Copy username only')}>
                    <span>
                      <CopyToClipboard text={username} onCopy={handleOnUsernameCopy}>
                        <IconButton
                          disabled={disabled}
                          edge='end'
                          aria-label='copy'
                          onClick={handleCopyUsernameClicked(gid)}
                          size="large">
                          <FaceIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </span>
                  </Tooltip>
                  <IconButton
                    disabled={disabled}
                    edge='end'
                    aria-label='edit'
                    onClick={handleEditClicked(uid)}
                    size="large">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disabled={disabled}
                    edge='end'
                    aria-label='delete'
                    onClick={handleDeleteClicked(uid)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        }
      </List>
      {
        alertMessage && <Alert severity={alertType}>{alertMessage}</Alert>
      }
      <NewDialog { ...props } open={openNew} setOpen={setOpenNew} />
      { getItemFromId(editId) && openEdit && <EditDialog gameId={getItemFromId(editId)} open={openEdit} setOpen={setOpenEdit} /> }
      { getItemFromId(deleteId) && openDelete && <DeleteDialog gameId={getItemFromId(deleteId)} open={openDelete} setOpen={setOpenDelete} /> }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message={snackbarText}
      />
    </Box>
  );
};

GameIdSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};
