import {
  setSelectedBankId,
  setAmount,
  setTransactionDt,
  setStatus,
  setRemark,
  reset,
} from './slice';
import { toNumber } from 'lodash';

export const updateSelectedBankId = id => dispatch => {
  dispatch(setSelectedBankId(id));
  dispatch(setTransactionDt(new Date()));
};

export const updateAmount = (amountStr) => (dispatch, getState) => {
  const amount = toNumber(amountStr);
  dispatch(setAmount(amount));
};

export const updateTransactionDt = (dt) => dispatch => {
  dispatch(setTransactionDt(dt));
};

export const updateStatus = status => dispatch => {
  dispatch(setStatus(status));
};

export const updateRemark = (remark) => dispatch => {
  dispatch(setRemark(remark));
};

export const resetUi = () => dispatch => {
  dispatch(reset());
};
