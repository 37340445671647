import React, { useMemo } from 'react';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { isEmpty } from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function FormikMultipleSelect(props) {
  const { name, label, fullWidth = false, children } = props;
  const [ field, meta ] = useField(props);
  const labelId = `${name}-select-label`;
  const { value: rawValue } = field;

  const value = useMemo(
    () => {
      return (isEmpty(rawValue)) ? [] : rawValue;
    }, [rawValue]
  );

  return (
    <FormControl
      fullWidth={Boolean(fullWidth)}
      error={meta.touched && meta.error}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        { ...field }
        { ...props }
        multiple
        labelId={labelId}
        MenuProps={MenuProps}
        input={<OutlinedInput label={label} />}
        value={value}
      >
        {children}
      </Select>
      {
        !!(meta.touched && meta.error) && <FormHelperText>{meta.error}</FormHelperText>
      }
    </FormControl>
  );
}
