import { useEffect, useState } from 'react';
import feathers from 'services/feathers';
import dayjs from 'dayjs';

function useNewLuckySpin() {
  const service = feathers.service(`/lucky-spins`);
  const [ lastSpin, setLastSpin ] = useState(null);

  useEffect(() => {
    const onCreated = (data) => {
      const { contact, createdAt, reward } = data;
      const formattedDate = dayjs(createdAt).format('HH:mm:ss');

      const text = `[${formattedDate}] ${contact} 🧧${reward.$numberDecimal}`;
      setLastSpin(text);
    };

    service.on('created', onCreated);

    return () => {
      service.removeListener('created', onCreated);
    };
  }, [service]);

  return lastSpin;
};

export default useNewLuckySpin;