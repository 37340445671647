import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import NewProfileDialog from './NewProfileDialog';
import EditProfileDialog from './EditProfileDialog';
import feathers from 'services/feathers';
import { get, findIndex } from 'lodash';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListSubheader from '@mui/material/ListSubheader';
import Radio from '@mui/material/Radio';
import EditIcon from '@mui/icons-material/Edit';
import { isPhoneNumber, formatPossiblePhoneNumber, sanitizePhoneNumber } from 'utils/phone';

// redux store
import { useDispatch, useSelector } from 'react-redux';

import * as actions from './store/actions';
import * as selectors from './store/selectors';

export default function ProfileSelector (props) {
  const dispatch = useDispatch();
  const {
    disabled = false,
    disabledForPending = false,
    onDefaultGameIdFound = undefined,
    reducerPrefix = '',
  } = props;
  const theme = useTheme();
  const profileList = useSelector(selectors.getProfileList(reducerPrefix));
  const helperText = useSelector(selectors.getHelperText(reducerPrefix));
  const isError = useSelector(selectors.getIsError(reducerPrefix));
  const findText = useSelector(selectors.getFindText(reducerPrefix));
  const profile = useSelector(selectors.getProfile(reducerPrefix));

  const { t } = useTranslation();
  const [ openNew, setOpenNew ] = useState(false);
  const [ editProfile, setEditProfile ] = useState(null);

  const onFindChanged = (event) => {
    event.preventDefault();

    dispatch(actions.updateFindText(reducerPrefix, event.target.value));
    if (event.target.value === '' && profile !== null) resetUi();
  };

  const handleFind = async (event) => {
    event.preventDefault();
    dispatch(actions.resetBeforeFind(reducerPrefix));

    if (!findText) return;

    try {
      let orArr = [
        { refId: findText.toLowerCase() },
        { contact: findText },
        { whatsApp: findText },
        { telegram: findText }
      ];

      const formattedData = formatPossiblePhoneNumber(findText);
      if (isPhoneNumber(formattedData)) {
        const phoneNumber = sanitizePhoneNumber(findText);
        orArr.push({ contact: phoneNumber });
        orArr.push({ whatsApp: phoneNumber });
      }

      const findProfile = await feathers.service('profiles').find({
        query: {
          $or: orArr,
          $limit: -1
        }
      });

      if (findProfile.length) {
        dispatch(actions.updateProfileList(reducerPrefix, findProfile));
        return;
      }

      const findGameId = await feathers.service('game-ids').find({
        query: {
          username: {
            $regex: findText.toLowerCase(),
            $options: 'i'
          },
          $populate: [{ path: 'profileId' }],
          $limit: -1
        }
      });

      // Continue to search by game username
      if (findGameId.length) {
        const profileOnly = findGameId.map(p => {
          return p.profileId;
        });
        dispatch(actions.updateProfileList(reducerPrefix, profileOnly));

        // Set default profile, product and game id
        if (profileOnly.length === 1) {
          const defaultGameId = findGameId[0];
          const findProduct = await feathers.service('kiosks').find({
            query: {
              type: defaultGameId.type,
              owner: defaultGameId.owner,
              $limit: 1
            }
          });

          const defaultProductId = get(findProduct, 'data[0]._id');
          const defaultGameUsername = get(defaultGameId, 'username');

          if (defaultGameUsername && defaultProductId && onDefaultGameIdFound) {
            onDefaultGameIdFound(defaultGameUsername, defaultProductId)
          }
        }
        return;
      }

      dispatch(actions.updateError(reducerPrefix, t('Profile not found')));
    } catch (err) {
      dispatch(actions.updateError(reducerPrefix, err.message));
    }
  };

  const selectProfile = (newProfile) => {
    dispatch(actions.updateProfile(reducerPrefix, newProfile));
  };

  const handleProfileCancel = (event) => {
    event.preventDefault();
    resetUi();
  };

  const handleProfileEdit = (profile) => (event) => {
    if (event) event.preventDefault();
    setEditProfile(profile);
  };

  const handleCloseEdit = (open) => {
    if (open) return;
    setEditProfile(null);
  };

  const handleReturnedNewProfile = (newProfile) => {
    dispatch(actions.updateProfileList(reducerPrefix, [ newProfile ]));
    selectProfile(newProfile);
  };

  const handleReturnedEditProfile = (newProfile) => {
    const newProfileList = [ ...profileList ];
    const updateIndex = findIndex(newProfileList, { _id: newProfile._id });

    if (updateIndex >= 0) newProfileList[updateIndex] = newProfile;

    dispatch(actions.updateProfileList(reducerPrefix, newProfileList));
    selectProfile(newProfile);
    setEditProfile(null);
  };

  const handleSelectedItem = (profile) => () => {
    selectProfile(profile);
  };

  const resetUi = () => {
    selectProfile(null);
    dispatch(actions.resetUi(reducerPrefix));
  };

  const renderProfileList = () => {
    if (!profileList.length) return null;
    return (
      <List
        dense
        aria-labelledby='game-ids'
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            {t('Result Count', { count: profileList.length })}
          </ListSubheader>
        }
      >
      {
        profileList.map(p => {
          const uid = get(p, '_id');
          if (uid === undefined) return null;

          const uniqueLabel = `profile-${uid}`;
          const isEnabled = get(p, 'isEnabled', false);

          // Extract stats
          const dpAmt = get(p, 'stats.totalDpAmount.$numberDecimal', '0');
          const wdAmt = get(p, 'stats.totalWdAmount.$numberDecimal', '0');
          const parsedDpAmt = parseFloat(dpAmt);
          const parsedWdAmt = parseFloat(wdAmt);
          const winlossAmt = parsedDpAmt - parsedWdAmt;

          const remark = get(p, 'remark');
          const name = get(p, 'name', '');
          const refId = get(p, 'refId', '');
          const secondaryLabel = !remark ? refId: `${refId} (${remark})`;

          let primaryLabel = `${name} ${t('is disabled')}`;
          if (isEnabled) {
            primaryLabel = (
              <div>
                {`${name} [ `}
                <span>
                  <b style={{color: theme.palette.success.main}}>
                  {parsedDpAmt.toFixed(2)}
                  </b>
                </span>
                {` - `}
                <span>
                  <b style={{color: theme.palette.error.main}}>
                  {parsedWdAmt.toFixed(2)}
                  </b>
                </span>
                {` = `}
                <span>
                  <b style={{color: `${winlossAmt >= 0 ? theme.palette.success.main : theme.palette.error.main}`}}>
                  {winlossAmt.toFixed(2)}
                  </b>
                </span>
                {` ]`}
              </div>
            );
          }

          // Get from react state
          const selectedProfileId = get(profile, '_id');

          return (
            <ListItem disabled={disabled || !isEnabled || disabledForPending} key={uid} dense button onClick={handleSelectedItem(p)}>
              <ListItemIcon>
                <Radio
                  checked={selectedProfileId === uid}
                  value={uid}
                  name='game-id'
                  inputProps={{ 'aria-label': uniqueLabel }}
                  disabled={!isEnabled}
                />
              </ListItemIcon>
              <ListItemText id={uid} primary={primaryLabel} secondary={secondaryLabel} />
              <ListItemSecondaryAction>
                <IconButton
                  disabled={disabled || disabledForPending}
                  edge='end'
                  aria-label='edit'
                  onClick={handleProfileEdit(p)}
                  size="large">
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
      </List>
    );
  };

  return (
    <Box>
      <Box sx={{ m: 2 }} component='form' noValidate autoComplete='off' onSubmit={ (e) => { e.preventDefault(); }}>
        <Box sx={{ width: { sm: '100%', md: '50%'} }}>
          <TextField
            fullWidth
            autoFocus
            required
            id='profileRefId'
            label={t('Profile')}
            error={isError}
            helperText={helperText}
            value={findText}
            onChange={onFindChanged}
            disabled={disabled || disabledForPending}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    disabled={ disabled }
                    aria-label='Cancel Profile'
                    onClick={handleProfileCancel}
                    size="large">
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <ButtonGroup sx={{ mt: 1 }} disabled={disabled || disabledForPending} size='large' variant='contained' aria-label='Profile Actions'>
          <Button type='submit' onClick={handleFind}>{t('Find ↵')}</Button>
          <Button onClick={() => { setOpenNew(true); }}>{t('New')}</Button>
        </ButtonGroup>
      </Box>
      <NewProfileDialog onNewProfile={handleReturnedNewProfile} open={openNew} setOpen={setOpenNew} />
      <EditProfileDialog onEditProfile={handleReturnedEditProfile} open={editProfile !== null} closeEdit={handleCloseEdit} profile={editProfile} />
      {
        renderProfileList()
      }
    </Box>
  );
}

ProfileSelector.propTypes = {
  //onDefaultGameIdFound: PropTypes.func.isRequired
};
