import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  productCount: 1,
  selectedSrcProductId: null,
  selectedSrcGameId: null,
  selectedDestProductIds: [null, null, null, null, null, null, null, null, null, null],
  selectedDestGameIds: [null, null, null, null, null, null, null, null, null, null],
  amount: 0,
  point: 0,
  voidAmount: 0,
  splitPoint: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  productIndex: 0,
  transactionDt: new Date(),
  remark: '',
  status: 'idle',
  voidTransactions: [],
  expanded: false,
  successWdCount: 0,
  successDpCount: 0
};

export const slice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    setProductCount: (state, action) => {
      state.productCount = action.payload;
    },
    setSelectedSrcProductId: (state, action) => {
      state.selectedSrcProductId = action.payload;
    },
    setSelectedSrcGameId: (state, action) => {
      state.selectedSrcGameId = action.payload;
    },
    setSelectedDestProductIdByIndex: (state, action) => {
      state.selectedDestProductIds[action.payload.index] = action.payload.id;
    },
    setSelectedDestGameIdByIndex: (state, action) => {
      state.selectedDestGameIds[action.payload.index] = action.payload.id;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setPoint: (state, action) => {
      state.point = action.payload;
    },
    setVoidAmount: (state, action) => {
      state.voidAmount = action.payload;
    },
    setSplitPointByIndex: (state, action) => {
      state.splitPoint[action.payload.index] = action.payload.point;
    },
    setProductIndex: (state, action) => {
      state.productIndex = action.payload;
    },
    setTransactionDt: (state, action) => {
      state.transactionDt = action.payload;
    },
    setRemark: (state, action) => {
      state.remark = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setVoidTransactions: (state, action) => {
      state.voidTransactions = action.payload;
    },
    setExpanded: (state, action) => {
      state.expanded = action.payload;
    },
    increaseSuccessWdCount: (state, action) => {
      state.successWdCount = state.successWdCount + 1;
    },
    increaseSuccessDpCount: (state, action) => {
      state.successDpCount = state.successDpCount + 1;
    },
    resetSplit: (state, action) => {
      state.splitPoint = initialState.splitPoint;
    },
    reset: (state, action) => {
      const defaultState = { ...initialState };
      defaultState.transactionDt = new Date();
      return defaultState;
    }
  }
});

export const {
  setProductCount,
  setSelectedSrcProductId,
  setSelectedSrcGameId,
  setSelectedDestProductIdByIndex,
  setSelectedDestGameIdByIndex,
  setAmount,
  setPoint,
  setVoidAmount,
  setSplitPointByIndex,
  setProductIndex,
  setTransactionDt,
  setRemark,
  setStatus,
  setVoidTransactions,
  setExpanded,
  increaseSuccessWdCount,
  increaseSuccessDpCount,
  resetSplit,
  reset,
} = slice.actions;

export default slice.reducer;
