const { includes } = require('lodash');

const isHardcodedAccordionValue = (value) => {
  return includes([
    'pending-deposit',
    'pending-withdrawal',
    'deposit-free',
    'transfer'
  ], value);
};

const getHardcodedAccordionHeader = (value) => {
  if (value === 'pending-deposit' || value === 'pending-withdrawal') return 'ba-pending';
  if (value === 'deposit-free' || value === 'transfer') return 'ba-others';
  return false;
};

exports.isHardcodedAccordionValue = isHardcodedAccordionValue;
exports.getHardcodedAccordionHeader = getHardcodedAccordionHeader;
