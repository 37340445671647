import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

export default function OthersAccordion (props) {
  const { t } = useTranslation();
  const { disabled, expanded, onChange, radioValue = null, radioOnChange } = props;

  return (
    <Accordion disabled={disabled} square expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-others-content`}
        id={`panel-others-header`}
      >
        <Typography variant='body1'>{t('Others')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <FormControl>
        <RadioGroup aria-label='others' name='others' value={radioValue} onChange={radioOnChange}>
          <FormControlLabel
            disabled={disabled}
            value={'capital-adjustment'}
            control={<Radio />}
            label={t('Capital Adjustment')}
          />
          <FormControlLabel
            disabled={disabled}
            value={'deposit-free'}
            control={<Radio />}
            label={t('Deposit Free')}
          />
          <FormControlLabel
            disabled={disabled}
            value={'transfer'}
            control={<Radio />}
            label={t('Transfer')}
          />
        </RadioGroup>
      </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

OthersAccordion.propTypes = {
  disabled: PropTypes.bool.isRequired,
  expanded: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  radioOnChange: PropTypes.func.isRequired,
};
