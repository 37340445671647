import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import feathers from 'services/feathers';
import { ResponsiveContainer, Bar, CartesianGrid, XAxis, YAxis, Legend, Line, Tooltip } from 'recharts';
import { BarChart, LineChart, ComposedChart } from 'features/recharts';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useAuth } from 'hooks/useAuth';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { find, get, sumBy, groupBy, map, sortBy } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Dashboard() {
  const { t } = useTranslation();
  const [ data, setData ] = useState(null);
  const [ status, setStatus ] = useState('idle');
  const [ dateRangeType, setDateRangeType ] = useState('today');
  const [ selectedStaffIds, setSelectedStaffIds] = React.useState([]);
  const [ productPerformanceData, setProductPerformanceData ] = useState(null);
  const { user } = useAuth();
  const staffIds = get(user, 'staffIds', []);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const theme = useTheme();

  const handleDateRangeChange = (event) => {
    setDateRangeType(event.target.value);
  };

  const handleSelectedStaffIdsChange = (event) => {
    setSelectedStaffIds(event.target.value);
  };

  // Select no staff id by default
  /*useEffect(() => {
    if (!staffIds.length) return;
    let allStaffIds = [];
    staffIds.forEach(s => {
      allStaffIds.push(s._id);
    });
    setSelectedStaffIds(allStaffIds);
  }, [staffIds]);*/

  function generateDailyPerformance (report) {
    let dailyPerformance = groupBy(report, '_id.date');

    dailyPerformance = map(dailyPerformance, (day, key) => {
      const dpCount = sumBy(day, function (o) {
        const dpCount = get(o, 'dpCount', 0);
        return dpCount;
      });

      const wdCount = sumBy(day, function(o) {
        const wdCount = get(o, 'wdCount', 0);
        return wdCount;
      });

      const dpAmount = sumBy(day, function(o) {
        const amt = get(o, 'dpAmount.$numberDecimal', '0');
        return parseFloat(amt);
      });

      const wdAmount = sumBy(day, function(o) {
        const amt = get(o, 'wdAmount.$numberDecimal', '0');
        return parseFloat(amt);
      });

      return {
        date: key,
        dpCount,
        wdCount,
        dpAmount: dpAmount.toFixed(2),
        wdAmount: wdAmount.toFixed(2),
        balance: (dpAmount - wdAmount).toFixed(2)
      }
    });
    return sortBy(dailyPerformance, 'date');
  };

  function generateProductPerformance (report) {
    let productPerformance = groupBy(report, 'product.name');
    productPerformance = map(productPerformance, (product, key) => {
      const dpCount = sumBy(product, 'dpCount');
      const wdCount = sumBy(product, 'wdCount');
      const dpAmount = sumBy(product, function(o) {
        const amt = get(o, 'dpAmount.$numberDecimal', '0');
        return parseFloat(amt);
      });
      const wdAmount = sumBy(product, function(o) {
        const amt = get(o, 'wdAmount.$numberDecimal', '0');
        return parseFloat(amt);
      });
      return {
        type: key,
        dpCount,
        wdCount,
        dpAmount: dpAmount.toFixed(2),
        wdAmount: wdAmount.toFixed(2),
        totalCount: dpCount + wdCount
      }
    });
    return sortBy(productPerformance, 'type');
  };

  useEffect(() => {
    let isMounted = true;
    async function fetchReport () {
      try {
        setStatus('loading');
        const report = await feathers.service('/action-logs').find({
          query: {
            $aggReport: 'operationStats',
            dateRangeType: dateRangeType,
            staffIds: selectedStaffIds
          }
        });

        if (isMounted) {
          const _dailyPerformance = generateDailyPerformance(report);
          const _productPerformance = generateProductPerformance(report);
          setData(_dailyPerformance);
          setProductPerformanceData(_productPerformance);
        }
      } catch (err) {
        if (isMounted) setGlobalErrorMessage(err);
      }
      if (isMounted) setStatus('idle');
    };

    fetchReport();

    return () => {
      isMounted = false;
    }
  }, [dateRangeType, staffIds, selectedStaffIds, setGlobalErrorMessage]);

  //https://colorhunt.co/palette/196303
  //https://colorhunt.co/palette/191954
  function generateContent () {
    if (status !== 'idle') {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color='secondary' />
        </Box>
      );
    } else {
      return (
        <div>
          <ResponsiveContainer width='100%' height={250}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line name={t('DP Count')} type='monotone' dataKey='dpCount' stroke={`${theme.palette.success.main}`} strokeWidth={2} />
              <Line name={t('WD Count')} type='monotone' dataKey='wdCount' stroke={`${theme.palette.error.main}`} strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={250}>
            <ComposedChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar name={t('DP Point Amount')} dataKey='dpAmount' stackId='a' fill={`${theme.palette.success.main}`} />
              <Bar name={t('WD Point Amount')} dataKey='wdAmount' stackId='a' fill={`${theme.palette.error.main}`} />
              <Line name={t('Balance')} type='monotone' dataKey='balance' stroke={`${theme.palette.info.main}`} strokeWidth={2} />
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={250}>
            <ComposedChart
              data={productPerformanceData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='type' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar name={t('DP Count by Product')} dataKey='dpCount' stackId='a' fill={`${theme.palette.success.main}`} />
              <Bar name={t('WD Count by Product')} dataKey='wdCount' stackId='a' fill={`${theme.palette.error.main}`}  />
              <Line name={t('Total')} type='monotone' dataKey='totalCount' stroke={`${theme.palette.info.main}`} strokeWidth={2} />
            </ComposedChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={250}>
            <BarChart
              data={productPerformanceData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='type' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar name={t('DP Point by Product')} dataKey='dpAmount' stackId='a' fill={`${theme.palette.success.main}`} />
              <Bar name={t('WD Point by Product')} dataKey='wdAmount' stackId='a' fill={`${theme.palette.error.main}`} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    }
  };

  function generateDateRangeSelector () {
    return (
      <FormControl variant='standard'>
        <InputLabel id='dateRangeSelectorLabel'>{t('Date Range')}</InputLabel>
          <Select
            labelId='dateRangeSelectorLabel'
            id='dateRangeSelector'
            value={dateRangeType}
            onChange={handleDateRangeChange}
          >
            <MenuItem value={'today'}>{t('Today')}</MenuItem>
            <MenuItem value={'yesterday'}>{t('Yesterday')}</MenuItem>
            <MenuItem value={'last24h'}>{t('Last 24 hours')}</MenuItem>
            <MenuItem value={'last3d'}>{t('Last 3 days')}</MenuItem>
            <MenuItem value={'last7d'}>{t('Last week')}</MenuItem>
            <MenuItem value={'last1M'}>{t('Last month')}</MenuItem>
            <MenuItem value={'last3M'}>{t('Last 3 months')}</MenuItem>
            <MenuItem value={'last6M'}>{t('Last 6 months')}</MenuItem>
            <MenuItem value={'last1y'}>{t('Last year')}</MenuItem>
          </Select>
      </FormControl>
    );
  };

  function generateStaffIdsSelector () {
    if (!staffIds.length) return null;
    return (
      <FormControl variant='standard'>
        <InputLabel id='staffIdsSelectorLabel'>{t('Staff(s)')}</InputLabel>
        <Select
          labelId='staffIdsSelectorLabel'
          id='staffIdsSelector'
          multiple
          value={selectedStaffIds}
          onChange={handleSelectedStaffIdsChange}
          input={<Input />}
          renderValue={(selected) => {
            let selectedName = [];
            selected.forEach(s => {
              const name = get(find(staffIds, { _id: s }), 'name');
              if (name) selectedName.push(name);
            });
            return selectedName.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {staffIds.map((staffId) => (
            <MenuItem key={staffId._id} value={staffId._id}>
              <Checkbox checked={selectedStaffIds.indexOf(staffId._id) > -1} />
              <ListItemText primary={staffId.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  };

  return (
    <Box>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        {
          generateDateRangeSelector()
        }
        {
          generateStaffIdsSelector()
        }
      </Box>
      {
        generateContent()
      }
    </Box>
  );
}
