import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import CompanyBankSelectorBasic from 'features/companyBankSelector/CompanyBankSelectorBasic';
import CompanyBankSelectorGroupByBank from 'features/companyBankSelector/CompanyBankSelectorGroupByBank';
import CompanyBankSelectorGroupByName from 'features/companyBankSelector/CompanyBankSelectorGroupByName';
import { useAuth } from 'hooks/useAuth';

export default function CompanyBankSelector (props) {
  const { user } = useAuth();
  const bankSelectorType = get(user, 'settings.bankSelectorType', 'basic');

  if (bankSelectorType === 'groupByBank') {
    return (<CompanyBankSelectorGroupByBank { ...props } />);
  } else if (bankSelectorType === 'groupByName') {
    return (<CompanyBankSelectorGroupByName { ...props } />);
  } else {
    return (<CompanyBankSelectorBasic { ...props } />);
  }
};

CompanyBankSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  companyBanks: PropTypes.array.isRequired,
};
