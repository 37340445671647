import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader (props) {
  const { open } = props;
  return (
    <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: '#fff' }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

Loader.propTypes = {
  open: PropTypes.bool.isRequired
};