const { get } = require('lodash');

const formatMessage = (response) => {
  return {
    type: 'success',
    message: generateSuccessMessage(response)
  };
};

const generateSuccessMessage = (response) => {
  const username = get(response, 'username');
  const balance = get(response, 'balance');
  const password = get(response, 'password');
  const message = get(response, 'message');
  const m = [];
  username && m.push(`🆔 ${username}`);
  (balance !== undefined) && m.push(`💲 ${balance}`);
  password && m.push(`🔑 ${password}`);

  if (m.length <= 1) return message;
  return m.join(', ');
};

exports.formatMessage = formatMessage;
