import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import MaterialReactTable from 'material-react-table';
import feathers from 'services/feathers';
import { get, isEmpty } from 'lodash';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import lookupKiosk from 'features/lookup/Kiosk.json';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import CreateModal from 'features/crudModal/Create.js';
import EditModal from 'features/crudModal/Edit.js';
import * as Yup from 'yup';
import { toMongoFilter, toMongoSort } from 'utils/query';

function KioskUrls() {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(t("Required")),
    name: Yup.string().required(t("Required")),
    url: Yup.string().url().required(t("Required")),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const [ createModalOpen, setCreateModalOpen ] = useState(false);
  const [ editModalOpen, setEditModalOpen ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const filters = toMongoFilter(columnFilters);
      const sort = toMongoSort(sorting);
      const query = {
        ...(
          !!globalFilter && {
            '$text': {
              '$search': `${globalFilter}`
            }
          }
        ),
        ...(
          !isEmpty(filters) && {
            ...filters
          }
        ),
        $skip: pagination.pageIndex * pagination.pageSize,
        $limit: pagination.pageSize,
        $sort: sort
      };

      try {
        const response = await feathers.service('kiosk-urls').find({ query });
        return response;
      } catch (err) {
        setGlobalErrorMessage(err);
        return null;
      }
    },
    keepPreviousData: true,
  });

  useEffect(
    () => {
      const service = feathers.service('balance-check-adjustment-logs');

      const onPatched = (data) => {
        refetch();
      };

      service.on('patched', onPatched);

      return () => {
        service.removeListener('patched', onPatched);
      };
    }, [refetch]
  );

  const booleanOptions = useMemo(
    () => {
      return [
        { text: t('true'), value: 'true' },
        { text: t('false'), value: 'false' },
      ];
    }, [t]
  );

  const typeOptions = useMemo(
    () => {
      return Object.keys(lookupKiosk).map((k) => {
        return {
          text: lookupKiosk[k],
          value: k
        }
      });
    }, []
  );

  const columns = useMemo(
    () => {
       const commonColumns = [
        {
          accessorKey: '_id',
          header: t('Id'),
          enableSorting: false,
          enableEditing: false,
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'type',
          header: t('Type'),
          filterSelectOptions: typeOptions,
          filterVariant: 'multi-select',
          Cell: ({ renderedCellValue }) => {
            const humanType = get(lookupKiosk, renderedCellValue, '');
            return <Box component='span'>{humanType}</Box>
          },
          meta: {
            formikEditVariant: 'select',
            selectType: 'kioskType',
          }
        },
        {
          accessorKey: 'name',
          header: t('Name'),
          enableSorting: false,
        },
        {
          accessorKey: 'url',
          header: t('URL'),
          enableSorting: false,
        },
        {
          accessorKey: 'isEnabled',
          header: t('Enabled'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            formikEditVariant: 'checkbox',
            initialValue: true
          }
        },
      ];

      return commonColumns;
    }, [t, typeOptions, booleanOptions]
  );

  const handleUrlPropagate = useCallback(
    async (row) => {
      const url = row.getValue('url');
      const type = row.getValue('type');
      try {
        await feathers.service('kiosks').patch(null,
        {
          url,
        },
        {
          query: {
            type
          }
        });
        setGlobalMessage(t('Success'), 'success');
      } catch (err) {
        setGlobalErrorMessage(err);
      };
    }, [t, setGlobalMessage, setGlobalErrorMessage]
  );

  const handleCreateNewRow = useCallback(
    async (values) => {
      try {
        await feathers.service('kiosk-urls').create(values);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleUpdateRow = useCallback(
    async (values) => {
      const { _id, ...data } = values;
      try {
        await feathers.service('kiosk-urls').patch(_id, data);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(t('Delete confirmation', { text: row.getValue('name') }))
      ) {
        return;
      }

      const kioskId = row.getValue('_id');
      try {
        await feathers.service('kiosk-urls').remove(kioskId);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [t, refetch, setGlobalErrorMessage]
  );

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: t('Actions'),
          },
        }}
        data={data?.data ?? []}
        initialState={{
          columnVisibility: {
            _id: false
          },
          showColumnFilters: false
        }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('Error loading data'),
            }
          : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Stack direction='row' spacing={1}>
            <Tooltip arrow title={t('New Item')}>
              <IconButton onClick={() => setCreateModalOpen(true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={t('Refresh Data')}>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        renderRowActions={({ row, table }) => {
          const canPropagate = ability.can('update', subject('kioskUrls', row?.original));
          const canEdit = ability.can('update', subject('kioskUrls', row?.original));
          const canDelete = ability.can('delete', subject('kioskUrls', row?.original));

          return (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {
                !!canPropagate && <Tooltip arrow placement="right" title={t('Propagate URL')}>
                  <IconButton color="warning" onClick={() => handleUrlPropagate(row)}>
                    <SyncIcon />
                  </IconButton>
                </Tooltip>
              }
              {
                canEdit && (
                 <Tooltip arrow placement="left" title={t('Edit')}>
                    <IconButton onClick={() => {
                      setEditData(row?.original);
                      setEditModalOpen(true);
                    }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              {
                canDelete && (
                 <Tooltip arrow placement="right" title={t('Delete')}>
                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Box>
          );
        }}
        onEditingRowSave={handleUpdateRow}
        rowCount={data?.total ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <CreateModal
        modelName='kioskUrls'
        validationSchema={validationSchema}
        open={createModalOpen}
        columns={columns}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <EditModal
        modelName='kioskUrls'
        validationSchema={validationSchema}
        editData={editData}
        open={editModalOpen}
        columns={columns}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleUpdateRow}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const KioskUrlsWithReactQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <KioskUrls />
  </QueryClientProvider>
);

export default KioskUrlsWithReactQueryProvider;
