import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ProfileSelector from 'features/profileSelector/ProfileSelector';
import ProductSelector from 'features/order/ProductSelector';
import ProductOperation from 'features/productOperation/ProductOperationWd';
import TransactionSaver from 'features/order/TransactionSaver';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import feathers from 'services/feathers';
import { get, find } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Button from '@mui/material/Button';
import { Link, unstable_useBlocker as useBlocker } from 'react-router-dom';
import GameIdSelector from 'features/gameIdSelector/GameIdSelector';
import { useAuth } from 'hooks/useAuth';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import ConfirmNavigation from 'features/order/ConfirmNavigation';

// redux store
import { useDispatch, useSelector } from 'react-redux';

import * as pwdActions from './store/actions';
import * as pwdSelectors from './store/selectors';
import { getProfile } from 'features/profileSelector/store/selectors';
import { resetUi as resetProfileSelector } from 'features/profileSelector/store/actions';

const REDUCER_PREFIX = 'pwd';

export default function PendingWithdrawal () {
  const dispatch = useDispatch();
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const selectedProfile = useSelector(getProfile(REDUCER_PREFIX));
  const selectedProductId = useSelector(pwdSelectors.getSelectedProductId);
  const point = useSelector(pwdSelectors.getPoint);
  const transactionDt = useSelector(pwdSelectors.getTransactionDt);
  const remark = useSelector(pwdSelectors.getRemark);
  const selectedGameId = useSelector(pwdSelectors.getSelectedGameId);
  const isIdle = useSelector(pwdSelectors.getIsIdle);
  const successCount = useSelector(pwdSelectors.getSuccessCount);

  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage} = useGlobalMessageActionsContext();
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const promptOnRouteTransition = get(settings, 'promptOnRouteTransition', true);
  const blocker = useBlocker(!!selectedProfile && promptOnRouteTransition);

  const {
    kiosks: products,
    kiosksReady: productsReady,
    kiosksError: productsError
  } = useContext(CommonContext);

  useEffect(() => {
    if (!productsError) return;
    if (productsError) {
      setGlobalErrorMessage(productsError);
      return;
    }
  }, [productsError, setGlobalErrorMessage]);

  useEffect(() => {
    if (!selectedProfile) return;
    dispatch(pwdActions.updateTransactionDt(new Date()));
  }, [dispatch, selectedProfile]);

  const onProductSelected = (productId) => {
    dispatch(pwdActions.updateSelectedProductId(productId));
  };

  const onPointChanged = useCallback(
    (point) => {
      dispatch(pwdActions.updatePoint(point));
    }, [dispatch]
  );

  const onRemarkChanged = (event) => {
    event.preventDefault();
    dispatch(pwdActions.updateRemark(event.target.value));
  };

  const onTransactionDtChanged = (dt) => {
    dispatch(pwdActions.updateTransactionDt(dt));
  };

  const handleDefaultGameIdFound = (username, productId) => {
    dispatch(pwdActions.updateDefaultGameId(username, productId));
  };

  const onOperationChangedCallback = useCallback(
    (status) => {
      dispatch(pwdActions.updateStatus(status))
    }, [dispatch]
  );

  const onGameIdSelectedCallback = useCallback(
    (gameId) => {
      dispatch(pwdActions.updateSelectedGameId(gameId));
    }, [dispatch]
  );

  const generateProductData = () => {
    const product = getSelectedProductFromId();
    const productType = get(product, 'type');

    return [{
      productType,
      amount: 0,
      point: parseFloat(point),
      action: 'withdrawal',
      username: selectedGameId
    }];
  };

  const onSaveClicked = async (event) => {
    try {
      dispatch(pwdActions.updateStatus('saving'));
      const profileId = get(selectedProfile, '_id');
      const productData = generateProductData();

      const data = {
        profileId,
        products: productData,
        amount: 0,
        remark,
        transactionDt,
        action: 'withdrawal',
        isPending: true
      };

      await feathers.service('transactions').create(data);
      setGlobalMessage(t('Pending WD saved'));
      newOrder();
      dispatch(pwdActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(pwdActions.updateStatus('idle'));
    }
  };

  const tryToSave = (event) => {
    if (successCount <= 0) {
      setConfirmOpen(true);
    } else {
      onSaveClicked(event);
    }
  };

  function newOrder () {
    dispatch(pwdActions.resetUi());
    dispatch(resetProfileSelector(REDUCER_PREFIX));
  };

  const getSelectedProductFromId = () => {
    return find(products, { _id: selectedProductId }) || null;
  };

  const isDisabled = useMemo(
    () => {
      return !selectedProfile || !selectedProfile.isEnabled;
    }, [selectedProfile]
  );

  if (!productsReady) return <Loader open={true} />;

  return (
    <Box>
      <ConfirmNavigation blocker={blocker} />
      <ConfirmDialog
        title={`${t('Incomplete Order Confirmation')}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={onSaveClicked}
      >
        {t('Save without point withdrawal')}
      </ConfirmDialog>
      <Loader open={!isIdle} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined'>
                <ProfileSelector
                  reducerPrefix={REDUCER_PREFIX}
                  onDefaultGameIdFound={handleDefaultGameIdFound}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right' }}>
                <Link to='/transactions/pending-withdrawal' style={{ color: 'inherit', textDecoration: 'none' }}>
                  <Button color='warning' variant='outlined'>{t('Check Pending')}</Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <ProductSelector
                  value={selectedProductId}
                  products={products}
                  onChange={onProductSelected}
                  disabled={isDisabled}
                />
              </Paper>
            </Grid>
            {
              selectedProductId && selectedProfile &&
              (
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 1 }}>
                    <ProductOperation
                      product={getSelectedProductFromId()}
                      profile={selectedProfile}
                      point={point.toString()}
                      setPoint={onPointChanged}
                      onChange={onOperationChangedCallback}
                      gameId={selectedGameId}
                      successCountUpdater={pwdActions.updateSuccessCount}
                    >
                      <GameIdSelector
                        onChange={onGameIdSelectedCallback}
                        value={selectedGameId}
                        product={getSelectedProductFromId()}
                        profile={selectedProfile}
                        disabled={!isIdle}
                      />
                    </ProductOperation>
                  </Paper>
                </Grid>
              )

            }
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ px: 1, py: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      variant='inline'
                      ampm={false}
                      label={t('Datetime')}
                      value={transactionDt}
                      onChange={onTransactionDtChanged}
                      views={['day', 'hours', 'minutes', 'seconds']}
                      inputFormat="YYYY/MM/DD HH:mm:ss"
                      invalidDateMessage={t('Invalid Date Format')}
                      disabled={isDisabled}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='remark'
                      label={t('Remark')}
                      value={remark}
                      onChange={onRemarkChanged}
                      multiline
                      maxRows={4}
                      InputProps={{
                        disabled: !selectedProfile || !selectedProfile.isEnabled,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {
              selectedProductId && selectedProfile &&
              (
                <Grid item xs={12}>
                  <Box sx={{ textAlign: 'right' }}>
                    <TransactionSaver onClick={tryToSave} />
                  </Box>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};