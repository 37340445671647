import { get } from 'lodash';
import { initialState } from './slice';

export const getProductCount = state => get(state, 'transfer.productCount', initialState.productCount);
export const getSelectedSrcProductId = state => get(state, 'transfer.selectedSrcProductId', initialState.selectedSrcProductId);
export const getSelectedSrcGameId = state => get(state, 'transfer.selectedSrcGameId', initialState.selectedSrcGameId);
export const getSelectedDestProductIds = state => get(state, 'transfer.selectedDestProductIds', initialState.selectedDestProductIds);
export const getSelectedDestGameIds = state => get(state, 'transfer.selectedDestGameIds', initialState.selectedDestGameIds);

export const getCurrentDestProductId = state => {
  const productIndex = getProductIndex(state);
  return get(state, `transfer.selectedDestProductIds[${productIndex}]`, null);
};

export const getCurrentDestGameId = state => {
  const productIndex = getProductIndex(state);
  return get(state, `transfer.selectedDestGameIds[${productIndex}]`, null);
};

export const getAmount = state => get(state, 'transfer.amount', initialState.amount);
export const getPoint = state => get(state, 'transfer.point', initialState.point);
export const getVoidAmount = state => get(state, 'transfer.voidAmount', initialState.voidAmount);
export const getSplitPoint = state => get(state, 'transfer.splitPoint', initialState.splitPoint);

export const getCurrentSplitPoint = state => {
  const productIndex = getProductIndex(state);
  return get(state, `transfer.splitPoint[${productIndex}]`, 0);
};

export const getProductIndex = state => get(state, 'transfer.productIndex', initialState.productIndex);
export const getTransactionDt = state => get(state, 'transfer.transactionDt', initialState.transactionDt);
export const getRemark = state => get(state, 'transfer.remark', initialState.remark);
export const getIsIdle = state => get(state, 'transfer.status', initialState.status) === 'idle';
export const getVoidTransactions = state => get(state, 'transfer.voidTransactions', initialState.voidTransactions);
export const getExpanded = state => get(state, 'transfer.expanded', initialState.expanded);
export const getSuccessWdCount = state => get(state, 'transfer.successWdCount', initialState.successWdCount);
export const getSuccessDpCount = state => get(state, 'transfer.successDpCount', initialState.successDpCount);
