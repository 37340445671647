import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  _id: null,
  username: '',
  name: '',
  role: null,
  staffIds: [],
  notes: [],
  settings: {},
  lang: 'en'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initUser: (state, action) => {
      state._id = action.payload._id;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.staffIds = action.payload.staffIds;
      state.notes = action.payload.notes;
      state.settings = action.payload.settings;
      state.lang = action.payload.lang;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateSettings: (state, action) => {
      state.name = action.payload.name;
      state.settings = action.payload.settings;
      state.lang = action.payload.lang;
    },
    setDarkMode: (state, action) => {
      state.settings = {
        ...state.settings,
        darkMode: action.payload
      }
    }
  }
});

export const {
  initUser,
  updateName,
  updateSettings,
  setDarkMode
} = userSlice.actions;

export default userSlice.reducer;
