import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
//import reduxifyServices, { getServicesStatus } from 'feathers-redux';
//import * as fs from './feathersServices';

const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST || 'localhost:3030';
const socket = io(SOCKET_HOST);

const app = feathers();

app.configure(socketio(socket, {
  timeout: 30000
}));

app.configure(auth({
  storage: window.localStorage
}));

export default app;

/*const reduxifyOptions = {
  idField: '_id'
};*/

/*export const feathersServices = reduxifyServices(app, fs.mapServicePathsToNames, reduxifyOptions);
export const getFeathersStatus = (servicesRootState, names = fs.prioritizedListServices) => {
  return getServicesStatus(servicesRootState, names);
};*/
