import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import '@fontsource/roboto';
import RequireAuth from 'features/login/RequireAuth';
import Login from 'features/login/Login';
import Logout from 'features/login/Logout';
import Layout from 'features/layout/Layout';
import Dashboard from 'features/dashboard/Dashboard';
import Operations from 'features/operations/Operations';
import Users from 'features/users/Users';
import History from 'features/history/History';
import ApiKeys from 'features/apiKeys/ApiKeys';
import TextMessages from 'features/textMessages/TextMessages';
import WhatsappSessions from 'features/whatsappSessions/WhatsappSessions';
import Bonuses from 'features/bonuses/Bonuses';
import CompanyBanks from 'features/companyBanks/CompanyBanks';
import Profiles from 'features/profiles/Profiles';
import Transactions from 'features/transactions/Transactions';
import Order from 'features/order/Order';
import Deposit from 'features/order/deposit/Deposit';
import Withdrawal from 'features/order/withdrawal/Withdrawal';
import PendingDeposit from 'features/order/pendingDeposit/PendingDeposit';
import PendingWithdrawal from 'features/order/pendingWithdrawal/PendingWithdrawal';
import Adjustment from 'features/order/adjustment/Adjustment';
import Transfer from 'features/order/transfer/Transfer';
import KioskUrls from 'features/kioskUrls/KioskUrls';
import Kiosks from 'features/kiosks/Kiosks';
import Settings from 'features/settings/Settings';
import LuckySpinSettings from 'features/luckySpinSettings/LuckySpinSettings';
import LuckySpins from 'features/luckySpins/LuckySpins';
import BCALogs from 'features/balanceCheckAdjustmentLogs/BalanceCheckAdjustmentLogs';
import SmsNotistack from 'features/smsNotistack/SmsNotistack';
import SpinNotistack from 'features/spinNotistack/SpinNotistack';
import CommonProvider from 'features/context/CommonProvider';
import KioskUrlsProvider from 'features/context/KioskUrlsProvider';
import GlobalMessageContextProvider from 'features/context/GlobalMessageContext';
import Loader from 'features/loader/Loader';
import { SnackbarProvider } from 'notistack';

// Reporting
import WinLossReport from 'features/reports/WinLoss';
import UniqTrxReport from 'features/reports/UniqTrx';
import OpStatsReport from 'features/reports/OpStats';

// Material ui picker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Theme
import { ThemeProvider } from '@mui/material/styles';
import lightTheme from './themes/light';
import darkTheme from './themes/dark';
import { useUserConfig } from 'hooks/useUserConfig';

function App() {
  const { darkMode } = useUserConfig();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/login" element={
          <Login />
        } />
        <Route path="/" element={
          <CommonProvider>
            <RequireAuth>
              <Layout />
            </RequireAuth>
          </CommonProvider>
        }>
          <Route index element={
            <Dashboard />
          } />
          <Route path="/operations" element={<Operations />} />
          <Route path="/users" element={<Users />} />
          <Route path="/history" element={<History />} />
          <Route path="/api-keys" element={<ApiKeys />} />
          <Route path="/text-messages" element={<TextMessages />} />
          <Route path="/whatsapp-sessions" element={<WhatsappSessions />} />
          <Route path="/bca-logs" element={<BCALogs />} />
          <Route path="/bonuses" element={<Bonuses />} />
          <Route path="/company-banks" element={<CompanyBanks />} />
          <Route path="/profiles" element={<Profiles />} />
          <Route path="/transactions" element={<Transactions />}>
            <Route path="/transactions/:id" element={<Transactions />} />
          </Route>
          <Route path="/order" element={<Order />}>
            <Route path="/order/deposit" element={<Deposit />} />
            <Route path="/order/deposit/:id" element={<Deposit />} />
            <Route path="/order/withdrawal" element={<Withdrawal />} />
            <Route path="/order/withdrawal/:id" element={<Withdrawal />} />
            <Route path="/order/pending-deposit" element={<PendingDeposit />} />
            <Route path="/order/pending-deposit/:id" element={<PendingDeposit />} />
            <Route path="/order/pending-withdrawal" element={<PendingWithdrawal />} />
            <Route path="/order/adjustment" element={<Adjustment />} />
            <Route path="/order/adjustment/:id" element={<Adjustment />} />
            <Route path="/order/transfer" element={<Transfer />} />
          </Route>
          <Route path="/report/winloss" element={<WinLossReport />} />
          <Route path="/report/uniqtrx" element={<UniqTrxReport />} />
          <Route path="/report/opstats" element={<OpStatsReport />} />
          <Route path="/kiosk-urls" element={<KioskUrls />} />
          <Route path="/kiosks" element={
            <KioskUrlsProvider>
              <Kiosks />
            </KioskUrlsProvider>
          } />
          <Route path="/settings" element={<Settings />} />
          <Route path="/lucky-spin-settings" element={<LuckySpinSettings />} />
          <Route path="/lucky-spins" element={<LuckySpins />} />

          <Route path="/logout" element={<Logout />} />
        </Route>
      </>
    )
  );

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme }>
      <Suspense fallback={<Loader open={true} />}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider maxSnack={5}>
            <GlobalMessageContextProvider>
              <RouterProvider router={router} />
            </GlobalMessageContextProvider>
          </SnackbarProvider>
          <SpinNotistack />
          <SmsNotistack />
        </LocalizationProvider>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
