import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import OperationCard from 'features/operationCard/OperationCard';
import feathers from 'services/feathers';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

export default function Operations () {
  const { t } = useTranslation();
  const [ remoteRefresh, setRemoteRefresh ] = useState(false);
  const [ refreshTimeout, setRefreshTimeout ] = useState(null);
  const [ kioskSettings, setKioskSettings ] = useState([]);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  useEffect(() => {
    async function fetchKiosks () {
      const params = {
        query: {
          isEnabled: true,
          $sort: {
            weight: 1
          },
          $limit: -1
        }
      }

      try {
        const kiosks = await feathers.service(`/kiosks`).find(params);
        setKioskSettings(kiosks);
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }

    fetchKiosks();
  }, [setGlobalErrorMessage]);

  useEffect(() => {
    return () => {
      if (refreshTimeout) clearTimeout(refreshTimeout);
    };
  }, [refreshTimeout]);

  const refreshCb = () => {
    if (remoteRefresh) return;
    setRemoteRefresh(true);
    setRefreshTimeout(
      setTimeout(function (){
        setRemoteRefresh(false);
      }, 16000)
    );
  };

  return (
    <Box>
      <Box sx={{ mb: 3, textAlign: 'center' }}>
        <Button variant='contained' color='success' onClick={refreshCb} disabled={remoteRefresh}>
          {t(`Refresh All Product Balance`)}
        </Button>
      </Box>
      <Grid container spacing={2}>
        {
          kioskSettings.length ? kioskSettings.map((ks, i) => {
            if (ks.isEnabled !== true) return null;
            const kioskBalance = ks.balance.$numberDecimal;
            return (
              <Grid item xs={12} md={6} lg={4} xl={3} key={ks._id}>
                <OperationCard kioskSetting={ks} kioskBalance={kioskBalance} remoteRefresh={remoteRefresh} />
              </Grid>
              );
          }) : null
        }
      </Grid>
    </Box>
  );
};