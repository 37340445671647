import React, { useEffect } from 'react';
import {
  useNavigate
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Loader from 'features/loader/Loader';
import { useDispatch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit'

export default function Logout() {
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const dispatch = useDispatch();

  useEffect(() => {
    // this will return { type: '@@RESET' }
    const resetAction = createAction('@@RESET')();
    async function logout() {
      try {
        await auth.logout();
        dispatch(resetAction);
      } catch (err) {
        setGlobalErrorMessage({ err });
      } finally {
        navigate('/', { replace: true });
      }
    }

    logout();
  }, [dispatch, auth, navigate, setGlobalErrorMessage]);

  return (
     <Loader open={true} text={t('Exit')} />
  );
}