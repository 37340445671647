import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { sortBy, get, toNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
} from '@mui/material';

export default function ProductSelector (props) {
  const { t } = useTranslation();
  const { disabled, onChange, products, value = null } = props;

  const handleChange = (event) => {
    event.preventDefault();
    const productId = event.target.value;
    onChange(productId);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>{t('Product')}</FormLabel>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          {
            sortBy(products, ['weight', 'name']).map((product) => {
              // return button in grid item xs={4}
              if (!product.isEnabled) return null;
              const balance = get(product, 'balance.$numberDecimal', '0');
              const balanceCheck = get(product, 'balanceCheck.$numberDecimal', '0');
              const discrepancy = toNumber(balanceCheck) - toNumber(balance);

              return (
                <Grid item xs={6} sm={4} md={3} key={product._id}>
                  <Button
                    size='small'
                    disabled={disabled}
                    fullWidth
                    value={product._id}
                    onClick={handleChange}
                    variant={value === product._id ? 'contained' : 'outlined'}
                    color={discrepancy === 0 ? 'primary' : 'warning'}
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      //color: discrepancy === 0 ? 'inherit' : 'error.dark',
                      fontWeight: discrepancy === 0 ? 'inherit' : 700,
                      // '&.Mui-disabled': {
                      //   color: 'error.main',
                      // },
                    }}
                  >
                    {`${product.name}`}
                  </Button>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

ProductSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
};
