import app from '../services/feathers';
import { useCallback, useEffect, useState } from 'react';

function useSocketIsDisconnected () {
  const [ isDisconnected, setIsDisconnected ] = useState(false);

  const onConnectCb = useCallback(
    () => {
      setIsDisconnected(false);
    },
    [setIsDisconnected]
  );

  const onDisconnectCb = useCallback(
    () => {
      setIsDisconnected(true);
    },
    [setIsDisconnected]
  );

  useEffect(() => {
    app.io.on('connect', onConnectCb);
    app.io.on('disconnect', onDisconnectCb);

    return () => {
      app.io.removeListener('connect', onConnectCb);
      app.io.removeListener('disconnect', onDisconnectCb);
    };
  }, [onConnectCb, onDisconnectCb])

  return isDisconnected;
};

export default useSocketIsDisconnected;