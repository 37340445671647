import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { AbilityContext } from './casl/Can';
import ability from './casl/ability';
import { ProvideAuth } from 'hooks/useAuth';
import { ProvideUserConfig } from 'hooks/useUserConfig';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);

// change to React.Fragment from React.StrictMode for mui...
root.render(
  <React.Fragment>
    <Provider store={store}>
      <ProvideAuth>
        <ProvideUserConfig>
          <AbilityContext.Provider value={ability}>
            <App />
          </AbilityContext.Provider>
        </ProvideUserConfig>
      </ProvideAuth>
    </Provider>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
