import { get } from 'lodash';
import { initialState } from './slice';

export const getSelectedBankId = state => get(state, 'adjustment.selectedBankId', initialState.selectedBankId);
export const getSelectedAction = state => get(state, 'adjustment.selectedAction', initialState.selectedAction);
export const getAmount = state => get(state, 'adjustment.amount', initialState.amount);
export const getCapitalMode = state => get(state, 'adjustment.capitalMode', initialState.capitalMode);
export const getTransactionDt = state => get(state, 'adjustment.transactionDt', initialState.transactionDt);
export const getIsIdle = state => get(state, 'adjustment.status', initialState.status) === 'idle';
export const getRemark = state => get(state, 'adjustment.remark', initialState.remark);
