import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export default function TransactionSaver (props) {
  const { t } = useTranslation();
  const { disabled = false, onClick } = props;
  return (
    <Button color='success' size='large' disabled={disabled} variant='contained' onClick={onClick}>
      {t('Save')}
    </Button>
  );
}

TransactionSaver.propTypes = {
  onClick: PropTypes.func.isRequired,
  //disabled: PropTypes.bool.isRequired,
};
