import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';

export default function ActionSelector (props) {
  const { t } = useTranslation();
  const { value, onChange } = props;
  const actions = [
    {
      value: 'deposit',
      label: t('Deposit')
    },
    {
      value: 'withdrawal',
      label: t('Withdrawal')
    }
  ];

  const handleChange = (event) => {
    const v = event.target.value;
    onChange(v);
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{t('Action')}</FormLabel>
      <RadioGroup aria-label='action' name='action' value={value} onChange={handleChange}>
        {
          actions.map((action) => {
            return (
              <FormControlLabel
                key={action.value}
                value={action.value}
                control={<Radio />}
                label={`${action.label}`}
              />
            )
          })
        }
      </RadioGroup>
    </FormControl>
  );
};

ActionSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
