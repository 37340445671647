import React, { useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { get, truncate, findIndex } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import dayjs from 'dayjs';
import MD5 from "crypto-js/md5";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import LinearProgress from '@mui/material/LinearProgress';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import relativeTime from 'dayjs/plugin/relativeTime';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/en'
import 'dayjs/locale/zh'
dayjs.extend(relativeTime);

export default function JwtRevoke () {
  const { t } = useTranslation();
  const { user } = useAuth();
  const lang = get(user, 'lang', 'en');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const accessToken = localStorage.getItem('feathers-jwt') || '';
  const hashedToken = MD5(accessToken).toString();
  const [ state, setState ] = useState('fetch');
  const [ sessions, setSessions ] = useState([]);
  const [ checked, setChecked ] = useState([]);
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ selectAll, setSelectAll ] = useState(false);

  useEffect(() => {
    if (state !== 'fetch') return;
    let isMounted = true;

    async function fetch () {
      try {
        const result = await feathers.service('/jwt').find();
        if (isMounted) setSessions(result);
      } catch (err) {
        if (isMounted) setGlobalErrorMessage(err);
      }
      if (isMounted) setState('idle');
    };

    fetch();

    return () => {
      isMounted = false;
    }
  }, [state, setGlobalErrorMessage]);

  useEffect(() => {
    if (!selectAll) {
      let newChecked = [];
      setChecked(newChecked);
    } else {
      let newChecked = [];
      sessions.forEach(s => {
        const key = s.key;
        if (key === hashedToken) return;
        newChecked.push(key);
      });
      setChecked(newChecked);
    }
  }, [selectAll, hashedToken, sessions]);

  const handleToggle = (key) => () => {
    const currentIndex = checked.indexOf(key);
    const newChecked = [ ...checked ];

    if (currentIndex === -1) newChecked.push(key);
    else newChecked.splice(currentIndex, 1);
    setChecked(newChecked);
  };

  const handleSessionDelete = (key) => async () => {
    const currentIndex = checked.indexOf(key);
    const newChecked = [ ...checked ];

    if (currentIndex === -1) newChecked.push(key);
    setChecked(newChecked);
    setConfirmOpen(true);
  };

  const deleteLocalSession = (_sessions, key) => {
    const currentIndex = findIndex(_sessions, { key: key });
    if (currentIndex < 0) return _sessions;
    return _sessions.splice(currentIndex, 1);
  };

  const deleteLocalChecked = (_checked, key) => {
    const currentIndex = _checked.indexOf(key);
    if (currentIndex < 0) return _checked;
    return _checked.splice(currentIndex, 1);
  };

  const handleAllClicked = () => {
    setSelectAll(!selectAll);
  };

  const handleDeleteSelected = () => {
    if (checked.length > 0) setConfirmOpen(true);
  };

  const handleSessionsDelete = async () => {
    setState('deleting');
    let newSessions = [ ...sessions ];
    let newChecked = [ ...checked ];
    for (var i = checked.length - 1; i >= 0; i--) {
      const key = checked[i];

      try {
        await feathers.service(`/jwt`).remove(key);
        deleteLocalSession(newSessions, key);
        deleteLocalChecked(newChecked, key);
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }
    setSessions(newSessions);
    setChecked(newChecked);
    setSelectAll(false);
    setState('idle');
  };

  const sessionText = checked.length > 1 ? t('sessions') : t('session');
  const deleteText = checked.length > 0 ? `${t('Delete')} ${checked.length} ${sessionText}` : t('Delete');

  return (
    <Box p={2}>
      {
        state !== 'idle' && <LinearProgress color='secondary' />
      }
      <Typography variant='h6' gutterBottom>
        {
          sessions.length > 1 ?
          t('Active Sessions') :
          t('Active Session')
        }
      </Typography>
      <Box>
        <ButtonGroup disabled={state !== 'idle'} size='small' variant='contained' aria-label='primary button group'>
          <Button disabled={sessions.length <= 1} color={selectAll ? 'primary' : 'inherit'} onClick={handleAllClicked}>{t('All')}</Button>
          <Button disabled={!checked.length} color={checked.length ? 'secondary' : 'inherit'} onClick={handleDeleteSelected}>{deleteText}</Button>
        </ButtonGroup>
      </Box>
      <List>
      {
        sessions.map(session => {
          const key = session.key;
          const truncatedKey = truncate(key, {
            length: 8,
            omission: ''
          });
          const labelId = `session-list-label=${key}`;
          const val = session.value.split(',');
          const ip = get(val, '[0]', 'N/A');
          const epoch = get(val, '[1]', Date.now());
          const lastUsage = dayjs(Number(epoch)).locale(lang).fromNow();
          const isCurrent = key === hashedToken;
          const currentText = isCurrent ? t('(current)') : '';
          const primaryLabel = (
            <Typography variant='overline' display='block' gutterBottom>
              {`${ip} ${truncatedKey}`}
            </Typography>
          );

          const secondaryLabel = (
            <Typography variant='caption' display='block' gutterBottom>
              {`${lastUsage} ${currentText}`}
            </Typography>
          );
          const disabledNeeded = isCurrent || state !== 'idle';
          return (
            <ListItem key={key} dense button disabled={disabledNeeded} onClick={ handleToggle(key)}>
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={checked.indexOf(key) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText primary={primaryLabel} secondary={secondaryLabel} />
              <ListItemSecondaryAction>
                <IconButton
                  disabled={disabledNeeded}
                  edge='end'
                  aria-label='delete-session'
                  onClick={ handleSessionDelete(key) }
                  size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
      </List>
      <ConfirmDialog
        title={`${t('Delete')} ${checked.length} ${checked.length > 1 ? t('Sessions') : t('Session')}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleSessionsDelete}
      >
        {t('Are you sure to delete the selected session(s)?')}
      </ConfirmDialog>
    </Box>
  );

};