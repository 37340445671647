import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import feathers from 'services/feathers';
import { ResponsiveContainer, Bar, CartesianGrid, XAxis, YAxis, Legend, Line, Tooltip } from 'recharts';
import { BarChart, LineChart, ComposedChart } from 'features/recharts';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { find, get, sumBy, groupBy, map, sortBy, filter } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useAuth } from 'hooks/useAuth';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Dashboard() {
  const { t } = useTranslation();
  const [ data, setData ] = useState(null);
  const [ summaryData, setSummaryData ] = useState(null);
  const [ bankUsage, setBankUsage ] = useState(null);
  const [ totalBankBalance, setTotalBankBalance ] = useState(0);
  const [ status, setStatus ] = useState('idle');
  const [ dateRangeType, setDateRangeType ] = useState('today');
  const [ selectedStaffIds, setSelectedStaffIds] = React.useState([]);
  const [ productPerformanceData, setProductPerformanceData ] = useState(null);
  const { user } = useAuth();
  const staffIds = get(user, 'staffIds');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const theme = useTheme();

  const handleDateRangeChange = (event) => {
    setDateRangeType(event.target.value);
  };

  const handleSelectedStaffIdsChange = (event) => {
    setSelectedStaffIds(event.target.value);
  };

  // Select no staff id by default
  /*useEffect(() => {
    if (!staffIds.length) return;
    let allStaffIds = [];
    staffIds.forEach(s => {
      allStaffIds.push(s._id);
    });
    setSelectedStaffIds(allStaffIds);
  }, [staffIds]);*/

  function generateDailyPerformance (report) {
    let dailyPerformance = groupBy(report, '_id.date');

    dailyPerformance = map(dailyPerformance, (day, key) => {
      return {
        date: key,
        dpCount: sumBy(day, function (o) {
          const bank = get(o, 'bank._id');
          if (bank === undefined) return 0
          const dpCount = get(o, 'dpCount', 0);
          return dpCount;
        }),
        wdCount: sumBy(day, function(o) {
          const bank = get(o, 'bank._id');
          if (bank === undefined) return 0
          const wdCount = get(o, 'wdCount', 0);
          return wdCount;
        }),
        dpAmount: sumBy(day, function(o) {
          const bank = get(o, 'bank._id');
          if (bank === undefined) return 0
          const amt = get(o, 'dpAmount.$numberDecimal', '0');
          return parseFloat(amt);
        }).toFixed(2),
        wdAmount: sumBy(day, function(o) {
          const bank = get(o, 'bank._id');
          if (bank === undefined) return 0
          const amt = get(o, 'wdAmount.$numberDecimal', '0');
          return parseFloat(amt);
        }).toFixed(2),
      }
    });
    return sortBy(dailyPerformance, 'date');
  };

  function generateProductPerformance (report) {
    let productPerformance = groupBy(report, 'product.name');
    productPerformance = map(productPerformance, (product, key) => {
      return {
        type: key,
        dpCount: sumBy(product, 'dpCount'),
        wdCount: sumBy(product, 'wdCount'),
        dpPtAmount: sumBy(product, function(o) {
          const amt = get(o, 'dpPtAmount.$numberDecimal', '0');
          return parseFloat(amt);
        }).toFixed(2),
        wdPtAmount: sumBy(product, function(o) {
          const amt = get(o, 'wdPtAmount.$numberDecimal', '0');
          return parseFloat(amt);
        }).toFixed(2),
      }
    });
    return sortBy(productPerformance, 'type');
  };

  function generateSummaryData (bankUsage, report) {
    const totalDpCount = sumBy(bankUsage, 'dpCount');
    const totalWdCount = sumBy(bankUsage, 'wdCount');

    const totalDpAmount = sumBy(bankUsage, function(o) {
      const amt = get(o, 'dpAmount', '0');
      return parseFloat(amt);
    }).toFixed(2);

    const totalWdAmount = sumBy(bankUsage, function(o) {
      const amt = get(o, 'wdAmount', '0');
      return parseFloat(amt);
    }).toFixed(2);

    const totalBonusPaid = sumBy(report, function(o) {
      const amt = get(o, 'bonusPaid.$numberDecimal', '0');
      return parseFloat(amt);
    }).toFixed(2);

    const totalBonusVoided = sumBy(report, function(o) {
      const amt = get(o, 'bonusVoided.$numberDecimal', '0');
      return parseFloat(amt);
    }).toFixed(2);

    const totalBonusLoss = sumBy(report, function(o) {
      const amt = get(o, 'bonusLoss.$numberDecimal', '0');
      return parseFloat(amt);
    }).toFixed(2);

    return {
      totalDpCount,
      totalWdCount,
      totalDpAmount,
      totalWdAmount,
      totalBonusPaid,
      totalBonusVoided,
      totalBonusLoss
    };
  };

  function generateBankUsage (report) {
    let bankUsage = groupBy(report, '_id.bankId');
    bankUsage = filter(bankUsage, (banks, key) => {
      return (key !== 'undefined' && banks.length > 0);
    });
    bankUsage = map(bankUsage, (banks, key) => {
      const bankName = get(banks, '[0].bank.name', '');
      return {
        bankName: bankName,
        dpCount: sumBy(banks, 'dpCount'),
        wdCount: sumBy(banks, 'wdCount'),
        dpAmount: sumBy(banks, function(o) {
          const amt = get(o, 'dpAmount.$numberDecimal', '0');
          return parseFloat(amt);
        }).toFixed(2),
        wdAmount: sumBy(banks, function(o) {
          const amt = get(o, 'wdAmount.$numberDecimal', '0');
          return parseFloat(amt);
        }).toFixed(2),
      }
    });

    bankUsage = map(bankUsage, (banks) => {
      const dpCount = get(banks, 'dpCount', 0);
      const wdCount = get(banks, 'wdCount', 0);

      const totalCount = dpCount + wdCount;
      return {
        ...banks,
        totalCount
      };
    });

    return sortBy(bankUsage, 'bankName');
  };

  function generateTotalBankBalance (banks) {
    const total = sumBy(banks, function(b) {
      const amt = get(b, 'balance.$numberDecimal', '0');
      const isBorrowed = get(b, 'isBorrowed', false);
      if (isBorrowed) return 0;
      return parseFloat(amt);
    }).toFixed(2);
    return total;
  };

  useEffect(() => {
    let isMounted = true;
    async function fetchReport () {
      try {
        setStatus('loading');
        const report = await feathers.service('/transactions').find({
          query: {
            $aggReport: 'salesSummary',
            dateRangeType: dateRangeType,
            staffIds: selectedStaffIds
          }
        });

        const filterByStaffIds = staffIds.length ? {
          owner: {
            $in: selectedStaffIds
          }
        } : {};

        const banks = await feathers.service('company-banks').find({
          query: {
            $limit: -1,
            isEnabled: true,
            ...filterByStaffIds
          }
        });
        if (isMounted) {
          const _dailyPerformance = generateDailyPerformance(report);
          const _productPerformance = generateProductPerformance(report);
          const _totalBankBalance = generateTotalBankBalance(banks);
          const _bankUsage = generateBankUsage(report);
          const _summaryData = generateSummaryData(_bankUsage, report);
          generateBankUsage(report);
          setData(_dailyPerformance);
          setProductPerformanceData(_productPerformance);
          setSummaryData(_summaryData);
          setTotalBankBalance(_totalBankBalance);
          setBankUsage(_bankUsage);
        }
      } catch (err) {
        if (isMounted) setGlobalErrorMessage(err);
      }
      if (isMounted) setStatus('idle');
    };

    fetchReport();

    return () => {
      isMounted = false;
    }
  }, [dateRangeType, staffIds, selectedStaffIds, setGlobalErrorMessage]);

  //https://colorhunt.co/palette/196303
  //https://colorhunt.co/palette/191954
  function generateContent () {
    if (status !== 'idle' || summaryData === null) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color='secondary' />
        </Box>
      );
    } else {
      return (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Current Bank Balance')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    totalBankBalance
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Total DP Count')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    summaryData.totalDpCount
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Total WD Count')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    summaryData.totalWdCount
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Total DP Amount')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    summaryData.totalDpAmount
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Total WD Amount')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    summaryData.totalWdAmount
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Total Bonus Paid')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    summaryData.totalBonusPaid
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Total Bonus Voided')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    summaryData.totalBonusVoided
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper variant='outlined' square sx={{ p: 2 }}>
                <Typography variant='h6' gutterBottom>
                  {
                    t('Total Bonus Loss')
                  }
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  {
                    summaryData.totalBonusLoss
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <ResponsiveContainer width='100%' height={250}>
                <ComposedChart
                  data={bankUsage}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='bankName' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar name={t('DP Count by Bank')} dataKey='dpCount' stackId='a' fill={`${theme.palette.success.main}`} />
                  <Bar name={t('WD Count by Bank')} dataKey='wdCount' stackId='a' fill={`${theme.palette.error.main}`} />
                  <Line name={t('Total Count')} type='monotone' dataKey='totalCount' stroke={`${theme.palette.info.main}`} strokeWidth={2} />
                </ComposedChart>
              </ResponsiveContainer>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={bankUsage}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='bankName' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar name={t('DP Amount by Bank')} dataKey='dpAmount' stackId='a' fill={`${theme.palette.success.main}`} />
                  <Bar name={t('WD Amount by Bank')} dataKey='wdAmount' stackId='a' fill={`${theme.palette.error.main}`} />
                </BarChart>
              </ResponsiveContainer>
              <ResponsiveContainer width='100%' height={250}>
                <LineChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='date' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line name={t('DP Count')} type='monotone' dataKey='dpCount' stroke={`${theme.palette.success.main}`} strokeWidth={2} />
                  <Line name={t('WD Count')} type='monotone' dataKey='wdCount' stroke={`${theme.palette.error.main}`} strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='date' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar name={t('DP Amount')} dataKey='dpAmount' stackId='a' fill={`${theme.palette.success.main}`} />
                  <Bar name={t('WD Amount')} dataKey='wdAmount' stackId='a' fill={`${theme.palette.error.main}`}/>
                </BarChart>
              </ResponsiveContainer>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={productPerformanceData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='type' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar name={t('DP Count by Product')} dataKey='dpCount' stackId='a' fill={`${theme.palette.success.main}`} />
                  <Bar name={t('WD Count by Product')} dataKey='wdCount' stackId='a' fill={`${theme.palette.error.main}`} />
                </BarChart>
              </ResponsiveContainer>
              <ResponsiveContainer width='100%' height={250}>
                <BarChart
                  data={productPerformanceData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='type' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar name={t('DP Point by Product')} dataKey='dpPtAmount' stackId='a' fill={`${theme.palette.success.main}`} />
                  <Bar name={t('WD Point by Product')} dataKey='wdPtAmount' stackId='a' fill={`${theme.palette.error.main}`} />
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Box>
      );
    }
  };

  function generateDateRangeSelector () {
    return (
      <FormControl variant='standard' sx={{ mx: 1, minWidth: 100 }}>
        <InputLabel id='dateRangeSelectorLabel'>{t('Date Range')}</InputLabel>
          <Select
            labelId='dateRangeSelectorLabel'
            id='dateRangeSelector'
            value={dateRangeType}
            onChange={handleDateRangeChange}
          >
            <MenuItem value={'today'}>{t('Today')}</MenuItem>
            <MenuItem value={'yesterday'}>{t('Yesterday')}</MenuItem>
            <MenuItem value={'last24h'}>{t('Last 24 hours')}</MenuItem>
            <MenuItem value={'last3d'}>{t('Last 3 days')}</MenuItem>
            <MenuItem value={'last7d'}>{t('Last week')}</MenuItem>
            <MenuItem value={'last1M'}>{t('Last month')}</MenuItem>
            <MenuItem value={'last3M'}>{t('Last 3 months')}</MenuItem>
            <MenuItem value={'last6M'}>{t('Last 6 months')}</MenuItem>
            <MenuItem value={'last1y'}>{t('Last year')}</MenuItem>
          </Select>
      </FormControl>
    );
  };

  function generateStaffIdsSelector () {
    if (!staffIds.length) return null;
    return (
      <FormControl variant='standard' sx={{ mx: 1, minWidth: 100 }}>
        <InputLabel id='staffIdsSelectorLabel'>{t('Staff(s)')}</InputLabel>
        <Select
          labelId='staffIdsSelectorLabel'
          id='staffIdsSelector'
          multiple
          value={selectedStaffIds}
          onChange={handleSelectedStaffIdsChange}
          input={<Input />}
          renderValue={(selected) => {
            let selectedName = [];
            selected.forEach(s => {
              const name = get(find(staffIds, { _id: s }), 'name');
              if (name) selectedName.push(name);
            });
            return selectedName.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {staffIds.map((staffId) => (
            <MenuItem key={staffId._id} value={staffId._id}>
              <Checkbox checked={selectedStaffIds.indexOf(staffId._id) > -1} />
              <ListItemText primary={staffId.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  };

  return (
    <Box>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        {
          generateDateRangeSelector()
        }
        {
          generateStaffIdsSelector()
        }
      </Box>
      {
        generateContent()
      }
    </Box>
  );
}