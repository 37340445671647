import React, { useContext, useCallback, useEffect } from 'react';
import ActionSelector from 'features/order/ActionSelector';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CompanyBankSelector from 'features/companyBankSelector/CompanyBankSelector';
import TransactionSaver from 'features/order/TransactionSaver';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import feathers from 'services/feathers';
import { get } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useParams, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import ConfirmNavigation from 'features/order/ConfirmNavigation';

// redux store
import { useDispatch, useSelector } from 'react-redux';

import * as adjActions from './store/actions';
import * as adjSelectors from './store/selectors';

export default function Adjustment () {
  const dispatch = useDispatch();
  const selectedBankId = useSelector(adjSelectors.getSelectedBankId);
  const selectedAction = useSelector(adjSelectors.getSelectedAction);
  const amount = useSelector(adjSelectors.getAmount);
  const capitalMode = useSelector(adjSelectors.getCapitalMode);
  const transactionDt = useSelector(adjSelectors.getTransactionDt);
  const isIdle = useSelector(adjSelectors.getIsIdle);
  const remark = useSelector(adjSelectors.getRemark);
  const params = useParams();

  const { t } = useTranslation();
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const promptOnRouteTransition = get(settings, 'promptOnRouteTransition', true);
  const blocker = useBlocker(!!selectedBankId && promptOnRouteTransition);

  const {
    companyBanks,
    companyBanksReady,
    companyBanksError
  } = useContext(CommonContext);

  useEffect(() => {
    if (blocker.stats === 'blocked' && !selectedBankId) blocker.reset?.();
  }, [blocker, selectedBankId]);

  useEffect(() => {
    const bankId = get(params, 'id', null);
    if (bankId) dispatch(adjActions.updateSelectedBankId(bankId));
  }, [dispatch, params]);

  useEffect(() => {
    if (!companyBanksError) return;
    if (companyBanksError) {
      setGlobalErrorMessage(companyBanksError);
      return;
    }
  }, [companyBanksError, setGlobalErrorMessage]);

  const onActionSelectedCallback = useCallback(
    (action) => {
      dispatch(adjActions.updateSelectedAction(action));
    }, [dispatch]
  );

  const onCompanyBankSelectedCallback = useCallback(
    (companyBankId) => {
      dispatch(adjActions.updateSelectedBankId(companyBankId));
    }, [dispatch]
  );

  const onAmountChanged = (event) => {
    event.preventDefault();
    dispatch(adjActions.updateAmount(event.target.value));
  };

  const onCapitalModeChangedCallback = (event, value) => {
    dispatch(adjActions.updateCapitalMode(value));
  }

  const onRemarkChanged = (event) => {
    event.preventDefault();
    dispatch(adjActions.updateRemark(event.target.value));
  };

  const onTransactionDtChanged = (dt) => {
    dispatch(adjActions.updateTransactionDt(dt));
  };

  const onSaveClicked = async (event) => {
    try {
      dispatch(adjActions.updateStatus('saving'));
      const data = {
        bankId: selectedBankId,
        action: selectedAction,
        transactionDt,
        amount: parseFloat(amount),
        capitalMode,
        remark,
        isAdjustment: true
      };

      await feathers.service('transactions').create(data);
      setGlobalMessage(t('Adjustment saved'), 'success');
      newOrder();
      dispatch(adjActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(adjActions.updateStatus('idle'));
    }
  };

  function newOrder () {
    dispatch(adjActions.resetUi());
  };

  if (!companyBanksReady) return <Loader open={true} />;

  return (
    <Box>
      <ConfirmNavigation blocker={blocker} />
      <Loader open={!isIdle} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <CompanyBankSelector
                  disabled={false}
                  companyBanks={companyBanks}
                  value={selectedBankId}
                  onChange={onCompanyBankSelectedCallback}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <ActionSelector
                  value={selectedAction}
                  onChange={onActionSelectedCallback}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
             <Paper variant='outlined' sx={{ px: 1, py: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      variant='inline'
                      ampm={false}
                      label={t('Datetime')}
                      value={transactionDt}
                      onChange={onTransactionDtChanged}
                      views={['day', 'hours', 'minutes', 'seconds']}
                      inputFormat="YYYY/MM/DD HH:mm:ss"
                      invalidDateMessage={t('Invalid Date Format')}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id='amount'
                      label={t('Amount')}
                      value={amount.toString()}
                      onChange={onAmountChanged}
                      InputProps={{
                        disabled: false,
                        type: 'number'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='remark'
                      label={t('Remark')}
                      value={remark}
                      onChange={onRemarkChanged}
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title={t('Capital Mode Usage')}>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          control={
                            <Switch
                              id='capitalMode'
                              checked={capitalMode}
                              onChange={onCapitalModeChangedCallback}
                              name="capitalMode"
                              inputProps={{ 'aria-label': 'capitalMode' }}
                            />
                          }
                          label={t("Capital Mode")}
                          labelPlacement="end"
                        />
                      </FormControl>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right' }}>
                <TransactionSaver onClick={onSaveClicked} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};