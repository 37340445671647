import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import useFeathersService from 'hooks/useFeathersService';
import { get, find, sortBy, trim } from 'lodash';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NewDialog from './NewDialog';
import EditDialog from './EditDialog';
import DeleteDialog from './DeleteDialog';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import lookup from 'features/lookup/Bank';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';

export default function CustomerBankViewer (props) {
  const { t } = useTranslation();
  const { profile } = props;
  const [ alertType, setAlertType ] = useState('info');
  const [ alertMessage, setAlertMessage ] = useState('');
  const [ openNew, setOpenNew ] = useState(false);
  const [ openEdit, setOpenEdit ] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);
  const [ editId, setEditId ] = useState(null);
  const [ deleteId, setDeleteId ] = useState(null);
  const [ openSnackbar, setOpenSnackBar ] = useState(false);
  const profileId = get(profile, '_id');

  const params = {
    query: {
      profileId: profileId,
      $limit: -1,
    }
  };

  const predicate = (data) => {
    return data.profileId === profileId;
  };

  const { data, ready, error } = useFeathersService('customer-banks', params, predicate);

  useEffect(() => {
    if (!ready) return;

    if (!data.length) {
      setAlert('info', t('No customer bank records found'));
    } else {
      setAlert('info', '');
    }
  }, [data, ready, t]);

  useEffect(() => {
    if (!error) return;
    const errorMessage = get(error, 'message', t('Unknown error'));
    setAlert('error', errorMessage);
  }, [error, t]);


  const setAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
  };

  const handleNewClicked = (event) => {
    event.preventDefault();
    setOpenNew(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handleOnCopy = (event) => {
    setOpenSnackBar(true);
  };

  const handleEditClicked = (uid) => () => {
    setEditId(uid);
    setOpenEdit(true);
  };

  const handleDeleteClicked = (uid) => () => {
    setDeleteId(uid);
    setOpenDelete(true);
  };

  const getItemFromId = (id) => {
    return find(data, { _id: id }) || null;
  };

  const generatePrimaryLabel = (customerBank) => {
    const accountHolderName = get(customerBank, 'accountHolderName', '').toUpperCase();
    const bankName = get(lookup, customerBank.bankName, 'N/A');
    const remark = get(customerBank, 'remark', '');

    if (remark) {
      return `${accountHolderName} (${bankName} - ${remark})`;
    }

    return `${accountHolderName} (${bankName})`;
  };

  const generateSecondaryLabel = (customerBank) => {
    const accountNumber = get(customerBank, 'accountNumber', '');
    return `${accountNumber}`;
  };

  return (
    <Box>
      <List
        dense
        aria-labelledby='customer-banks'
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            {t('Customer Banks')}
          <span>
            <IconButton size='small' color='secondary' aria-label='new-item' onClick={handleNewClicked}>
              <AddBoxIcon />
            </IconButton>
          </span>
          </ListSubheader>
        }
      >
        {
          sortBy(data, ['remark', 'bankName']).map(gid => {
            const uid = gid._id;
            const accountNumber = trim(get(gid, 'accountNumber', ''));
            return (
              <ListItem alignItems="flex-start" key={uid}>
                <ListItemText id={uid} primary={generatePrimaryLabel(gid)} secondary={generateSecondaryLabel(gid)} />
                <ListItemSecondaryAction>
                  <CopyToClipboard text={accountNumber} onCopy={handleOnCopy}>
                    <IconButton edge='end' aria-label='copy' size="large">
                      <FileCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                  <IconButton
                    edge='end'
                    aria-label='edit'
                    onClick={handleEditClicked(uid)}
                    size="large">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    onClick={handleDeleteClicked(uid)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        }

      </List>
      {
        alertMessage && <Alert severity={alertType}>{alertMessage}</Alert>
      }
      <NewDialog { ...props } open={openNew} setOpen={setOpenNew} />
      { getItemFromId(editId) && openEdit && <EditDialog customerBank={getItemFromId(editId)} open={openEdit} setOpen={setOpenEdit} /> }
      { getItemFromId(deleteId) && openDelete && <DeleteDialog customerBank={getItemFromId(deleteId)} open={openDelete} setOpen={setOpenDelete} /> }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message={t(`Account number copied`)}
      />
    </Box>
  );
};

CustomerBankViewer.propTypes = {
  profile: PropTypes.object.isRequired,
};
