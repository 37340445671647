import React from 'react';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export default function FormikSelect(props) {
  const { name, label, fullWidth = false, children } = props;
  const [ field, meta ] = useField(props);
  const labelId = `${name}-select-label`;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl
      fullWidth={Boolean(fullWidth)}
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        { ...field }
        { ...props }
        labelId={labelId}
        MenuProps={MenuProps}
      >
        {children}
      </Select>
      {
        !!(meta.touched && meta.error) && <FormHelperText>{meta.error}</FormHelperText>
      }
    </FormControl>
  );
}
