import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import ProfileSelector from 'features/profileSelector/ProfileSelector';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ProductSelector from 'features/order/ProductSelector';
import BonusSelector from 'features/order/BonusSelector';
import CompanyBankSelector from 'features/companyBankSelector/CompanyBankSelector';
import ProductOperation from 'features/productOperation/ProductOperationDp';
import TransactionSaver from 'features/order/TransactionSaver';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import { find, get, reduce, times } from 'lodash';
import CustomerBankViewer from 'features/customerBankViewer/CustomerBankViewer';
import feathers from 'services/feathers';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Button,
  ButtonGroup
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate, useParams, Link, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import GameIdSelector from 'features/gameIdSelector/GameIdSelector';
import { useAuth } from 'hooks/useAuth';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import Stack from '@mui/material/Stack';
import ConfirmNavigation from 'features/order/ConfirmNavigation';

// redux store
import { useDispatch, useSelector } from 'react-redux';

import * as dpActions from './store/actions';
import * as dpSelectors from './store/selectors';
import { getProfile } from 'features/profileSelector/store/selectors';
import { resetUi as resetProfileSelector } from 'features/profileSelector/store/actions';

const REDUCER_PREFIX = 'dp';

export default function Deposit (props) {
  const dispatch = useDispatch();
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const selectedProfile = useSelector(getProfile(REDUCER_PREFIX));
  const productCount = useSelector(dpSelectors.getProductCount);
  const selectedBonus = useSelector(dpSelectors.getSelectedBonus);
  const selectedBankId = useSelector(dpSelectors.getSelectedBankId);
  const transactionDt = useSelector(dpSelectors.getTransactionDt);
  const amount = useSelector(dpSelectors.getAmount);
  const remark = useSelector(dpSelectors.getRemark);
  const productIndex = useSelector(dpSelectors.getProductIndex);
  const selectedProductIds = useSelector(dpSelectors.getSelectedProductIds);
  const currentProductId = useSelector(dpSelectors.getCurrentProductId);
  const currentGameId = useSelector(dpSelectors.getCurrentGameId);
  const selectedGameIds = useSelector(dpSelectors.getSelectedGameIds);

  const currentSplitAmount = useSelector(dpSelectors.getCurrentSplitAmount);
  const currentSplitPoint = useSelector(dpSelectors.getCurrentSplitPoint);
  const splitAmount = useSelector(dpSelectors.getSplitAmount);
  const splitPoint = useSelector(dpSelectors.getSplitPoint);

  const isIdle = useSelector(dpSelectors.getIsIdle);
  const pendingMode = useSelector(dpSelectors.getPendingMode);
  const transactionId = useSelector(dpSelectors.getTransactionId);
  const successCount = useSelector(dpSelectors.getSuccessCount);

  const { t } = useTranslation();

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const promptOnRouteTransition = get(settings, 'promptOnRouteTransition', true);
  const blocker = useBlocker(!!selectedProfile && promptOnRouteTransition);

  const params = useParams();
  const navigate = useNavigate();

  const {
    bonuses,
    bonusesReady,
    bonusesError,
    companyBanks,
    companyBanksReady,
    companyBanksError,
    kiosks: products,
    kiosksReady: productsReady,
    kiosksError: productsError
  } = useContext(CommonContext);

  useEffect(() => {
    if (selectedProfile !== null) return;
    dispatch(dpActions.resetUi());
  }, [selectedProfile, dispatch]);

  useEffect(() => {
    const pendingId = get(params, 'id', null);
    if (pendingId) dispatch(dpActions.updateTransactionId(pendingId));
  }, [dispatch, params]);

  useEffect(() => {
    if (!companyBanksReady || !transactionId) return;

    let isMounted = true;
    async function fetchTransaction () {
      try {
        const transaction = await feathers.service(`/transactions`).get(transactionId);
        const isPending = get(transaction, 'isPending', false);
        if (isMounted && isPending) {
          dispatch(dpActions.updateStatus('loading'));
          dispatch(dpActions.restorePendingTxn(transaction));
          dispatch(dpActions.updateStatus('idle'));
        }
      } catch (err) {
        if (isMounted) {
          setGlobalErrorMessage(err);
          dispatch(dpActions.updateStatus('idle'));
        }
      }
    }
    fetchTransaction();
    return () => {
      isMounted = false;
    }
  }, [dispatch, transactionId, companyBanksReady, setGlobalErrorMessage]);

  useEffect(() => {
    if (!bonusesError && !companyBanksError && !productsError) return;

    if (bonusesError) {
      setGlobalErrorMessage(bonusesError);
      return;
    }
    if (companyBanksError) {
      setGlobalErrorMessage(companyBanksError);
      return;
    }
    if (productsError) {
      setGlobalErrorMessage(productsError);
      return;
    }
  }, [bonusesError, companyBanksError, productsError, setGlobalErrorMessage]);

  const isFreeBonus = useMemo(
    () => {
      return get(selectedBonus, 'depositFree', false);
    }, [selectedBonus]
  );

  const isProfileNotSelected = useMemo(
    () => {
      return !selectedProfile || !selectedProfile.isEnabled;
    }, [selectedProfile]
  );

  // const isFreeFixedBonus = useMemo(
  //   () => {
  //     const mode = get(selectedBonus, 'mode');
  //     const depositFree = get(selectedBonus, 'depositFree', false);
  //     return mode === 'fixed' && depositFree;
  //   }, [selectedBonus]
  // );

  const onSelectedProductIdChanged = (value) => {
    dispatch(dpActions.updateCurrentProductId(value));
  };

  const onSelectedGameIdChange = (value) => {
    dispatch(dpActions.updateCurrentGameId(value));
  };

  const onBonusSelected = useCallback(
    (bonus) => {
      dispatch(dpActions.updateSelectedBonus(bonus));
    }, [dispatch]
  );

  const onCompanyBankSelected = (companyBankId) => {
    if (pendingMode) return;
    dispatch(dpActions.updateSelectedBankId(companyBankId));
  };

  const onAmountChanged = (event) => {
    event.preventDefault();
    dispatch(dpActions.updateAmount(event.target.value));
  };

  const onProductCountChanged = useCallback(
    (event) => {
      event.preventDefault();
      const value = event.target.value;
      const intVal = parseInt(value);
      dispatch(dpActions.updateProductCount(intVal));
    }, [dispatch]
  );

  const onSplitAmountChanged = (event) => {
    event.preventDefault();
    const value = event.target.value;
    dispatch(dpActions.updateCurrentSplitAmount(value));
  };

  const onSplitPointChanged = useCallback(
    (event) => {
      event.preventDefault();
      const value = event.target.value;
      dispatch(dpActions.updateCurrentSplitPoint(value));
    }, [dispatch]
  );

  const onRemarkChanged = (event) => {
    event.preventDefault();
    dispatch(dpActions.updateRemark(event.target.value));
  };

  const onTransactionDtChanged = (dt) => {
    dispatch(dpActions.updateTransactionDt(dt));
  };

  const onProductIndexChanged = (event, value) => {
    event.preventDefault();
    dispatch(dpActions.updateProductIndex(value));
  };

  const onOperationChangedCallback = useCallback(
    (status) => {
      dispatch(dpActions.updateStatus(status));
    }, [dispatch]
  );

  const onSaveClicked = (event) => {
    if (pendingMode) updateTransaction();
    else createTransaction();
  };

  const tryToSave = (event) => {
    if (successCount < productCount) {
      setConfirmOpen(true);
    } else {
      onSaveClicked(event);
    }
  };

  const handleDefaultGameIdFound = (gameId, productId) => {
    dispatch(dpActions.updateDefaultGameId(gameId, productId));
  };

  const generateProductData = () => {
    let products = [];
    for (var i = 0; i < productCount; i++) {
      const product = getProductFromId(selectedProductIds[i]);
      const gameId = selectedGameIds[i];
      if (!product) throw new Error('Invalid / undefined product.');
      const productType = get(product, 'type');
      const amount = splitAmount[i];
      const point = splitPoint[i];
      products.push({
        productType: productType,
        amount,
        point,
        action: 'deposit',
        username: gameId
      });
    }
    return products;
  };

  const updateTransaction = async () => {
    try {
      dispatch(dpActions.updateStatus('saving'));
      const profileId = get(selectedProfile, '_id');
      const bonusId = get(selectedBonus, '_id');
      const productData = generateProductData();

      const data = {
        profileId,
        products: productData,
        bonusId,
        transactionDt,
        isPending: false
      };

      await feathers.service('transactions').patch(transactionId, data);
      setGlobalMessage(t('Pending DP saved'), 'success');
      newOrder();
      dispatch(dpActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(dpActions.updateStatus('idle'));
    }
  };

  const createTransaction = async () => {
    try {
      dispatch(dpActions.updateStatus('saving'));
      const profileId = get(selectedProfile, '_id');
      const bonusId = get(selectedBonus, '_id');
      const productData = generateProductData();

      const data = {
        profileId,
        products: productData,
        bankId: selectedBankId,
        bonusId,
        amount: parseFloat(amount),
        remark,
        transactionDt,
        action: 'deposit'
      };

      await feathers.service('transactions').create(data);
      setGlobalMessage(t('Deposit saved'), 'success');
      newOrder();
      dispatch(dpActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(dpActions.updateStatus('idle'));
    }
  };

  function newOrder () {
    dispatch(dpActions.resetUi());
    dispatch(resetProfileSelector(REDUCER_PREFIX));

    if (transactionId) {
      navigate('/order/deposit', { replace: false });
    }
  };

  const getProductFromId = (productId) => {
    if (!productId) return null;
    return find(products, { _id: productId }) || null;
  };

  const renderProductTabs = () => {
    if (productCount <= 0) return null;
    let tabs = [];

    for (var i = 1; i <= productCount; i++) {
      tabs.push(<Tab label={`#${i}`} key={`product-tab-${i}`} />);
    }

    return tabs;
  };

  const getRemainingAmount = () => {
    const usedAmount = reduce(splitAmount, function (sum, n) {
      return sum + parseFloat(n);
    }, 0);

    const r = parseFloat(amount) - usedAmount;
    return r.toFixed(2);
  };

  const generateProductCountButtons = useCallback(
    () => {
      let buttons = [];
      for (var i = 1; i <= 5; i++) {
        buttons.push(
          <Button
            size='large'
            key={`product-count-${i}`}
            variant={productCount === i ? 'contained' : 'outlined'}
            value={i}
            onClick={onProductCountChanged}
          >
            {i}
          </Button>
        );
      }
      return buttons;
    }, [productCount, onProductCountChanged]
  );

  if (!bonusesReady || !companyBanksReady || !productsReady) return <Loader open={true} />;

  return (
    <Box>
      <ConfirmNavigation blocker={blocker} />
      <ConfirmDialog
        title={`${t('Incomplete Order Confirmation')}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={onSaveClicked}
      >
        {t('Save without point deposit')}
      </ConfirmDialog>
      <Loader open={!isIdle} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined'>
                <ProfileSelector
                  reducerPrefix={REDUCER_PREFIX}
                  onDefaultGameIdFound={handleDefaultGameIdFound}
                />
              </Paper>
            </Grid>
            {
              selectedProfile &&
              (
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 1 }}>
                    <CustomerBankViewer profile={selectedProfile} />
                  </Paper>
                </Grid>
              )
            }
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right' }}>
                <Link to='/transactions/pending-deposit' style={{ textDecoration: 'none' }}>
                  <Button color='warning' variant='outlined'>{t('Check Pending')}</Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <BonusSelector
                  value={selectedBonus}
                  onChange={onBonusSelected}
                  bonuses={bonuses}
                  disabled={isProfileNotSelected}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <CompanyBankSelector
                  value={selectedBankId}
                  companyBanks={companyBanks}
                  onChange={onCompanyBankSelected}
                  disabled={isProfileNotSelected || pendingMode || isFreeBonus}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ px: 1, py: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                     <DateTimePicker
                      variant='inline'
                      ampm={false}
                      label={t('Datetime')}
                      value={transactionDt}
                      onChange={onTransactionDtChanged}
                      views={['day', 'hours', 'minutes', 'seconds']}
                      inputFormat="YYYY/MM/DD HH:mm:ss"
                      invalidDateMessage={t('Invalid Date Format')}
                      disabled={isProfileNotSelected || pendingMode}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id='amount'
                      label={t('Amount')}
                      value={amount.toString()}
                      onChange={onAmountChanged}
                      fullWidth
                      InputProps={{
                        disabled: isProfileNotSelected || pendingMode || isFreeBonus,
                        type: 'number'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='remark'
                      label={t('Remark')}
                      value={remark}
                      onChange={onRemarkChanged}
                      fullWidth
                      multiline
                      maxRows={4}
                      InputProps={{
                        disabled: isProfileNotSelected || pendingMode
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel component='legend'>{t('Product Count')}</FormLabel>
                      <ButtonGroup sx={{ mt: 1 }}>
                        {
                          generateProductCountButtons()
                        }
                      </ButtonGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <Tabs
                  value={productIndex}
                  onChange={onProductIndexChanged}
                  aria-label='product-tab'
                  variant='scrollable'
                  scrollButtons='auto'
                  textColor='primary'
                  indicatorColor='primary'
                >
                  {
                    renderProductTabs()
                  }
                </Tabs>
                <Stack spacing={1} sx={{ mt: 2 }} direction='row'>
                  {
                    !isFreeBonus &&
                    <TextField
                      id='remainingAmount'
                      label={t('Remaining Amount')}
                      type='number'
                      value={getRemainingAmount() || ''}
                      error={getRemainingAmount() !== '0.00'}
                      InputProps={{
                        disabled: true
                      }}
                    />
                  }
                  {
                    !isFreeBonus &&
                    <TextField
                      id='splitAmount'
                      label={t('Split Amount')}
                      type='number'
                      value={currentSplitAmount.toString()}
                      onChange={onSplitAmountChanged}
                      InputProps={{
                        disabled: false
                      }}
                    />
                  }
                  <TextField
                    id='splitPoint'
                    label={t('Split Point')}
                    type='number'
                    value={currentSplitPoint.toString()}
                    onChange={onSplitPointChanged}
                    InputProps={{
                      disabled: true
                    }}
                  />
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              {
                times(productCount, null).map((index) => {
                  return (
                    <Paper variant='outlined' sx={{ p: 1 }}
                      hidden={index !== productIndex}
                      key={`tab-product-${index}`}
                    >
                      <ProductSelector
                        value={currentProductId}
                        products={products}
                        onChange={onSelectedProductIdChanged}
                        disabled={isProfileNotSelected}
                      />
                    </Paper>
                  )
                })
              }
            </Grid>
            {
              currentProductId && selectedProfile &&
              (
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 1 }}>
                    <ProductOperation
                      product={getProductFromId(currentProductId)}
                      profile={selectedProfile}
                      calculatedPoint={currentSplitPoint.toString()}
                      onChange={onOperationChangedCallback}
                      onSplitPointChanged={onSplitPointChanged}
                      gameId={currentGameId}
                      pointUnlock={isFreeBonus}
                      successCountUpdater={dpActions.updateSuccessCount}
                    >
                      <GameIdSelector
                        onChange={onSelectedGameIdChange}
                        value={currentGameId}
                        product={getProductFromId(currentProductId)}
                        profile={selectedProfile}
                        disabled={!isIdle}
                      />
                    </ProductOperation>
                  </Paper>
                </Grid>
              )
            }
            {
              (productIndex === productCount - 1) &&
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'right' }}>
                  <TransactionSaver onClick={tryToSave} />
                </Box>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};