import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useAuth } from 'hooks/useAuth';
import useNewLuckySpin from 'hooks/useNewLuckySpin';
import useSound from 'use-sound';
import notificationSound from 'assets/sounds/notification_coin.mp3';
import { get } from 'lodash';

function SpinNotistack() {
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ play ] = useSound(notificationSound);

  const lastLuckySpin = useNewLuckySpin();

  useEffect(() => {
    if (userRole !== 'user' || !lastLuckySpin) return;

    enqueueSnackbar(lastLuckySpin, {
      autoHideDuration: 30000,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'bottom'
      }
    });
    play();
  }, [userRole, lastLuckySpin, play, enqueueSnackbar, closeSnackbar]);

  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default function IntegrationNotistack() {
  const notistackRef = React.createRef();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      maxSnack={5}
      hideIconVariant={true}
      ref={notistackRef}
      action={(key) => (
        <Button color='secondary' onClick={onClickDismiss(key)}>
          Close
        </Button>
      )}
    >
      <SpinNotistack />
    </SnackbarProvider>
  );
}
