import { BarChart as ReBarChart, LineChart as ReLineChart, ComposedChart as ReComposedChart } from 'recharts';
import { styled } from '@mui/material/styles';

const colorOverride = ({ theme} ) => ({
  '& .recharts-default-tooltip': {
    backgroundColor: `${theme.palette.background.default} !important`,
    border: `1px solid ${theme.palette.action.active} !important`
  },
  '& .recharts-tooltip-label': {
    color: `${theme.palette.text.secondary} !important`
  },
  '& .recharts-tooltip-cursor': {
    fill: `${theme.palette.action.selected} !important`
  },
  '& .recharts-text': {
    fill: `${theme.palette.text.secondary} !important`
  },
  '& g.recharts-cartesian-grid-horizontal > line, & g.recharts-cartesian-grid-vertical > line': {
    fill: `${theme.palette.text.secondary} !important`,
    stroke: `${theme.palette.text.secondary} !important`
  },
  '& .recharts-active-dot > circle': {
    stroke: `${theme.palette.action.selected} !important`
  }
});

export const BarChart = styled(ReBarChart, {
})(
  colorOverride
);

export const LineChart = styled(ReLineChart, {
})(
  colorOverride
);

export const ComposedChart = styled(ReComposedChart, {
})(
  colorOverride
);
