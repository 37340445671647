import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  profileList: [],
  helperText: '',
  isError: false,
  findText: '',
  profile: null,
};

export const slice = createSlice({
  name: 'profileSelector',
  initialState,
  reducers: {
    setFindText: (state, action) => {
      state.findText = action.payload.text;
    },
    setProfileList: (state, action) => {
      state.profileList = action.payload.list;
    },
    setError: (state, action) => {
      state.isError = true;
      state.helperText = action.payload.errorText;
    },
    setProfile: (state, action) => {
      state.profile = action.payload.profile;
    },
    resetWithoutFindText: (state, action) => {
      state.profileList = [];
      state.helperText = '';
      state.isError = false;
      state.profile = null;
    },
    reset: (state, action) => {
      return initialState;
    }
  }
});

export const {
  setFindText,
  setProfileList,
  setError,
  setProfile,
  resetWithoutFindText,
  reset,
} = slice.actions;

export default slice.reducer;
