import {
  setProductCount,
  setSelectedBonus,
  setSelectedBankId,
  setSelectedGameIdByIndex,
  setSelectedProductIdByIndex,
  setTransactionDt,
  setAmount,
  setRemark,
  setProductIndex,
  setSplitAmountByIndex,
  setSplitPointByIndex,
  setStatus,
  setPendingMode,
  setTransactionId,
  increaseSuccessCount,
  resetSplit,
  reset,
} from './slice';
import { get, toNumber } from 'lodash';
import {
  getProductIndex,
  getSelectedBonus,
  getProductCount,
  getAmount,
  getPendingMode,
  getSplitAmount,
} from './selectors';
import { calculatePointByBonus } from 'features/order/helper';

export const updateProductCount = count => (dispatch, getState) => {
  let checkedCount = count;
  if (checkedCount < 1) checkedCount = 1;
  else if (checkedCount > 10) checkedCount = 10;
  dispatch(setProductCount(checkedCount));
  dispatch(resetSplit());

  // set product index to last
  const index = getProductIndex(getState());
  if (index > checkedCount - 1) dispatch(setProductIndex(checkedCount - 1));

  // recalculate
  dispatch(calculateBonus());
};

export const updateSelectedBonus = bonus => (dispatch, getState) => {
  dispatch(setSelectedBonus(bonus));
  dispatch(resetSplit());

  // to make sure deposit free amount always 0
  const depositFree = get(bonus, 'depositFree', false);
  const mode = get(bonus, 'mode');
  const pendingMode = getPendingMode(getState());
  if (depositFree && mode === 'fixed' && !pendingMode) dispatch(setAmount(0));
  dispatch(calculateBonus());
};

export const updateSelectedBankId = id => dispatch => {
  dispatch(setSelectedBankId(id));
  dispatch(setTransactionDt(new Date()));
};

// for product index 0 only
export const updateDefaultGameId = (gameId, productId) => dispatch => {
  dispatch(setSelectedGameIdByIndex({ index: 0, id: gameId }));
  dispatch(setSelectedProductIdByIndex({ index: 0, id: productId }));
};

export const updateTransactionDt = (dt) => dispatch => {
  dispatch(setTransactionDt(dt));
};

export const updateAmount = (amountStr) => (dispatch, getState) => {
  const amount = toNumber(amountStr);
  dispatch(setAmount(amount));
  dispatch(calculateBonus());
};

const calculateBonus = () => (dispatch, getState) => {
  const count = getProductCount(getState());
  if (count > 2) return;

  const amount = getAmount(getState());
  const bonus = getSelectedBonus(getState());

  if (count === 1) {
    const point = calculatePointByBonus(amount, bonus);
    dispatch(setSplitAmountByIndex({ index: 0, amount }));
    dispatch(setSplitPointByIndex({ index: 0, point }));
  } else {
    const partedAmount = amount / 2;
    const partedPoint = calculatePointByBonus(partedAmount, bonus);
    dispatch(setSplitAmountByIndex({ index: 0, amount: partedAmount }));
    dispatch(setSplitPointByIndex({ index: 0, point: partedPoint }));
    dispatch(setSplitAmountByIndex({ index: 1, amount: partedAmount }));
    dispatch(setSplitPointByIndex({ index: 1, point: partedPoint }));
  }
};

export const updateRemark = (remark) => dispatch => {
  dispatch(setRemark(remark));
};

export const restorePendingTxn = (transaction) => dispatch => {
  const savedAmount = get(transaction, 'amount.$numberDecimal', '0');
  const savedBankId = get(transaction, 'bankId');
  const savedTransactionDt = get(transaction, 'transactionDt');
  const savedRemark = get(transaction, 'remark', '');
  const amount = toNumber(savedAmount);
  dispatch(setAmount(amount));
  dispatch(setSelectedBankId(savedBankId));
  dispatch(setTransactionDt(savedTransactionDt));
  dispatch(setRemark(savedRemark));
};

export const updateProductIndex = (index) => (dispatch, getState) => {
  dispatch(setProductIndex(index));
  const count = getProductCount(getState());

  if (count < 3 || (index + 1) !== count) return;

  const splitAmount = getSplitAmount(getState());

  const currentAmount = splitAmount[index];
  if (currentAmount !== 0) return;

  const amount = getAmount(getState());
  const bonus = getSelectedBonus(getState());

  let usedAmount = 0;
  for (let i = 0; i < count - 1; i++) {
    usedAmount += splitAmount[i];
  }

  const remainingAmount = parseFloat(amount) - usedAmount;
  const point = calculatePointByBonus(remainingAmount, bonus);

  dispatch(setSplitAmountByIndex({ index: index, amount: remainingAmount }));
  dispatch(setSplitPointByIndex({ index: index, point }));
};

export const updateCurrentProductId = id => (dispatch, getState) => {
  const index = getProductIndex(getState());
  dispatch(setSelectedProductIdByIndex({ index, id }));
  dispatch(setSelectedGameIdByIndex({ index, id: null }));
};

export const updateCurrentGameId = id => (dispatch, getState) => {
  const index = getProductIndex(getState());
  dispatch(setSelectedGameIdByIndex({ index, id }));
};

export const updateCurrentSplitAmount = amountStr => (dispatch, getState) => {
  const index = getProductIndex(getState());
  const bonus = getSelectedBonus(getState());
  const amount = toNumber(amountStr);
  const point = calculatePointByBonus(amount, bonus);
  dispatch(setSplitAmountByIndex({ index, amount }));
  dispatch(setSplitPointByIndex({ index, point }));
};

export const updateCurrentSplitPoint = pointStr => (dispatch, getState) => {
  const index = getProductIndex(getState());
  const point = toNumber(pointStr);
  dispatch(setSplitPointByIndex({ index, point }));
};

export const updateStatus = status => dispatch => {
  dispatch(setStatus(status));
};

export const updatePendingMode = isPending => dispatch => {
  dispatch(setPendingMode(isPending));
};

export const updateTransactionId = id => dispatch => {
  dispatch(setTransactionId(id));
  if (id) dispatch(setPendingMode(true));
};

export const updateSuccessCount = () => dispatch => {
  dispatch(increaseSuccessCount());
};

export const resetUi = () => dispatch => {
  dispatch(reset());
};
