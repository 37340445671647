import React, { useEffect, useState, useMemo, useCallback } from 'react';
import MaterialReactTable from 'material-react-table';
import feathers from 'services/feathers';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import lookupKiosk from 'features/lookup/Kiosk.json';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from 'hooks/useAuth';
import dayjs from 'dayjs';
import { toMongoFilter, toMongoSort } from 'utils/query';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import GameDialog from 'features/gameDialog/GameDialog';

function History() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ gameLog, setGameLog ] = useState(null);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'createdAt', desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const filters = toMongoFilter(columnFilters);
      const sort = toMongoSort(sorting);
      const query = {
        ...(
          !!globalFilter && {
            '$text': {
              '$search': `${globalFilter}`
            }
          }
        ),
        ...(
          !isEmpty(filters) && {
            ...filters
          }
        ),
        $skip: pagination.pageIndex * pagination.pageSize,
        $limit: pagination.pageSize,
        $populate: [{ path: 'executeBy', select: ['username'] }],
        $sort: sort
      };

      try {
        const response = await feathers.service('action-logs').find({ query });
        return response;
      } catch (err) {
        setGlobalErrorMessage(err);
        return null;
      }
    },
    keepPreviousData: true,
  });

  useEffect(
    () => {
      const service = feathers.service('balance-check-adjustment-logs');

      const onPatched = (data) => {
        refetch();
      };

      service.on('patched', onPatched);

      return () => {
        service.removeListener('patched', onPatched);
      };
    }, [refetch]
  );

  const actionOptions = useMemo(
    () => {
      const types = ['deposit', 'withdrawal', 'newuser', 'updateuser', 'balance', 'productbalance', 'gamelog'];
      return types.map((type) => ({ text: t(type), value: type }));
    }, [t]
  );

  const typeOptions = useMemo(
    () => {
      return Object.keys(lookupKiosk).map((k) => {
        return {
          text: lookupKiosk[k],
          value: k
        }
      });
    }, []
  );

  const handleGameDialogClick = useCallback(
    (data) => (event) => {
      event?.preventDefault();
      setGameLog(JSON.stringify(data));
    }, []
  );

  const hasGameLog = useMemo(
    () => {
      return !!gameLog;
    }, [gameLog]
  );

  const handleGameDialogClose = useCallback(
    (event) => {
      event?.preventDefault();
      setGameLog(null);
    }, []
  );

  const columns = useMemo(
    () => {
      const ownerColumn = (userRole === 'user') ? null : {
        accessorFn: (row) => {
          return get(row, 'executeBy.username');
        },
        id: 'executeBy.username',
        header: t('Executer'),
        enableSorting: false,
      };

      const commonColumns = [
        {
          accessorKey: '_id',
          header: t('Id'),
          enableSorting: false,
        },
        {
          accessorKey: 'action',
          header: t('Action'),
          enableSorting: false,
          filterSelectOptions: actionOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
        },
        {
          accessorKey: 'request.kioskSetting.type',
          header: t('Kiosk Type'),
          filterSelectOptions: typeOptions,
          filterVariant: 'multi-select',
          enableSorting: false,
        },
        {
          accessorKey: 'request.kioskSetting.name',
          header: t('Kiosk Name'),
          enableSorting: false,
          enableColumnFilter: false,
        },
        {
          accessorFn: (row) => {
            const username = get(row, 'request.username', get(row, 'response.username', ''));
            return username;
          },
          id: 'username',
          header: t('Username'),
          enableSorting: false,
          enableColumnFilter: false,
        },
        {
          accessorFn: (row) => {
            const amount = get(row, 'request.amount', get(row, 'response.amount', 0));
            return amount;
          },
          id: 'amount',
          header: t('Amount'),
          enableSorting: false,
          enableColumnFilter: false,
        },
        {
          accessorFn: (row) => {
            const action = row?.action || '';
            if (action !== 'gamelog') return get(row, 'response.message', '');

            const hasData = row?.response?.data?.length ? true : false;
            if (!hasData) return null;

            return (
              <Box component='span'>
                <Button onClick={handleGameDialogClick(row)} size='small' color='success' variant='contained'>{t('View')}</Button>
              </Box>
            );
          },
          id: 'responseMessage',
          header: t('Response Message'),
          enableSorting: false,
          enableColumnFilter: false,
        },
        {
          accessorFn: (row) => {
            return get(row, 'response.error.message', '');
          },
          id: 'errorMessage',
          header: t('Error Message'),
          enableSorting: false,
          enableColumnFilter: false,
        },
        {
          accessorKey: 'createdAt',
          header: t('Created At'),
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
        },
        {
          accessorKey: 'updatedAt',
          header: t('Updated At'),
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
        },
      ];

      return ownerColumn ? [ownerColumn, ...commonColumns] : commonColumns;
    }, [t, userRole, typeOptions, actionOptions, handleGameDialogClick]
  );

  return (
    <Box>
      <GameDialog open={hasGameLog} data={gameLog} onClose={handleGameDialogClose} />
      <MaterialReactTable
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: t('Actions'),
          },
        }}
        data={data?.data ?? []}
        initialState={{
          columnVisibility: {
            _id: false,
            'request.kioskSetting.type': false,
            updatedAt: false,
          },
          showColumnFilters: false
        }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('Error loading data'),
            }
          : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Stack direction='row' spacing={1}>
            <Tooltip arrow title={t('Refresh Data')}>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        rowCount={data?.total ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const HistoryWithReactQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <History />
  </QueryClientProvider>
);

export default HistoryWithReactQueryProvider;
