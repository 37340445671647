import React, { useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
} from '@mui/material';
import { AbilityContext } from 'casl/Can';
import TextInput from 'features/formikControl/Text';
import Select from 'features/formikControl/Select';
import MultipleSelect from 'features/formikControl/MultipleSelect';
import Checkbox from 'features/formikControl/Checkbox';
import SelectKioskType from 'features/formikControl/Select/KioskType';
import SelectKioskUrl from 'features/formikControl/Select/KioskUrl';
import SelectCountry from 'features/formikControl/Select/Country';
import SelectBank from 'features/formikControl/Select/Bank';
import DateTimePicker from 'features/formikControl/DateTimePicker';
import ColorPicker from 'features/formikControl/ColorPicker';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { get, startCase, isEmpty } from 'lodash';

const CreateModal = ({ modelName, validationSchema, open, columns, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);

  const [values] = useState(() =>
    columns.reduce((acc, column) => {
      const enableCreating = get(column, 'meta.enableCreating', true);
      if (!enableCreating) return acc;
      const initialValue = get(column, 'meta.initialValue', '');
      acc[column.accessorKey ?? ''] = initialValue;
      return acc;
    }, {}),
  );

  function formatData(data) {
    const ret = columns.reduce((acc, column) => {
      const enableCreating = get(column, 'meta.enableCreating', true);
      const columnKey = column.accessorKey ?? '';
      if (!enableCreating || !columnKey) return acc;

      let columnValue = get(data, columnKey);
      const replaceEmpty = get(column, 'meta.replaceEmpty', false);

      if (isEmpty(columnValue) && replaceEmpty) columnValue = undefined;

      const dataFormatter = get(column, 'meta.dataFormatter');
      if (typeof dataFormatter === 'function') columnValue = dataFormatter(columnValue);

      return {
        ...acc,
        [columnKey]: columnValue
      };
    }, {});

    return ret;
  }

/*  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };
*/
  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose}>
      <DialogTitle>{t(startCase(modelName))}</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const formattedData = formatData(values);
            onSubmit(formattedData);
            setSubmitting(false);
            onClose();
          }}
        >
        {
          props => (<Form id="createForm">
            <Stack spacing={2} sx={{ my: 1 }}>
            {
              columns.map((column) => {
                const enableCreating = get(column, 'meta.enableCreating', true);
                if (!enableCreating) return null;

                const editVariant = get(column, 'meta.formikEditVariant', 'text');
                const columnId = get(column, 'id');
                const isPermitted = ability.can('create', modelName, columnId);

                switch (editVariant) {
                  case 'text':
                    const textType = get(column, 'meta.textType', 'text');
                    const inputMode = get(column, 'meta.inputMode');
                    return (
                      <TextInput
                        key={columnId}
                        label={column.header}
                        name={column.accessorKey}
                        type={textType}
                        disabled={!isPermitted}
                        {
                          ...(!!inputMode && {
                            inputMode,
                            inputProps: { inputMode }
                          })
                        }
                      />
                    );

                  case 'date-time':
                    return (
                      <DateTimePicker
                        key={columnId}
                        label={column.header}
                        name={column.accessorKey}
                        disabled={!isPermitted}
                      />
                    );

                  case 'color':
                    return (
                      <ColorPicker
                        key={columnId}
                        label={column.header}
                        name={column.accessorKey}
                        disabled={!isPermitted}
                      />
                    );

                  case 'select':
                    const selectType = get(column, 'meta.selectType');

                    switch (selectType) {
                      case 'kioskType':
                        return (
                          <SelectKioskType
                            key={columnId}
                            label={column.header}
                            name={column.accessorKey}
                            disabled={!isPermitted}
                          />
                        );

                      case 'kioskUrl':
                        return (
                          <SelectKioskUrl
                            key={columnId}
                            label={column.header}
                            name={column.accessorKey}
                            disabled={!isPermitted}
                          />
                        );

                      case 'country':
                        return (
                          <SelectCountry
                            key={columnId}
                            label={column.header}
                            name={column.accessorKey}
                            disabled={!isPermitted}
                          />
                        );

                      case 'bank':
                        return (
                          <SelectBank
                            key={columnId}
                            label={column.header}
                            name={column.accessorKey}
                            disabled={!isPermitted}
                          />
                        );

                      default:
                        const selectOptions = get(column, 'editSelectOptions', []);
                        const translateOption = get(column, 'meta.translateOption', false);
                        const emptyOption = get(column, 'meta.emptyOption', false);

                        return (
                          <Select
                            key={columnId}
                            label={column.header}
                            name={column.accessorKey}
                            disabled={!isPermitted}
                          >
                          {
                            !!emptyOption && <MenuItem value={''}><em>{t('None')}</em></MenuItem>
                          }
                          {
                            selectOptions.map(({ text, value }) => {
                              return (
                                <MenuItem key={`${text}${value}`} value={value}>
                                  {
                                    !!translateOption ? t(text) : text
                                  }
                                </MenuItem>
                              );
                            })
                          }
                          </Select>
                        )
                    }

                  case 'multi-select':
                    const selectOptions = get(column, 'editSelectOptions', []);
                    const translateOption = get(column, 'meta.translateOption', false);
                    const emptyOption = get(column, 'meta.emptyOption', false);

                    return (
                      <MultipleSelect
                        key={columnId}
                        label={column.header}
                        name={column.accessorKey}
                        disabled={!isPermitted}
                      >
                      {
                        !!emptyOption && <MenuItem value={''}><em>{t('None')}</em></MenuItem>
                      }
                      {
                        selectOptions.map(({ text, value }) => {
                          return (
                            <MenuItem key={`${text}${value}`} value={value}>
                              {
                                !!translateOption ? t(text) : text
                              }
                            </MenuItem>
                          );
                        })
                      }
                      </MultipleSelect>
                    );

                  case 'checkbox':
                    return (
                      <Checkbox
                        key={columnId}
                        label={column.header}
                        name={column.accessorKey}
                        disabled={!isPermitted}
                      />
                    );

                  default:
                }
                return null;
              })
            }
            </Stack>
          </Form>)
        }
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type='submit' form='createForm' color='secondary' variant='contained'>
          {t('Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModal;
