import React, { useState, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import feathers from 'services/feathers';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { toMongoFilter, toMongoSort } from 'utils/query';
import dayjs from 'dayjs';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';

function WhatsAppSessions() {
  const { t } = useTranslation();
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'createdAt', desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const filters = toMongoFilter(columnFilters);
      const sort = toMongoSort(sorting);
      const query = {
        ...(
          !!globalFilter && {
            '$text': {
              '$search': `${globalFilter}`
            }
          }
        ),
        ...(
          !isEmpty(filters) && {
            ...filters
          }
        ),
        $skip: pagination.pageIndex * pagination.pageSize,
        $limit: pagination.pageSize,
        $sort: sort
      };

      try {
        const response = await feathers.service('whatsapp-sessions').find({ query });
        return response;
      } catch (err) {
        setGlobalErrorMessage(err);
        return null;
      }
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => {
      const commonColumns = [
        {
          accessorKey: '_id',
          header: t('Id'),
          enableSorting: false,
        },
        {
          accessorKey: 'name',
          header: t('Name'),
          enableSorting: false,
        },
        {
          accessorKey: 'session',
          header: t('Session'),
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorKey: 'apiKeyId.name',
          header: t('API Key Name'),
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorKey: 'createdAt',
          header: t('Created At'),
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
        },
        {
          accessorKey: 'updatedAt',
          header: t('Updated At'),
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
        },
      ];

      return commonColumns;
    }, [t]
  );

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: t('Actions'),
          },
        }}
        data={data?.data ?? []}
        initialState={{
          columnVisibility: {
            _id: false,
            updatedAt: false,
          },
          showColumnFilters: false
        }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('Error loading data'),
            }
          : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Stack direction='row' spacing={1}>
            <Tooltip arrow title={t('Refresh Data')}>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        rowCount={data?.total ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const WhatsAppSessionsWithReactQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <WhatsAppSessions />
  </QueryClientProvider>
);

export default WhatsAppSessionsWithReactQueryProvider;
