import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { get, groupBy, forOwn, find } from 'lodash';
import BankAccordion from './bankAccordion';
import OthersAccordion from './othersAccordion';
import PendingAccordion from './pendingAccordion';
import {
  isHardcodedAccordionValue,
  getHardcodedAccordionHeader
} from './helper';
import { useTranslation } from 'react-i18next';

export default function CompanyBankSelectorGroupByName (props) {
  const { t } = useTranslation();
  const {
    companyBanks,
    disabled,
    onChange,
    showOthers = false,
    value = null,
  } = props;
  const [ groupedBanks, setGroupedBanks ] = useState([]);
  const [ expanded, setExpanded ] = useState(false);

  useEffect(() => {
    if (!disabled) return;
    setExpanded(false);
  }, [disabled]);

  useEffect(() => {
    if (!value) return;

    if (isHardcodedAccordionValue(value)) {
      setExpanded(getHardcodedAccordionHeader(value));
    } else {
      const findBank = find(companyBanks, { _id: value });
      if (!findBank) return;
      const name = get(findBank, 'accountHolderName', '');
      const expandedName = `ba-${name}`;
      setExpanded(expandedName);
    }
  }, [value, companyBanks]);

  useEffect(() => {
    if (companyBanks.length <= 0) return;
    const grouped = groupBy(companyBanks, 'accountHolderName');
    setGroupedBanks(grouped);
  }, [companyBanks]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRadioChange = (event) => {
    const companyBankId = event.target.value;
    onChange(companyBankId);
  };

  const renderAccordionByName = () => {
    let accordion = [];

    forOwn(groupedBanks, (banks, name) => {
      accordion.push(
        <BankAccordion
          disabled={disabled}
          expanded={ expanded === `ba-${name}`}
          onChange={ handleAccordionChange(`ba-${name}`) }
          key={`ba-${name}`}
          title={name}
          banks={banks}
          radioValue={value}
          radioOnChange={handleRadioChange}
        />
      )
    });
    return accordion;
  };

  return (
    <>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>{t('Company Bank')}</FormLabel>
      </FormControl>
      {
        renderAccordionByName()
      }
      {
        showOthers && (
          <>
            <PendingAccordion
              disabled={disabled}
              expanded={ expanded === `ba-pending`}
              onChange={ handleAccordionChange(`ba-pending`) }
              radioValue={value}
              radioOnChange={handleRadioChange}
            />
            <OthersAccordion
              disabled={disabled}
              expanded={ expanded === `ba-others`}
              onChange={ handleAccordionChange(`ba-others`) }
              radioValue={value}
              radioOnChange={handleRadioChange}
            />
          </>
        )
      }
    </>
  );
};

CompanyBankSelectorGroupByName.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  companyBanks: PropTypes.array.isRequired,
};
