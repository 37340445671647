const Decimal = require('decimal.js');

// Amount is a number
// Bonus is an object
const calculatePointByBonus = (amount, bonus) => {
  if (!bonus) return 0;
  const { mode: bonusMode = '', amount: bonusAmount = 0, minDeposit = 0, maxBonus = 0, depositFree = false } = bonus;
  const decAmount = new Decimal(amount);
  const decBonusAmount = new Decimal(bonusAmount);
  const decMinDeposit = new Decimal(minDeposit);
  const decMaxBonus = new Decimal(maxBonus);
  const hasMaxBonusLimit = decMaxBonus.gt(0);

  if (depositFree) {
    switch (bonusMode) {
      case 'fixed':
        // Free fixed bonus
        return decBonusAmount.toNumber();

      // case 'percentage':
      //   const decBonus = decAmount.times(decBonusAmount).dividedBy(100);
      //   if (hasMaxBonusLimit && decBonus.gt(decMaxBonus)) {
      //     return decMaxBonus.toNumber();
      //   }

      //   return decBonus.toNumber();
      //   break;

      default:
        return 0;
    }
  } else {
    // If minDeposit is set, and amount is less than minDeposit, return amount
    if (decMinDeposit.gt(0) && decAmount.lt(decMinDeposit)) return decAmount.toNumber();

    switch (bonusMode) {
      case 'fixed':
        const decFixedBonus = decAmount.plus(decBonusAmount);
        return decFixedBonus.toNumber();

      case 'percentage':
        let decPercentageBonus = decAmount.times(decBonusAmount).dividedBy(100);

        if (hasMaxBonusLimit && decPercentageBonus.gt(decMaxBonus)) {
          decPercentageBonus = decMaxBonus;
        }
        return decAmount.plus(decPercentageBonus).toNumber();

      case 'multiplier':
        const decMultiplierBonus = decAmount.times(decBonusAmount);
        return decMultiplierBonus.toNumber();

      default:
        return 0;
    }
  }
};

const getDefaultDatetime = () => {
  const now = new Date();
  const Y = now.getFullYear();
  const M = now.getMonth() + 1;
  const D = now.getDate();
  const H = now.getHours();
  const m = now.getMinutes();
  return `${Y}-${zeroAppender(M)}-${zeroAppender(D)}T${zeroAppender(H)}:${zeroAppender(m)}`;
};

const zeroAppender = (i) => {
  if (i < 10) i = '0' + i;
  return i;
};

exports.calculatePointByBonus = calculatePointByBonus;
exports.getDefaultDatetime = getDefaultDatetime;
