import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import feathers from 'services/feathers';
import { Formik } from 'formik';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import { get, set } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import JwtRevoke from 'features/jwtRevoke/JwtRevoke';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useAuth } from 'hooks/useAuth';

export default function Settings() {
  const { t } = useTranslation();
  const { user, update: updateUser } = useAuth();
  const { settings, lang, _id: userId, role, name } = user;

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const {
    bonuses,
    bonusesReady,
    bonusesError
  } = useContext(CommonContext);

  const defaultBonus = get(settings, 'defaultBonus', '');
  const bankSelectorType = get(settings, 'bankSelectorType', 'basic');
  const showKioskCredential = get(settings, 'showKioskCredential', false);
  const promptOnRouteTransition = get(settings, 'promptOnRouteTransition', true);
  const hideWithdrawal = get(settings, 'hideWithdrawal', false);
  const relocatePendingDp = get(settings, 'relocatePendingDp', false);
  const relocateAdjustment = get(settings, 'relocateAdjustment', false);
  const refIdPrefix = get(settings, 'refIdPrefix', '');
  const refIdPostfixOffset = get(settings, 'refIdPostfixOffset', 0);
  const hideGameLogOperation = get(settings, 'hideGameLogOperation', false);

  useEffect(() => {
    if (!bonusesError) return;
    if (bonusesError) {
      setGlobalErrorMessage(bonusesError);
      return;
    }
  }, [bonusesError, setGlobalErrorMessage]);

  function generatePatchData (formData) {
    let data = {};

    set(data, 'name', formData.name);
    if (formData.password !== '') set(data, 'password', formData.password);
    set(data, 'lang', formData.lang);

    let sd = {};
    if (formData.defaultBonus !== '') sd['settings.defaultBonus'] = formData.defaultBonus;
    sd['settings.bankSelectorType'] = formData.bankSelectorType;
    sd['settings.showKioskCredential'] = formData.showKioskCredential;
    sd['settings.promptOnRouteTransition'] = formData.promptOnRouteTransition;
    sd['settings.hideWithdrawal'] = formData.hideWithdrawal;
    sd['settings.relocatePendingDp'] = formData.relocatePendingDp;
    sd['settings.relocateAdjustment'] = formData.relocateAdjustment;
    sd['settings.refIdPrefix'] = formData.refIdPrefix;
    sd['settings.refIdPostfixOffset'] = formData.refIdPostfixOffset;
    sd['settings.hideGameLogOperation'] = formData.hideGameLogOperation;
    set(data, '$set', sd);

    return data;
  };

  if (!bonusesReady) return <Loader open={true} />;

  return (
    <Box>
      <Formik
        initialValues={{
          name,
          password: '',
          defaultBonus,
          bankSelectorType,
          showKioskCredential,
          promptOnRouteTransition,
          hideWithdrawal,
          relocatePendingDp,
          relocateAdjustment,
          refIdPrefix,
          refIdPostfixOffset,
          hideGameLogOperation,
          lang
        }}
        validate={
          values => {
            const errors = {};
            if (!values.name) errors.name = t('Required');
            return errors;
          }
        }
        onSubmit={
          async (values, { setSubmitting }) => {
            try {
              const data = generatePatchData(values);
              const savedUser = await feathers.service('users').patch(userId, data);
              updateUser(savedUser);
              setSubmitting(false);
              setGlobalMessage(t('Saved'), 'success');
            } catch (err) {
              setSubmitting(false);
              setGlobalErrorMessage(err);
            };
          }
        }
      >
      {
        ({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    id='name'
                    required
                    autoFocus
                    label={t('Name')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name ? true : false}
                    helperText={errors.name && touched.name ? errors.name : ''}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    id='password'
                    label={t('New Password')}
                    type='password'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={errors.password ? true : false}
                    helperText={errors.password && touched.password ? errors.password : ''}
                  />
                </Grid>
              </Grid>
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      id='defaultBonus'
                      select
                      fullWidth
                      label={t('Default Bonus')}
                      onChange={event => {
                        setFieldValue('defaultBonus', event.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.defaultBonus}
                      error={errors.defaultBonus ? true : false}
                      helperText={errors.defaultBonus && touched.defaultBonus ? errors.defaultBonus : ''}
                    >
                      <MenuItem value={''}>No Default</MenuItem>
                      {
                        bonuses.map((bonus) => (
                          <MenuItem key={bonus._id} value={bonus._id}>
                            {bonus.name}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      id='bankSelectorType'
                      select
                      fullWidth
                      label={t('Company Bank Selector Type')}
                      onChange={event => {
                        setFieldValue('bankSelectorType', event.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.bankSelectorType}
                      error={errors.bankSelectorType ? true : false}
                      helperText={errors.bankSelectorType && touched.bankSelectorType ? errors.bankSelectorType : ''}
                    >
                      <MenuItem value={'basic'}>{t('Basic')}</MenuItem>
                      <MenuItem value={'groupByBank'}>{t('Group By Bank Name')}</MenuItem>
                      <MenuItem value={'groupByName'}>{t('Group By Account Holder Name')}</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              }
              {
               role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Switch
                            id='showKioskCredential'
                            checked={values.showKioskCredential}
                            onChange={event => {
                              setFieldValue('showKioskCredential', event.target.checked);
                            }}
                            name="showKioskCredential"
                            inputProps={{ 'aria-label': 'showKioskCredential' }}
                          />
                        }
                        label={t("Show Kiosk Credential")}
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Switch
                            id='promptOnRouteTransition'
                            checked={values.promptOnRouteTransition}
                            onChange={event => {
                              setFieldValue('promptOnRouteTransition', event.target.checked);
                            }}
                            name="promptOnRouteTransition"
                            inputProps={{ 'aria-label': 'promptOnRouteTransition' }}
                          />
                        }
                        label={t("Prompt on Route Transition")}
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Switch
                            id='hideWithdrawal'
                            checked={values.hideWithdrawal}
                            onChange={event => {
                              setFieldValue('hideWithdrawal', event.target.checked);
                            }}
                            name="hideWithdrawal"
                            inputProps={{ 'aria-label': 'hideWithdrawal' }}
                          />
                        }
                        label={t("Hide Withdrawal")}
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Switch
                            id='relocatePendingDp'
                            checked={values.relocatePendingDp}
                            onChange={event => {
                              setFieldValue('relocatePendingDp', event.target.checked);
                            }}
                            name="relocatePendingDp"
                            inputProps={{ 'aria-label': 'relocatePendingDp' }}
                          />
                        }
                        label={t("Relocate Pending DP")}
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Switch
                            id='relocateAdjustment'
                            checked={values.relocateAdjustment}
                            onChange={event => {
                              setFieldValue('relocateAdjustment', event.target.checked);
                            }}
                            name="relocateAdjustment"
                            inputProps={{ 'aria-label': 'relocateAdjustment' }}
                          />
                        }
                        label={t("Relocate Adjustment")}
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        control={
                          <Switch
                            id='hideGameLogOperation'
                            checked={values.hideGameLogOperation}
                            onChange={event => {
                              setFieldValue('hideGameLogOperation', event.target.checked);
                            }}
                            name="hideGameLogOperation"
                            inputProps={{ 'aria-label': 'hideGameLogOperation' }}
                          />
                        }
                        label={t("Hide Game Log Operation")}
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      id='refIdPrefix'
                      label={t('Reference Id Prefix')}
                      type='text'
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.refIdPrefix}
                      error={errors.refIdPrefix ? true : false}
                      helperText={errors.refIdPrefix && touched.refIdPrefix ? errors.refIdPrefix : ''}
                    />
                  </Grid>
                </Grid>
              }
              {
                role === 'user' && <Grid item xs={12}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      id='refIdPostfixOffset'
                      label={t('Reference Id Postfix Offset')}
                      type='number'
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.refIdPostfixOffset || ''}
                      error={errors.refIdPostfixOffset ? true : false}
                      helperText={errors.refIdPostfixOffset && touched.refIdPostfixOffset ? errors.refIdPostfixOffset : ''}
                    />
                  </Grid>
                </Grid>
              }
              <Grid item xs={12}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    id='lang'
                    select
                    fullWidth
                    label={t('Language')}
                    onChange={event => {
                      setFieldValue('lang', event.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.lang}
                    error={errors.lang ? true : false}
                    helperText={errors.lang && touched.lang ? errors.lang : ''}
                  >
                    <MenuItem value={'en'}>{t('English')}</MenuItem>
                    <MenuItem value={'zh'}>{t('中文')}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button size='large' variant='contained' type='submit' disabled={isSubmitting} onClick={handleSubmit} color='primary'>
                  {t('Save')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }
      </Formik>
      <Box sx={{ my: 2 }}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Paper elevation={8}>
              <JwtRevoke />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
