import React, { useContext, useState, useMemo, useCallback } from 'react';
import MaterialReactTable from 'material-react-table';
import feathers from 'services/feathers';
import { get, isEmpty } from 'lodash';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from 'hooks/useAuth';
import Decimal from 'decimal.js';
import CreateModal from 'features/crudModal/Create.js';
import EditModal from 'features/crudModal/Edit.js';
import * as Yup from 'yup';
import { toMongoFilter, toMongoSort } from 'utils/query';
import dayjs from 'dayjs';

function Bonuses() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const ability = useContext(AbilityContext);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Required")),
    mode: Yup.string().matches(/(percentage|fixed|multiplier)/),
    amount: Yup.number().min(0).max(200).required(t("Required")),
    minDeposit: Yup.number().integer().min(0).required(),
    maxBonus: Yup.number().integer().min(0).required(),
    depositFree: Yup.bool().required(t("Required")),
    dateStart: Yup.date().nullable(),
    dateEnd: Yup.date().nullable(),
    daysAllowed: Yup.array().of(Yup.number().integer()),
    isEnabled: Yup.bool().required(t("Required")),
  });

  const [ createModalOpen, setCreateModalOpen ] = useState(false);
  const [ editModalOpen, setEditModalOpen ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const filters = toMongoFilter(columnFilters);

      const sort = toMongoSort(sorting);
      const query = {
        ...(
          !!globalFilter && {
            '$text': {
              '$search': `${globalFilter}`
            }
          }
        ),
        ...(
          !isEmpty(filters) && {
            ...filters
          }
        ),
        $skip: pagination.pageIndex * pagination.pageSize,
        $limit: pagination.pageSize,
        $populate: [{ path: 'owner', select: ['_id', 'username'] }],
        $sort: sort
      };

      try {
        const response = await feathers.service('bonuses').find({ query });
        return response;
      } catch (err) {
        setGlobalErrorMessage(err);
        return null;
      }
    },
    keepPreviousData: true,
  });

  const booleanOptions = useMemo(
    () => {
      return [
        { text: t('true'), value: 'true' },
        { text: t('false'), value: 'false' },
      ];
    }, [t]
  );

  const modeOptions = useMemo(
    () => {
      return [
        { text: t('percentage'), value: 'percentage' },
        { text: t('fixed'), value: 'fixed' },
        { text: t('multiplier'), value: 'multiplier' },
      ];
    }, [t]
  );

  const daysOptions = useMemo(
    () => {
      return [1, 2, 3, 4, 5, 6, 7].map((d) => {
        return {
          text: t(`DAY${d}`),
          value: d
        }
      });
    }, [t]
  );

  const columns = useMemo(
    () => {
      const ownerColumn = (userRole === 'user') ? null : {
        accessorFn: (row) => {
          return get(row, 'owner.username');
        },
        id: 'owner.username',
        header: t('Owner'),
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          enableCreating: false
        }
      };

      const commonColumns = [
        {
          accessorKey: '_id',
          header: t('Id'),
          enableSorting: false,
          enableEditing: false,
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'name',
          header: t('Name'),
          enableSorting: false,
        },
        {
          accessorKey: 'mode',
          header: t('Mode'),
          editSelectOptions: modeOptions,
          filterSelectOptions: modeOptions,
          filterVariant: 'select',
          enableColumnFilter: true,
          enableSorting: true,
          Cell: ({ renderedCellValue, cell }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            initialValue: 'percentage',
            formikEditVariant: 'select',
          }
        },
        {
          accessorKey: 'amount',
          header: t('Amount'),
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ renderedCellValue, row, cell }) => {
            const strVal = cell.getValue() ?? '0';
            const decVal = new Decimal(strVal);
            return <Box component='span'>{decVal.toFixed(2)}</Box>
          },
          meta: {
            inputMode: 'numeric',
            initialValue: 0
          }
        },
        {
          accessorKey: 'minDeposit',
          header: t('Min Deposit'),
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ renderedCellValue, row, cell }) => {
            const strVal = cell.getValue() ?? '0';
            const decVal = new Decimal(strVal);
            return <Box component='span'>{decVal.toFixed(2)}</Box>
          },
          meta: {
            inputMode: 'numeric',
            initialValue: 0
          }
        },
        {
          accessorKey: 'maxBonus',
          header: t('Max Bonus'),
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ renderedCellValue, row, cell }) => {
            const strVal = cell.getValue() ?? '0';
            const decVal = new Decimal(strVal);
            return <Box component='span'>{decVal.toFixed(2)}</Box>
          },
          meta: {
            inputMode: 'numeric',
            initialValue: 0
          }
        },
        {
          accessorKey: 'depositFree',
          header: t('Deposit Free'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            formikEditVariant: 'checkbox',
            initialValue: false,
          }
        },
        {
          accessorKey: 'dateStart',
          header: t('Start Date'),
          enableColumnFilter: false,
          enableSorting: true,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
          meta: {
            formikEditVariant: 'date-time',
            replaceEmpty: true,
          }
        },
        {
          accessorKey: 'dateEnd',
          header: t('End Date'),
          enableColumnFilter: false,
          enableSorting: true,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
          meta: {
            formikEditVariant: 'date-time',
            replaceEmpty: true,
          }
        },
        {
          accessorKey: 'daysAllowed',
          header: t('Days Allowed'),
          editSelectOptions: daysOptions,
          filterSelectOptions: daysOptions,
          filterVariant: 'multi-select',
          enableColumnFilter: true,
          enableSorting: false,
          Cell: ({ renderedCellValue, cell }) => {
            if (!renderedCellValue?.length) return null;
            return renderedCellValue.sort().map((d) => t(`DAY${d}`)).join(', ');
          },
          meta: {
            initialValue: [],
            formikEditVariant: 'multi-select',
            replaceEmpty: true,
          }
        },
        {
          accessorKey: 'isEnabled',
          header: t('Enabled'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            formikEditVariant: 'checkbox',
            initialValue: true
          }
        },
      ];

      return ownerColumn ? [ownerColumn, ...commonColumns] : commonColumns;
    }, [t, userRole, booleanOptions, daysOptions, modeOptions]
  );

  const handleCreateNewRow = useCallback(
    async (values) => {
      try {
        await feathers.service('bonuses').create(values);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleUpdateRow = useCallback(
    async (values) => {
      const { _id, ...data } = values;
      try {
        await feathers.service('bonuses').patch(_id, data);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(t('Delete confirmation', { text: row.getValue('name') }))
      ) {
        return;
      }

      const kioskId = row.getValue('_id');
      try {
        await feathers.service('bonuses').remove(kioskId);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [t, refetch, setGlobalErrorMessage]
  );

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: t('Actions'),
          },
        }}
        data={data?.data ?? []}
        initialState={{
          columnVisibility: {
            _id: false
          },
          showColumnFilters: false
        }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('Error loading data'),
            }
          : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Stack direction='row' spacing={1}>
            <Tooltip arrow title={t('New Item')}>
              <IconButton onClick={() => setCreateModalOpen(true)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={t('Refresh Data')}>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        renderRowActions={({ row, table }) => {
          const canEdit = ability.can('update', subject('bonuses', row?.original));
          const canDelete = ability.can('delete', subject('bonuses', row?.original));

          return (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {
                canEdit && (
                 <Tooltip arrow placement="left" title={t('Edit')}>
                    <IconButton onClick={() => {
                      setEditData(row?.original);
                      setEditModalOpen(true);
                    }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              {
                canDelete && (
                 <Tooltip arrow placement="right" title={t('Delete')}>
                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Box>
          );
        }}
        onEditingRowSave={handleUpdateRow}
        rowCount={data?.total ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <CreateModal
        modelName='bonuses'
        validationSchema={validationSchema}
        open={createModalOpen}
        columns={columns}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <EditModal
        modelName='bonuses'
        validationSchema={validationSchema}
        editData={editData}
        open={editModalOpen}
        columns={columns}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleUpdateRow}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const BonusesWithReactQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <Bonuses />
  </QueryClientProvider>
);

export default BonusesWithReactQueryProvider;
