import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

export default function FormikText(props) {
  const [ field, meta, helpers ] = useField(props);
  const { setValue } = helpers;
  const { value } = field;

  return (
    <DateTimePicker
      { ...field }
      { ...props }
      views={['day', 'hours', 'minutes', 'seconds']}
      inputFormat="YYYY-MM-DD HH:mm:ss"
      renderInput={(props) => <TextField fullWidth {...props} />}
      onChange={(date) => {
        if (!dayjs(date).isValid()) setValue('');
        else setValue(dayjs(date).format());
      }}
      value={value === undefined ? '' : value}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}
