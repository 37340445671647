import {
  setProductCount,
  setSelectedSrcProductId,
  setSelectedSrcGameId,
  setSelectedDestProductIdByIndex,
  setSelectedDestGameIdByIndex,
  setAmount,
  setPoint,
  setVoidAmount,
  setSplitPointByIndex,
  setProductIndex,
  setTransactionDt,
  setRemark,
  setStatus,
  setVoidTransactions,
  setExpanded,
  increaseSuccessDpCount,
  increaseSuccessWdCount,
  resetSplit,
  reset,
} from './slice';
import { toNumber } from 'lodash';
import {
  getProductIndex,
  getVoidAmount,
  getPoint,
  getProductCount,
} from './selectors';

export const updateProductCount = count => (dispatch, getState) => {
  let checkedCount = count;
  if (checkedCount < 1) checkedCount = 1;
  else if (checkedCount > 10) checkedCount = 10;
  dispatch(setProductCount(checkedCount));
  dispatch(resetSplit());

  // set product index to last
  const index = getProductIndex(getState());
  if (index > checkedCount - 1) dispatch(setProductIndex(checkedCount - 1));

  dispatch(calculateFinalAmount());
};

export const updateDefaultGameId = (gameId, productId) => dispatch => {
  dispatch(setSelectedSrcGameId(gameId));
  dispatch(setSelectedSrcProductId(productId));
  dispatch(setExpanded('srcPanel'));
};

export const updateSelectedSrcProductId = id => dispatch => {
  dispatch(setSelectedSrcProductId(id));
  dispatch(setSelectedSrcGameId(null));
};

export const updateSelectedSrcGameId = id => dispatch => {
  dispatch(setSelectedSrcGameId(id));
};

export const updateCurrentDestProductId = id => (dispatch, getState) => {
  const index = getProductIndex(getState());
  dispatch(setSelectedDestProductIdByIndex({ index, id }));
  dispatch(setSelectedDestGameIdByIndex({ index, id: null }));
};

export const updateCurrentDestGameId = id => (dispatch, getState) => {
  const index = getProductIndex(getState());
  dispatch(setSelectedDestGameIdByIndex({ index, id }));
};

export const updatePoint = (pointStr) => dispatch => {
  const point = toNumber(pointStr);
  dispatch(setPoint(point));
  dispatch(calculateFinalAmount());
};

export const updateVoidAmount = (voidAmountStr) => (dispatch, getState) => {
  const voidAmount = toNumber(voidAmountStr);
  dispatch(setVoidAmount(voidAmount));
  dispatch(calculateFinalAmount());
};

const calculateFinalAmount = () => (dispatch, getState) => {
  const voidAmount = getVoidAmount(getState());
  const point = getPoint(getState());
  const finalAmount = point - voidAmount;
  dispatch(setAmount(finalAmount));

  const count = getProductCount(getState());
  if (count > 1) return;
  dispatch(setSplitPointByIndex({ index: 0, point: finalAmount }));
};

export const updateCurrentSplitPoint = pointStr => (dispatch, getState) => {
  const index = getProductIndex(getState());
  const point = toNumber(pointStr);
  dispatch(setSplitPointByIndex({ index, point }));
};

export const updateProductIndex = (index) => dispatch => {
  dispatch(setProductIndex(index));
};

export const updateTransactionDt = (dt) => dispatch => {
  dispatch(setTransactionDt(dt));
};

export const updateRemark = (remark) => dispatch => {
  dispatch(setRemark(remark));
};

export const updateStatus = status => dispatch => {
  dispatch(setStatus(status));
};

export const updateVoidTransactions = list => dispatch => {
  dispatch(setVoidTransactions(list));
};

export const updateExpanded = expanded => dispatch => {
  dispatch(setExpanded(expanded));
};

export const updateSuccessWdCount = () => dispatch => {
  dispatch(increaseSuccessWdCount());
};

export const updateSuccessDpCount = () => dispatch => {
  dispatch(increaseSuccessDpCount());
};

export const resetUi = () => dispatch => {
  dispatch(reset());
};
