import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { Formik } from 'formik';
import feathers from 'services/feathers';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { get } from 'lodash';
import lookup from 'features/lookup/Bank';
import { useTranslation } from 'react-i18next';

export default function EditDialog (props) {
  const { t } = useTranslation();
  const {
    customerBank,
    open,
    setOpen,
  } = props;

  const _id = get(customerBank, '_id');
  const bankName = get(customerBank, 'bankName', '');
  const accountHolderName = get(customerBank, 'accountHolderName', '');
  const accountNumber = get(customerBank, 'accountNumber', '');
  const remark = get(customerBank, 'remark', '');
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{t('Edit Customer Bank')}</DialogTitle>
        <Formik
          initialValues={{
            bankName,
            accountHolderName,
            accountNumber,
            remark
          }}
          validate={
            values => {
              const errors = {};
              if (!values.bankName) errors.bankName = 'Required';
              else if (!values.accountHolderName) errors.accountHolderName = 'Required';
              else if (!values.accountNumber) errors.accountNumber = 'Required';
              return errors;
            }
          }
          onSubmit={
            async (values, { setSubmitting }) => {
              try {
                setErrorMessage('');
                await feathers.service('customer-banks').patch(_id, values);
                setSubmitting(false);
                setOpen(false);
              } catch (err) {
                setSubmitting(false);
                setErrorMessage(err.message);
              };
            }
          }
        >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form autoComplete='off' onSubmit={handleSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField
                    id='bankName'
                    required
                    select
                    fullWidth
                    label={t('Bank Name')}
                    onChange={event => {
                      setFieldValue('bankName', event.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.bankName}
                    error={errors.bankName ? true : false}
                    helperText={errors.bankName && touched.bankName ? errors.bankName : ''}
                  >
                    {
                      Object.entries(lookup).map((bankEntry) => (
                        <MenuItem key={bankEntry[0]} value={bankEntry[0]}>
                          {bankEntry[1]}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                  <TextField
                    id='accountHolderName'
                    required
                    label={t('Account Holder Name')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.accountHolderName}
                    error={errors.accountHolderName ? true : false}
                    helperText={errors.accountHolderName && touched.accountHolderName ? errors.accountHolderName : ''}
                  />
                  <TextField
                    id='accountNumber'
                    required
                    label={t('Account Number')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.accountNumber}
                    error={errors.accountNumber ? true : false}
                    helperText={errors.accountNumber && touched.accountNumber ? errors.accountNumber : ''}
                  />
                  <TextField
                    id='remark'
                    autoFocus
                    label={t('Remark')}
                    type='text'
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.remark}
                    error={errors.remark ? true : false}
                    helperText={errors.remark && touched.remark ? errors.remark : ''}
                  />
                  {
                    errorMessage && <Alert severity='error'>{errorMessage}</Alert>
                  }
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button disabled={isSubmitting} onClick={handleClose} color='primary'>
                  {t('Cancel')}
                </Button>
                <Button type='submit' disabled={isSubmitting} onClick={handleSubmit} color='primary'>
                  {t('Save')}
                </Button>
              </DialogActions>
            </form>
          )
        }
        </Formik>
    </Dialog>
  );
}

EditDialog.propTypes = {
  customerBank: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
