import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

export default function ConfirmNavigation({blocker}) {
  const { t } = useTranslation();
  const location = useLocation();

  const pathname = useMemo(
    () => {
      return location?.pathname ?? '';
    }, [location]
  );

  useEffect(
    () => {
      if (blocker.state === 'blocked') {
        if (window.confirm(t('Discard and proceed', { pathname }))) {
          blocker.proceed?.();
          return;
        } else {
          blocker.reset?.();
        }
      }
    }, [blocker, pathname, t]
  );

  return null;
}
