import React, { useState, useContext, createContext, useEffect, useMemo } from 'react';
import feathers from 'services/feathers';
import { get } from 'lodash';
import i18n from 'i18n';
import { useAuth } from 'hooks/useAuth';

const userConfigContext = createContext();

export function ProvideUserConfig({ children }) {
  const userConfig = useProvideUserConfig();
  return <userConfigContext.Provider value={userConfig}>{children}</userConfigContext.Provider>;
}

export const useUserConfig = () => {
  return useContext(userConfigContext);
};

function useProvideUserConfig() {
  const [ lang, setLang ] = useState('en');
  const [ darkMode, setDarkMode ] = useState(false);
  const { user } = useAuth();

  const userId = useMemo(
    () => {
      return get(user, '_id');
    }, [user]
  );

  const userDarkMode = useMemo(
    () => {
      // to perserve even after logout
      const value = get(user, 'settings.darkMode', null);
      return (value !== null) ? value : darkMode;
    }, [user, darkMode]
  );

  const userLang = useMemo(
    () => {
      const value = get(user, 'lang', null);
      return (value !== null) ? value : lang;
    }, [user, lang]
  );

  useEffect(() => {
    setDarkMode(userDarkMode);
  }, [userDarkMode]);

  useEffect(() => {
    setLang(userLang);
  }, [userLang]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const toggleDarkMode = async () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);

    if (!userId) return;
    try {
      await feathers.service('users').patch(userId, { 'settings.darkMode': newDarkMode });
    } catch (err) {
      throw err;
    }
  };

  return {
    lang,
    setLang,
    darkMode,
    setDarkMode,
    toggleDarkMode,
  };
}
