import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { get, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

function BasicRadioGroup (props) {
  const { value, onChange, label, items, disabled } = props;
  return (
    <RadioGroup aria-label={label} name={label} value={value} onChange={onChange}>
      {
        items.map(item => {
          return (
            <FormControlLabel
              key={item.value}
              disabled={disabled}
              value={item.value}
              control={<Radio />}
              label={item.label}
            />
          )
        })
      }
    </RadioGroup>
  );
}

export default function CompanyBankSelector (props) {
  const { t } = useTranslation();
  const {
    companyBanks,
    disabled,
    onChange,
    showOthers = false,
    updatedAt = null,
    value = null,
  } = props;
  const theme = useTheme();

  useEffect(() => {
    if (updatedAt === null) return;
    onChange(null);
  }, [updatedAt, onChange]);

  const handleChange = (event) => {
    const companyBankId = event.target.value;
    onChange(companyBankId);
  };

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{t('Company Bank')}</FormLabel>
      <RadioGroup aria-label='companyBank' name='companyBank' value={value} onChange={handleChange}>
        {
          sortBy(companyBanks, ['name']).map((companyBank) => {
            if (!companyBank.isEnabled) return null;
            const balance = get(companyBank, 'balance.$numberDecimal', '0');
            const bgcolor = disabled ? theme.palette.text.disabled : get(companyBank, 'colorTag', '#fff');
            const color = disabled ? theme.palette.action.disabledBackground : theme.palette.getContrastText(bgcolor);
            return (
              <FormControlLabel
                key={companyBank._id}
                disabled={disabled}
                value={companyBank._id}
                control={<Radio />}
                label={
                  <div>
                    <Typography sx={{ bgcolor, color, p: 1, borderRadius: 1 }} variant='button'>
                      { companyBank.name }
                    </Typography>
                    <Typography variant='button'>
                      { ` (${parseFloat(balance).toFixed(2)})` }
                    </Typography>
                  </div>
                }
              />
            )
          })
        }
      </RadioGroup>
      {
        showOthers && (
          <>
            <FormLabel component='legend'>{t('Pending')}</FormLabel>
            <BasicRadioGroup
              value={value}
              onChange={handleChange}
              label='pending'
              disabled={disabled}
              items={
                [
                  { value: 'pending-deposit', label: t('Deposit') },
                  { value: 'pending-withdrawal', label: t('Withdrawal') },
                ]
              }
            />
            <FormLabel component='legend'>{t('Others')}</FormLabel>
            <BasicRadioGroup
              value={value}
              onChange={handleChange}
              label='others'
              disabled={disabled}
              items={
                [
                  { value: 'capital-adjustment', label: t('Capital Adjustment') },
                  { value: 'deposit-free', label: t('Deposit Free') },
                  { value: 'transfer', label: t('Transfer') },
                ]
              }
            />
          </>
        )
      }
    </FormControl>
  );
}

CompanyBankSelector.propTypes = {
  companyBanks: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
