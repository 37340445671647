import React from 'react';
import { useAuth } from 'hooks/useAuth';
import { useUserConfig } from 'hooks/useUserConfig';
import { Navigate, useLocation } from "react-router-dom";

export default function RequireAuth({ children }) {
  const auth = useAuth();
  const { lang, darkMode } = useUserConfig();
  const location = useLocation();

  if (auth.user) return children;

  return <Navigate to={`/login?lang=${lang}&darkMode=${darkMode}`} state={{ from: location }} replace />
}
