import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  selectedBankId: null,
  amount: 0,
  transactionDt: new Date(),
  status: 'idle',
  remark: ''
};

export const slice = createSlice({
  name: 'pendingDeposit',
  initialState,
  reducers: {
    setSelectedBankId: (state, action) => {
      state.selectedBankId = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setTransactionDt: (state, action) => {
      state.transactionDt = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setRemark: (state, action) => {
      state.remark = action.payload;
    },
    reset: (state, action) => {
      const defaultState = { ...initialState };
      defaultState.transactionDt = new Date();
      return defaultState;
    }
  }
});

export const {
  setSelectedBankId,
  setAmount,
  setTransactionDt,
  setStatus,
  setRemark,
  reset,
} = slice.actions;

export default slice.reducer;
