import { SketchPicker } from 'react-color';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField } from 'formik';

export default function FormikColorPicker(props) {
  const [ field, , helpers ] = useField(props);
  const { value } = field;
  const { setValue } = helpers;
  const { label, disabled } = props;

  return (
    <FormControlLabel
      label={label}
      labelPlacement='top'
      disabled={disabled}
      control={
        <SketchPicker
          onChangeComplete={(color) => {
            if (disabled) return;
            const { hex } = color;
            setValue(hex);
          }}
          color={value}
        />
      }
    />
  );
}
