import {
  setImage,
  reset,
} from './slice';

export const updateImage = image => (dispatch) => {
  if (!image) return;
  dispatch(setImage(image));
};

export const resetImage = () => dispatch => {
  dispatch(reset());
};
