import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
//import { feathersServices as fs } from 'services/feathers';
import authSlice from 'features/auth/authSlice';
import userSlice from 'features/user/userSlice';
import captchaSlice from 'features/captchaBridgeDialog/store/slice';
import { combineReducers } from 'redux';
import promise from 'redux-promise-middleware';
import { get } from 'lodash';

// txn slices
import depositSlice from 'features/order/deposit/store/slice';
import withdrawalSlice from 'features/order/withdrawal/store/slice';
import pendingDepositSlice from 'features/order/pendingDeposit/store/slice';
import pendingWithdrawalSlice from 'features/order/pendingWithdrawal/store/slice';
import adjustmentSlice from 'features/order/adjustment/store/slice';
import transferSlice from 'features/order/transfer/store/slice';
import profileSelectorSlice from 'features/profileSelector/store/slice';

const NODE_ENV = process.env.NODE_ENV || 'development';

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') state = undefined;

  return combineReducers({
    auth: authSlice,
    user: userSlice,
    captcha: captchaSlice,

    //users: fs.users.reducer,
    //actionLogs: fs.actionLogs.reducer,
    deposit: depositSlice,
    withdrawal: withdrawalSlice,
    transfer: transferSlice,
    pendingDeposit: pendingDepositSlice,
    pendingWithdrawal: pendingWithdrawalSlice,
    adjustment: adjustmentSlice,

    // profileSelector
    dpProfileSelector: createFilteredReducer(profileSelectorSlice, action => get(action, 'payload.name') === 'dp'),
    wdProfileSelector: createFilteredReducer(profileSelectorSlice, action => get(action, 'payload.name') === 'wd'),
    tfProfileSelector: createFilteredReducer(profileSelectorSlice, action => get(action, 'payload.name') === 'tf'),
    pwdProfileSelector:createFilteredReducer(profileSelectorSlice, action => get(action, 'payload.name') === 'pwd'),

  })(state, action);
};

const defaultMiddleware = getDefaultMiddleware();

let middleware = [];
middleware.push(defaultMiddleware[0]); //immutableStateInvariant

if (NODE_ENV === 'development') middleware.push(defaultMiddleware[1]); //serializableStateInvariant
middleware.push(promise);

export default configureStore({
  reducer: rootReducer,
  middleware,
});

function createFilteredReducer(reducerFunction, reducerPredicate) {
  return (state, action) => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
    return shouldRunWrappedReducer ? reducerFunction(state, action) : state;
  }
};