import React, { useContext, useState, useMemo, useCallback } from 'react';
import MaterialReactTable from 'material-react-table';
import feathers from 'services/feathers';
import { get, isEmpty } from 'lodash';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import lookupKiosk from 'features/lookup/Kiosk.json';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from 'hooks/useAuth';
import Decimal from 'decimal.js';
import EditModal from 'features/crudModal/Edit.js';
import * as Yup from 'yup';
import { toMongoFilter, toMongoSort } from 'utils/query';
import dayjs from 'dayjs';

function BCALogs() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const ability = useContext(AbilityContext);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const validationSchema = Yup.object().shape({
    remark: Yup.string().nullable(),
  });

  const [ editModalOpen, setEditModalOpen ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'createdAt', desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const filters = toMongoFilter(columnFilters);
      const sort = toMongoSort(sorting);
      const query = {
        ...(
          !!globalFilter && {
            '$text': {
              '$search': `${globalFilter}`
            }
          }
        ),
        ...(
          !isEmpty(filters) && {
            ...filters
          }
        ),
        $skip: pagination.pageIndex * pagination.pageSize,
        $limit: pagination.pageSize,
        $populate: [{ path: 'owner', select: ['_id', 'username'] }],
        $sort: sort
      };

      try {
        const response = await feathers.service('balance-check-adjustment-logs').find({ query });
        return response;
      } catch (err) {
        setGlobalErrorMessage(err);
        return null;
      }
    },
    keepPreviousData: true,
  });

  const typeOptions = useMemo(
    () => {
      return Object.keys(lookupKiosk).map((k) => {
        return {
          text: lookupKiosk[k],
          value: k
        }
      });
    }, []
  );

  const columns = useMemo(
    () => {
      const ownerColumn = (userRole === 'user') ? null : {
        accessorFn: (row) => {
          return get(row, 'executeBy.username');
        },
        id: 'executeBy.username',
        header: t('Owner'),
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          enableCreating: false
        }
      };

      const commonColumns = [
        {
          accessorKey: '_id',
          header: t('Id'),
          enableSorting: false,
          enableEditing: false,
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'kioskType',
          header: t('Kiosk Type'),
          filterSelectOptions: typeOptions,
          filterVariant: 'multi-select',
          Cell: ({ renderedCellValue }) => {
            const humanType = get(lookupKiosk, renderedCellValue, '');
            return <Box component='span'>{humanType}</Box>
          },
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'kioskName',
          header: t('Kiosk Name'),
          enableColumnFilter: false,
          enableSorting: false,
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'kioskUsername',
          header: t('Kiosk Username'),
          enableColumnFilter: false,
          enableSorting: false,
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'balanceBefore',
          header: t('Balance Before'),
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ renderedCellValue, row, cell }) => {
            const balanceStr = renderedCellValue?.$numberDecimal ?? '0';
            const decBalance = new Decimal(balanceStr);
            return <Box component='span'>{decBalance.toFixed(2)}</Box>
          },
          meta: {
            inputMode: 'numeric',
            initialValue: 0,
            enableCreating: false,
          }
        },
        {
          accessorKey: 'balanceAfter',
          header: t('Balance After'),
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ renderedCellValue, row, cell }) => {
            const balanceStr = renderedCellValue?.$numberDecimal ?? '0';
            const decBalance = new Decimal(balanceStr);
            return <Box component='span'>{decBalance.toFixed(2)}</Box>
          },
          meta: {
            inputMode: 'numeric',
            initialValue: 0,
            enableCreating: false
          }
        },
        {
          accessorKey: 'discrepancy',
          header: t('Discrepancy'),
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ renderedCellValue, row, cell }) => {
            const balanceStr = renderedCellValue?.$numberDecimal ?? '0';
            const decBalance = new Decimal(balanceStr);
            return <Box component='span'>{decBalance.toFixed(2)}</Box>
          },
          meta: {
            inputMode: 'numeric',
            initialValue: 0,
            enableCreating: false
          }
        },
        {
          accessorKey: 'remark',
          header: t('Remark'),
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorKey: 'createdAt',
          header: t('Created At'),
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'updatedAt',
          header: t('Updated At'),
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
          meta: {
            enableCreating: false
          }
        },
      ];

      return ownerColumn ? [ownerColumn, ...commonColumns] : commonColumns;
    }, [t, userRole, typeOptions]
  );

  const handleUpdateRow = useCallback(
    async (values) => {
      const { _id, ...data } = values;
      try {
        await feathers.service('balance-check-adjustment-logs').patch(_id, data);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(t('Delete confirmation', { text: row.getValue('name') }))
      ) {
        return;
      }

      const kioskId = row.getValue('_id');
      try {
        await feathers.service('balance-check-adjustment-logs').remove(kioskId);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [t, refetch, setGlobalErrorMessage]
  );

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: t('Actions'),
          },
        }}
        data={data?.data ?? []}
        initialState={{
          columnVisibility: {
            _id: false,
            createdAt: false,
          },
          showColumnFilters: false
        }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('Error loading data'),
            }
          : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Stack direction='row' spacing={1}>
            <Tooltip arrow title={t('Refresh Data')}>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        renderRowActions={({ row, table }) => {
          const canEdit = ability.can('update', subject('balanceCheckAdjustmentLogs', row?.original));
          const canDelete = ability.can('delete', subject('balanceCheckAdjustmentLogs', row?.original));

          return (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {
                canEdit && (
                 <Tooltip arrow placement="left" title={t('Edit')}>
                    <IconButton onClick={() => {
                      setEditData(row?.original);
                      setEditModalOpen(true);
                    }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              {
                canDelete && (
                 <Tooltip arrow placement="right" title={t('Delete')}>
                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Box>
          );
        }}
        onEditingRowSave={handleUpdateRow}
        rowCount={data?.total ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <EditModal
        modelName='balanceCheckAdjustmentLogs'
        validationSchema={validationSchema}
        editData={editData}
        open={editModalOpen}
        columns={columns}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleUpdateRow}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const BCALogsWithReactQueryProvider = () => (
  <QueryClientProvider client={queryClient}>
    <BCALogs />
  </QueryClientProvider>
);

export default BCALogsWithReactQueryProvider;
