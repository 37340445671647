import {
  setSelectedProductId,
  setPoint,
  setTransactionDt,
  setRemark,
  setSelectedGameId,
  setStatus,
  increaseSuccessCount,
  reset,
} from './slice';
import { toNumber } from 'lodash';

export const updateSelectedProductId = id => dispatch => {
  dispatch(setSelectedProductId(id));
  dispatch(setSelectedGameId(null));
};

export const updateDefaultGameId = (gameId, productId) => dispatch => {
  dispatch(setSelectedGameId(gameId));
  dispatch(setSelectedProductId(productId));
};

export const updatePoint = (pointStr) => dispatch => {
  const point = toNumber(pointStr);
  dispatch(setPoint(point));
};

export const updateTransactionDt = (dt) => dispatch => {
  dispatch(setTransactionDt(dt));
};

export const updateRemark = (remark) => dispatch => {
  dispatch(setRemark(remark));
};

export const updateSelectedGameId = id => dispatch => {
  dispatch(setSelectedGameId(id));
};

export const updateStatus = status => dispatch => {
  dispatch(setStatus(status));
};

export const updateSuccessCount = () => dispatch => {
  dispatch(increaseSuccessCount());
};

export const resetUi = () => dispatch => {
  dispatch(reset());
};
