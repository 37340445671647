import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#A4A4A4'
    },
    secondary: {
      main: '#FF7676'
    }
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'sans-serif',
      'Helvetica',
      'Arial'
    ].join(','),
  },
});