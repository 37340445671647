import React, { useState, useContext, createContext } from 'react';
import feathers from 'services/feathers';
import ability from 'casl/ability';
import { get } from 'lodash';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [isIdle, setIsIdle] = useState(true);
  const [user, setUser] = useState(null);

  const login = async (username, password) => {
    try {
      setIsIdle(false);
      const res = await feathers.authenticate({
        strategy: 'local',
        username,
        password
      });
      const u = get(res, 'user', null);
      const rules = get(res, 'rules', []);
      setUser(u);
      ability.update(rules);
    } catch (err) {
      setUser(null);
      ability.update([]);
      localStorage.clear();
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const relogin = async () => {
    try {
      setIsIdle(false);
      const res = await feathers.reAuthenticate();
      const u = get(res, 'user', null);
      const rules = get(res, 'rules', []);
      setUser(u);
      ability.update(rules);
    } catch (err) {
      setUser(null);
      ability.update([]);
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const logout = async () => {
    try {
      setUser(null);
      ability.update([]);
      localStorage.clear();

      setIsIdle(false);
      await feathers.logout();
      await feathers.io.close();
      await feathers.io.open();
    } catch (err) {
      throw err;
    } finally {
      setIsIdle(true);
    }
  };

  const update = (user) => {
    setUser(user);
  };

  return {
    isIdle,
    user,
    login,
    relogin,
    logout,
    update,
  };
}
