import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  selectedProductId: null,
  point: 0,
  transactionDt: new Date(),
  remark: '',
  selectedGameId: null,
  status: 'idle',
  successCount: 0
};

export const slice = createSlice({
  name: 'pendingWithdrawal',
  initialState,
  reducers: {
    setSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload;
    },
    setPoint: (state, action) => {
      state.point = action.payload;
    },
    setTransactionDt: (state, action) => {
      state.transactionDt = action.payload;
    },
    setRemark: (state, action) => {
      state.remark = action.payload;
    },
    setSelectedGameId: (state, action) => {
      state.selectedGameId = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    increaseSuccessCount: (state, action) => {
      state.successCount = state.successCount + 1;
    },
    reset: (state, action) => {
      const defaultState = { ...initialState };
      defaultState.transactionDt = new Date();
      return defaultState;
    }
  }
});

export const {
  setSelectedProductId,
  setPoint,
  setTransactionDt,
  setRemark,
  setSelectedGameId,
  setStatus,
  increaseSuccessCount,
  reset,
} = slice.actions;

export default slice.reducer;

