import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'sans-serif',
      'Helvetica',
      'Arial'
    ].join(','),
  },
});