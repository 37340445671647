import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import ProfileSelector from 'features/profileSelector/ProfileSelector';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ProductSelector from 'features/order/ProductSelector';
import CompanyBankSelector from 'features/companyBankSelector/CompanyBankSelector';
import ProductOperation from 'features/productOperation/ProductOperationWd';
import TransactionSaver from 'features/order/TransactionSaver';
import CommonContext from 'features/context/commonContext';
import Loader from 'features/loader/Loader';
import { find, get } from 'lodash';
import CustomerBankViewer from 'features/customerBankViewer/CustomerBankViewer';
import LastTransactionViewer from 'features/lastTransactionViewer/LastTransactionViewer';
import Button from '@mui/material/Button';
import feathers from 'services/feathers';
import { useNavigate, useParams, Link, unstable_useBlocker as useBlocker } from 'react-router-dom';
import dayjs from 'dayjs';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import GameIdSelector from 'features/gameIdSelector/GameIdSelector';
import { useAuth } from 'hooks/useAuth';
import ConfirmDialog from 'features/confirmDialog/ConfirmDialog';
import ConfirmNavigation from 'features/order/ConfirmNavigation';

// redux store
import { useDispatch, useSelector } from 'react-redux';

import * as wdActions from './store/actions';
import * as wdSelectors from './store/selectors';
import { getProfile } from 'features/profileSelector/store/selectors';
import { resetUi as resetProfileSelector, updateDefaultProfile } from 'features/profileSelector/store/actions';

const REDUCER_PREFIX = 'wd';

export default function Withdrawal (props) {
  const dispatch = useDispatch();
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const selectedProfile = useSelector(getProfile(REDUCER_PREFIX));
  const selectedBankId = useSelector(wdSelectors.getSelectedBankId);
  const transactionDt = useSelector(wdSelectors.getTransactionDt);
  const amount = useSelector(wdSelectors.getAmount);
  const voidAmount = useSelector(wdSelectors.getVoidAmount);
  const point = useSelector(wdSelectors.getPoint);
  const remark = useSelector(wdSelectors.getRemark);
  const selectedProductId = useSelector(wdSelectors.getSelectedProductId);
  const selectedGameId = useSelector(wdSelectors.getSelectedGameId );

  const isIdle = useSelector(wdSelectors.getIsIdle);
  const pendingMode = useSelector(wdSelectors.getPendingMode);
  const transactionId = useSelector(wdSelectors.getTransactionId);
  const voidTransactions = useSelector(wdSelectors.getVoidTransactions);
  const successCount = useSelector(wdSelectors.getSuccessCount);

  const { t } = useTranslation();

  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const { user } = useAuth();
  const settings = get(user, 'settings');
  const promptOnRouteTransition = get(settings, 'promptOnRouteTransition', true);
  const blocker = useBlocker(!!selectedProfile && promptOnRouteTransition);

  const params = useParams();
  const navigate = useNavigate();

  const {
    companyBanks,
    companyBanksReady,
    companyBanksError,
    kiosks: products,
    kiosksReady: productsReady,
    kiosksError: productsError
  } = useContext(CommonContext);

  useEffect(() => {
    const pendingId = get(params, 'id', null);
    if (pendingId) dispatch(wdActions.updateTransactionId(pendingId));
  }, [dispatch, params]);

  useEffect(() => {
    if (!companyBanksReady || !transactionId) return;

    let isMounted = true;
    async function fetchTransaction () {
      try {
        const transaction = await feathers.service(`/transactions`).get(transactionId);
        const isPending = get(transaction, 'isPending', false);
        if (isMounted && isPending) {
          dispatch(wdActions.updateStatus('loading'));
          const savedProfileId = get(transaction, 'profileId');
          const getProfile = await feathers.service('/profiles').get(savedProfileId);
          const savedProductType = get(transaction, 'products[0].productType');
          const savedGameId = get(transaction, 'products[0].username');
          const savedPoint = get(transaction, 'products[0].point.$numberDecimal', '0');
          //const savedTransactionDt = get(transaction, 'transactionDt');
          const savedRemark = get(transaction, 'remark', '');
          const savedProduct =  find(products, { type: savedProductType });

          dispatch(updateDefaultProfile(REDUCER_PREFIX, getProfile));
          dispatch(wdActions.restorePendingTxn(
            savedProduct._id,
            savedGameId,
            savedPoint,
            dayjs().format('YYYY-MM-DDTHH:mm'),
            savedRemark
          ));
          dispatch(wdActions.updateStatus('idle'));
        }
      } catch (err) {
        if (isMounted) {
          setGlobalErrorMessage(err);
          dispatch(wdActions.updateStatus('idle'));
        }
      }
    }
    fetchTransaction();
    return () => {
      isMounted = false;
    }
  }, [dispatch, transactionId, companyBanksReady, products, setGlobalErrorMessage]);

  useEffect(() => {
    if (!companyBanksError && !productsError) return;
    if (companyBanksError) {
      setGlobalErrorMessage(companyBanksError);
      return;
    }
    if (productsError) {
      setGlobalErrorMessage(productsError);
      return;
    }
  }, [companyBanksError, productsError, setGlobalErrorMessage]);

  const onProductSelected = (productId) => {
    dispatch(wdActions.updateSelectedProductId(productId));
  };

  const onCompanyBankSelected = (companyBankId) => {
    dispatch(wdActions.updateSelectedBankId(companyBankId));
  };

  const onAmountChanged = (event) => {
    event.preventDefault();
    dispatch(wdActions.updateAmount(event.target.value));
  };

  const onVoidAmountChanged = useCallback(
    (voidAmount) => {
      dispatch(wdActions.updateVoidAmount(voidAmount));
    }, [dispatch]
  );

  const onPointChanged = useCallback(
    (point) => {
      dispatch(wdActions.updatePoint(point));
    }, [dispatch]
  );

  const onRemarkChanged = (event) => {
    event.preventDefault();
    dispatch(wdActions.updateRemark(event.target.value));
  };

  const onTransactionDtChanged = (dt) => {
    dispatch(wdActions.updateTransactionDt(dt));
  };

  const onVoidTransactionsChanged = useCallback(
    (checked) => {
      dispatch(wdActions.updateVoidTransactions(checked));
    }, [dispatch]
  );

  const handleDefaultGameIdFound = (username, productId) => {
    dispatch(wdActions.updateDefaultGameId(username, productId));
  };

  const onOperationChangedCallback = useCallback(
    (status) => {
      dispatch(wdActions.updateStatus(status));
    }, [dispatch]
  );

  const onGameIdSelectedCallback = useCallback(
    (gameId) => {
      dispatch(wdActions.updateSelectedGameId(gameId));
    }, [dispatch]
  );

  const generateProductData = () => {
    const product = getSelectedProductFromId();
    const productType = get(product, 'type');

    return [{
      productType,
      amount: parseFloat(amount),
      point: parseFloat(point),
      action: 'withdrawal',
      username: selectedGameId
    }];
  };

  const onSaveClicked = async (event) => {
    if (pendingMode) updateTransaction();
    else createTransaction();
  };

  const tryToSave = (event) => {
    if (successCount <= 0 && pendingMode === false) {
      setConfirmOpen(true);
    } else {
      onSaveClicked(event);
    }
  };

  const updateTransaction = async () => {
    try {
      dispatch(wdActions.updateStatus('saving'));
      const data = {
        pendingBankId: selectedBankId,
        pendingAmount: parseFloat(amount),
        remark,
        transactionDt,
        isPending: false
      };

      await feathers.service('transactions').patch(transactionId, data);
      setGlobalMessage(t('Pending WD saved'), 'success');
      newOrder();
      dispatch(wdActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(wdActions.updateStatus('idle'));
    }
  };

  const createTransaction = async () => {
    try {
      dispatch(wdActions.updateStatus('saving'));
      const profileId = get(selectedProfile, '_id');
      const productData = generateProductData();

      const data = {
        profileId,
        products: productData,
        bankId: selectedBankId,
        amount: parseFloat(amount),
        remark,
        transactionDt,
        action: 'withdrawal'
      };

      await feathers.service('transactions').create(data);
      setGlobalMessage(t('Withdrawal saved'), 'success');
      newOrder();
      dispatch(wdActions.updateStatus('idle'));
    } catch (err) {
      setGlobalErrorMessage(err);
      dispatch(wdActions.updateStatus('idle'));
    }
  };

  function newOrder () {
    dispatch(wdActions.resetUi());
    dispatch(resetProfileSelector(REDUCER_PREFIX));

    if (transactionId) {
      navigate('/order/withdrawal', { replace: false });
    }
  };

  const getSelectedProductFromId = () => {
    return find(products, { _id: selectedProductId }) || null;
  };

  const isDisabled = useMemo(
    () => {
      return !selectedProfile || !selectedProfile.isEnabled;
    }, [selectedProfile]
  );

  if (!companyBanksReady || !productsReady) return <Loader open={true} />;

  return (
    <Box>
      <ConfirmNavigation blocker={blocker} />
      <ConfirmDialog
        title={`${t('Incomplete Order Confirmation')}`}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={onSaveClicked}
      >
        {t('Save without point withdrawal')}
      </ConfirmDialog>
      <Loader open={!isIdle} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined'>
                {
                  pendingMode ?
                  <ProfileSelector
                    reducerPrefix={REDUCER_PREFIX}
                    disabledForPending={true}
                  /> :
                  <ProfileSelector
                    reducerPrefix={REDUCER_PREFIX}
                    onDefaultGameIdFound={handleDefaultGameIdFound}
                  />
                }
              </Paper>
            </Grid>
            {
              selectedProfile &&
              (
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 1 }}>
                    <LastTransactionViewer
                      profile={selectedProfile}
                      voidAmount={voidAmount}
                      setVoidAmount={onVoidAmountChanged}
                      voidTransactions={voidTransactions}
                      setVoidTransactions={onVoidTransactionsChanged}
                    />
                  </Paper>
                </Grid>
              )
            }
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right' }}>
                <Link to='/transactions/pending-withdrawal' style={{ color: 'inherit', textDecoration: 'none' }}>
                  <Button color='warning' variant='outlined'>{t('Check Pending')}</Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                {
                  pendingMode ?
                  <ProductSelector
                    value={selectedProductId}
                    products={products}
                    onChange={onProductSelected}
                    disabled={true}
                  /> :
                  <ProductSelector
                    value={selectedProductId}
                    products={products}
                    onChange={onProductSelected}
                    disabled={isDisabled}
                  />
                }
              </Paper>
            </Grid>
            {
              selectedProductId && selectedProfile &&
              (
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 1 }}>
                    <ProductOperation
                      disabled={pendingMode}
                      product={getSelectedProductFromId()}
                      profile={selectedProfile}
                      point={point.toString()}
                      setPoint={onPointChanged}
                      onChange={onOperationChangedCallback}
                      gameId={selectedGameId}
                      successCountUpdater={wdActions.updateSuccessCount}
                    >
                      <GameIdSelector
                        onChange={onGameIdSelectedCallback}
                        value={selectedGameId}
                        product={getSelectedProductFromId()}
                        profile={selectedProfile}
                        disabled={!isIdle || pendingMode}
                      />
                    </ProductOperation>
                  </Paper>
                </Grid>
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ p: 1 }}>
                <CompanyBankSelector
                  value={selectedBankId}
                  companyBanks={companyBanks}
                  onChange={onCompanyBankSelected}
                  disabled={isDisabled}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant='outlined' sx={{ px: 1, py: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      variant='inline'
                      ampm={false}
                      label={t('Datetime')}
                      value={transactionDt}
                      onChange={onTransactionDtChanged}
                      views={['day', 'hours', 'minutes', 'seconds']}
                      inputFormat="YYYY/MM/DD HH:mm:ss"
                      invalidDateMessage={t('Invalid Date Format')}
                      disabled={isDisabled}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id='amount'
                      label={t('Amount')}
                      value={amount.toString()}
                      onChange={onAmountChanged}
                      InputProps={{
                        disabled: isDisabled,
                        type: 'number'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='remark'
                      label={t('Remark')}
                      value={remark}
                      onChange={onRemarkChanged}
                      multiline
                      maxRows={4}
                      InputProps={{
                        disabled: isDisabled,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {
              selectedProfile &&
              (
                <Grid item xs={12}>
                  <Paper variant='outlined' sx={{ p: 1 }}>
                    <CustomerBankViewer profile={selectedProfile} />
                  </Paper>
                </Grid>
              )
            }
            {
              selectedProductId && selectedProfile &&
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'right' }}>
                  <TransactionSaver onClick={tryToSave} />
                </Box>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};