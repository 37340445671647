import React from 'react';
import { useField } from 'formik';
import TextField from '@mui/material/TextField';

export default function FormikText(props) {
  const [ field, meta ] = useField(props);

  return (
    <TextField
      { ...field }
      { ...props }
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}
