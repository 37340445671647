import React from 'react';
import { useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function FormikCheckbox(props) {
  const { label } = props;
  const [ field, , helpers ] = useField({ ...props, type: 'checkbox' });
  const { setValue } = helpers;

  const handleOnChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          { ...field }
          { ...props }
          onChange={handleOnChange}
        />
      }
    />
  );
}
