import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import feathers from 'services/feathers';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as captchaActions from './store/actions';
import * as captchaSelectors from './store/selectors';

export default function CaptchaBridgeDialog (props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const captchaRequest = useSelector(captchaSelectors.getImage);
  const [ open, setOpen ] = useState(true);
  const [ captchaAnswer, setCaptchaAnswer ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  useEffect(() => {
    const captchaService = feathers.service('/captcha');

    const onCaptchaRequest = (data) => {
      const isDialogCaptcha = get(data, 'isDialogCaptcha', false);
      if (!isDialogCaptcha) return;
      dispatch(captchaActions.updateImage(data));
      setOpen(true);
    };

    const onCaptchaResponse = (data) => {
      dispatch(captchaActions.resetImage());
      setOpen(false);
    };

    captchaService.on('request', onCaptchaRequest);
    captchaService.on('response', onCaptchaResponse);

    return () => {
      captchaService.removeListener('request', onCaptchaRequest);
      captchaService.removeListener('response', onCaptchaResponse);
    };
  }, [dispatch]);

  const handleClose = () => {
    dispatch(captchaActions.resetImage());
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaAnswer) return;
    try {
      const captchaService = feathers.service('/captcha');
      let data = { ...captchaRequest };
      delete data.captcha;
      setIsSubmitting(true);
      await captchaService.update(null, {
        captchaAnswer, ...data
      });
      setOpen(false);
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setCaptchaAnswer('');
      setIsSubmitting(false);
    }
  };

  const onCaptchaAnswerChanged = (event) => {
    event.preventDefault();
    setCaptchaAnswer(event.target.value);
  };

  if (captchaRequest === null) return null;

  return (
    <Dialog fullWidth={true} maxWidth='xs' open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <form onSubmit={ event => event.preventDefault() } autoComplete='off' noValidate>
        <DialogTitle id='form-dialog-title'>{t('Captcha Verification')}</DialogTitle>
          <DialogContent>
            <Box
              component='img'
              alt={`Captcha ${captchaRequest?.type} ${captchaRequest?._id}`}
              src={`data:image/png;base64,${captchaRequest?.captcha}`}
              title={`${t('Captcha')} ${captchaRequest?.type} ${captchaRequest?._id}`}
              width="100%"
            />
            <TextField
              fullWidth
              autoFocus
              id='captcha-answer'
              label={t('Catpcha Answer')}
              value={captchaAnswer}
              onChange={onCaptchaAnswerChanged}
              InputProps={{
              }}
            />
            {
              errorMessage && <Alert severity='error'>{errorMessage}</Alert>
            }
          </DialogContent>
          <DialogActions>
            <Button variant='contained' type='submit' disabled={isSubmitting} color='primary' onClick={handleSubmit}>
              {t('Submit')}
            </Button>
          </DialogActions>
        </form>
    </Dialog>
  );
};

/*CaptchaBridgeDialog.propTypes = {
};
*/