import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  image: null
};

export const slice = createSlice({
  name: 'captcha',
  initialState,
  reducers: {
    setImage: (state, action) => {
      state.image = action.payload;
    },
    reset: (state, action) => {
      return initialState
    }
  }
});

export const {
  setImage,
  reset,
} = slice.actions;

export default slice.reducer;
