import ContentContext from './commonContext';
import React from 'react';
import useFeathersService from 'hooks/useFeathersService';

function CommonProvider (props) {
  const queryFetchAll = {
    query: {
      $limit: -1
    }
  };

  const { data: bonuses, ready: bonusesReady, error: bonusesError } = useFeathersService('bonuses', queryFetchAll);
  const { data: companyBanks, ready: companyBanksReady, error: companyBanksError } = useFeathersService('company-banks', queryFetchAll);
  const { data: kiosks, ready: kiosksReady, error: kiosksError } = useFeathersService('kiosks', queryFetchAll);

  return (
    <ContentContext.Provider value={{
      bonuses,
      bonusesError,
      bonusesReady,
      companyBanks,
      companyBanksError,
      companyBanksReady,
      kiosks,
      kiosksError,
      kiosksReady,
    }}>
      {props.children}
    </ContentContext.Provider>
  );
};

export default CommonProvider;