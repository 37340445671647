import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MuiCard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import feathers from 'services/feathers';
import { get, set, random, includes } from 'lodash';
import { actionToEnum } from './mapping';
import Alert from '@mui/material/Alert';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoopIcon from '@mui/icons-material/Loop';
import { formatMessage } from './helper';
import sound from 'assets/sounds/juntos.mp3';
import soundError from 'assets/sounds/notification_child.mp3';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useAuth } from 'hooks/useAuth';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import GameDialog from 'features/gameDialog/GameDialog';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Card = styled(MuiCard, {
  shouldForwardProp: (prop) => prop !== 'isErrorOccurred',
})(({ theme, isErrorOccurred }) => ({
  minWidth: 275,
  ...(
    !!isErrorOccurred && {
      border: `2px dotted ${theme.palette.error.main}`,
      borderStyle: 'dotted',
      animation: 'breath 1000ms infinite ease-in-out'
    }
  ),
  '@keyframes breath': {
    'from': {
      'borderStyle': 'dotted'
    },
    'to': {
      'borderStyle': 'solid'
    }
  },
}));

export default function OperationCard (props) {
  const { t } = useTranslation();
  const { kioskSetting, remoteRefresh, kioskBalance } = props;
  const [ audio ] = useState(new Audio(sound));
  const [ audioError ] = useState(new Audio(soundError));
  const [ action, setAction ] = useState(null);
  const [ username, setUsername ] = useState('');
  const [ amount, setAmount ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');
  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);
  const [ status, setStatus ] = useState('idle');
  const [ captchaRequest, setCaptchaRequest ] = useState(null);
  const [ captchaAnswer, setCaptchaAnswer ] = useState('');
  const [ localKioskBalance, setLocalKioskBalance ] = useState('-');
  const [ localKioskMessage, setLocalKioskMessage ] = useState(null);
  const [ openSnackbar, setOpenSnackBar ] = useState(false);
  const [ gameLog, setGameLog ] = useState(null);
  const { user } = useAuth();
  const showKioskCredential = user?.settings?.showKioskCredential ?? false;
  const hideGameLogOperation = user?.settings?.hideGameLogOperation ?? false;
  const userId = get(user, '_id');
  const [ hasGameLog, setHasGameLog ] = useState(false);
  const [ gameLogStatusUpdate, setGameLogStatusUpdate ] = useState(null);

  const kioskType = useMemo(
    () => {
      return get(kioskSetting, 'type');
    }, [kioskSetting]
  );

  const kioskId = useMemo(
    () => {
      return get(kioskSetting, '_id');
    }, [kioskSetting]
  );

  useEffect(() => {
    setLocalKioskBalance(kioskBalance);
  }, [kioskBalance]);

  useEffect(() => {
    if (!remoteRefresh) return;
    setStatus('refresh');
  }, [remoteRefresh]);

  useEffect(() => {
    if (status !== 'refresh') return;

    function generateDataForRefresh () {
      let data = {};
      set(data, 'action', actionToEnum['probal']);
      set(data, 'request.kioskSetting._id', kioskSetting._id);
      return data;
    };

    async function refreshProductBalance () {
      const data = generateDataForRefresh();
      setAction(null);
      setLocalKioskBalance('-');
      setLocalKioskMessage(null);
      feathers.service('/action-logs').create(data);
    };

    refreshProductBalance();
  }, [status, userId, kioskSetting]);

  useEffect(() => {
    if (status !== 'action') return;

    function generateData () {
      let data = {};
      set(data, 'action', actionToEnum[action]);
      set(data, 'request.kioskSetting._id', kioskSetting._id);
      switch (action) {
        case 'dp':
        case 'wd':
          set(data, 'request.username', username);
          set(data, 'request.amount', amount);
          break;
        case 'new':
          break;
        case 'pwd':
          set(data, 'request.username', username);
          set(data, 'request.newPassword', newPassword);
          break;
        case 'bal':
          set(data, 'request.username', username);
          break;
        case 'log':
          set(data, 'request.username', username);
          //const startDate = dayjs('2023-03-02 21:17:52').format();
          //const endDate = dayjs('2023-03-02 22:32:30').format();
          set(data, 'request.startDate', startDate);
          set(data, 'request.endDate', endDate);
          break;
        default:
      };
      return data;
    };

    async function performAction () {
      const data = generateData();
      setLocalKioskMessage(null);
      feathers.service('/action-logs').create(data);
    };

    performAction();
  }, [status, action, amount, kioskSetting, newPassword, userId, username, startDate, endDate]);

  useEffect(() => {
    if (status !== 'reset') return;
    if (action !== 'bal' && action !== 'log') setUsername('');
    setAmount('');
    setNewPassword('');
    setStartDate(null);
    setEndDate(null);
    setStatus('idle');
  }, [status, action]);

  const gameLogStorageKey = useMemo(
    () => {
      const kioskType = kioskSetting?.type ?? 'Unknown';
      const storageKey = `gamelog@${kioskType}`;
      return storageKey;
    }, [kioskSetting]
  );

  useEffect(
    () => {
      const dataStr = localStorage.getItem(gameLogStorageKey);
      try {
        const data = JSON.parse(dataStr);
        if (data) {
          setHasGameLog(true);
        } else {
          setHasGameLog(false);
        }
      } catch {}
    }, [gameLogStorageKey, gameLogStatusUpdate]
  );

  useEffect(() => {
    let isMounted = true;
    const service = feathers.service('action-logs');

    const onPatched = (data) => {
      const eventKioskId = get(data, 'request.kioskSetting._id');
      if (kioskId !== eventKioskId || !isMounted) return;

      const dataAction = data?.action ?? '';
      const hasError = data?.response?.error ? true : false;

      const isProductBalance = dataAction === 'productbalance';
      const isGameLog = dataAction === 'gamelog';

      if (!hasError) {
        const productBalance = get(data, 'response.productBalance', '-');
        setLocalKioskBalance(productBalance);

        if (isProductBalance) {
          setStatus('idle');
        } else if (isGameLog) {
          try {
            localStorage.setItem(gameLogStorageKey, JSON.stringify(data));
            setGameLogStatusUpdate(new Date());
          } catch {}
          setStatus('reset');
        } else {
          const response = get(data, 'response');
          const fm = formatMessage(response);
          setLocalKioskMessage(fm);
          setStatus('reset');
        }
      } else {
        const errorMessage = get(data, 'response.error.message');
        setCaptchaRequest(null);
        setLocalKioskMessage({
          type: 'error',
          message: errorMessage
        });
        setStatus('idle');
      }
    };

    service.on('patched', onPatched);

    return () => {
      isMounted = false;
      service.removeListener('patched', onPatched);
    };

  }, [kioskId, gameLogStorageKey]);

  useEffect(() => {
    if (!includes([
      '1slot',
      '918kaya',
      'ace333',
      'apollo777',
      'king855',
      'mario-club',
      'xe88',
    ], kioskType)) return;

    const captchaService = feathers.service('/captcha');

    const onCaptchaRequest = (data) => {
      const kioskId = get(kioskSetting, '_id');
      const isDialogCaptcha = get(data, 'isDialogCaptcha', false);
      if (data._id !== kioskId || isDialogCaptcha) return;
      setCaptchaRequest(data);
    };

    const onCaptchaResponse= (data) => {
      const kioskId = get(kioskSetting, '_id');
      const isDialogCaptcha = get(data, 'isDialogCaptcha', false);
      if (data._id !== kioskId || isDialogCaptcha) return;
      setCaptchaRequest(null);
    };

    captchaService.on('request', onCaptchaRequest);
    captchaService.on('response', onCaptchaResponse);

    return () => {
      captchaService.removeListener('request', onCaptchaRequest);
      captchaService.removeListener('response', onCaptchaResponse);
    };
  }, [kioskSetting, kioskType]);

  useEffect(() => {
    if (!captchaRequest) return;
    audio.currentTime = 0;
    audio.play();
    return () => {
      audio.pause();
    }
  }, [audio, captchaRequest]);

  useEffect(() => {
    if (!localKioskMessage || localKioskMessage.type !== 'error') return;
    audioError.play();
    return () => {
      audioError.pause();
    }
  }, [audio, audioError, localKioskMessage]);

  useEffect(
    () => {
      if (action !== 'pwd') return;
      setNewPassword(randomPassword());
    }, [action]
  );

  const submitButtonText = useMemo(
    () => {
      const name = kioskSetting?.name ?? '';

      switch (action) {
        case 'dp':
          return `${t('DP')} (${name})`;

        case 'wd':
          return `${t('WD')} (${name})`;

        case 'new':
          return `${t('Create New User')} (${name})`;

        case 'pwd':
          return `${t('Update Password')} (${name})`;

        case 'bal':
          return `${t('Check Balance')} (${name})`;

        case 'log':
          return `${t('Download Game Log')} (${name})`

        default:
      }

      return '';
    }, [t, action, kioskSetting]
  );

  function randomPassword () {
    const r = random(1000, 9999);
    return `Aaaa${r}`;
  };

  const handleActionSubmit = useCallback(
    (event) => {
      event?.preventDefault();
      setStatus(prev => {
        return status === 'idle' ? 'action' : prev;
      });
    }, [status]
  );

  const handleViewGameLog = useCallback(
    (event) => {
      event?.preventDefault();

      const dataStr = localStorage.getItem(gameLogStorageKey);
      setGameLog(dataStr);
    }, [gameLogStorageKey]
  );

  const handleBalanceRefresh = useCallback(
    (event) => {
      event?.preventDefault();
      setStatus(prev => {
        return prev === 'idle' ? 'refresh' : prev;
      })
    }, []
  );

  const handleNewRandomPassword = useCallback(
    (event) => {
      event?.preventDefault();
      setNewPassword(randomPassword());
    }, []
  );

  const handleCaptchaSubmit = useCallback(
    async (event) => {
      event?.preventDefault();

      if (!captchaAnswer) return;
      try {
        const captchaService = feathers.service('/captcha');
        let cr = { ...captchaRequest };
        delete cr.captcha;
        await captchaService.update(null, {
          captchaAnswer, ...cr
        });
      } catch (err) {
        const errorMessage = get(err, 'message');
        setLocalKioskMessage({
          type: 'error',
          message: errorMessage
        });
      } finally {
        setCaptchaAnswer('');
        setCaptchaRequest(null);
      }

    }, [captchaRequest, captchaAnswer]
  );

  const handleButtonClickCb = useCallback(
    (event) => {
      const id = event?.target?.id ?? '';
      const isOff = id === action ? true : false;
      setAction(isOff ? null : id);
    }, [action]
  );

  const handleUsernameChange = useCallback(
    (event) => {
      event?.preventDefault();
      setUsername(event?.target?.value ?? '');
    }, []
  );

  const handleAmountChange = useCallback(
    (event) => {
      event?.preventDefault();
      const value = event?.target?.value || '';

      if (!value) {
        setAmount('');
      } else {
        const decAmount = new Decimal(value);
        setAmount(decAmount.abs().toNumber());
      }
    }, []
  );

  const handleNewPasswordChange = useCallback(
    (event) => {
      event?.preventDefault();
      setNewPassword(event?.target?.value ?? '');
    }, []
  );

  const handleStartDateChange = useCallback(
    (newDate) => {
      setStartDate(newDate?.toDate() ?? null);
    }, []
  );

  const handleEndDateChange = useCallback(
    (newDate) => {
      setEndDate(newDate?.toDate() ?? null);
    }, []
  );

  const handleCaptchaAnswerChange = useCallback(
    (event) => {
      event?.preventDefault();
      setCaptchaAnswer(event?.target?.value ?? '');
    }, []
  );

  const handleGameDialogClose = useCallback(
    (event) => {
      event?.preventDefault();
      setGameLog(null);
    }, []
  );

  const handleDateRangeClick = useCallback(
    (variant) => (event) => {
      event?.preventDefault();

      let ed = dayjs();
      let sd = dayjs(ed);
      switch (variant) {
        case '1h':
          sd = sd.subtract(1, 'h').startOf('h');
          break;

        case '3h':
          sd = sd.subtract(3, 'h').startOf('h');
          break;

        case '6h':
          sd = sd.subtract(6, 'h').startOf('h');
          break;

        case '12h':
          sd = sd.subtract(12, 'h').startOf('h');
          break;

        case 'today':
          sd = sd.startOf('d');
          ed = ed.endOf('d');
          break;

        case 'yesterday':
          sd = sd.subtract(1, 'd').startOf('d');
          ed = sd.endOf('d');
          break;

        default:
      }

      setStartDate(sd.format());
      setEndDate(ed.format());
    }, []
  );

  const clipboardText = useMemo(
    () => {
      const message = localKioskMessage?.message ?? '';
      const kioskSettingName = kioskSetting?.name ?? '';
      let splitMessage = message.split(', ');
      splitMessage.unshift(`🎮 ${kioskSettingName}`);
      const formattedMessage = splitMessage.join('\r\n');
      return formattedMessage;
    }, [localKioskMessage, kioskSetting]
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handleOnCopy = (event) => {
    setOpenSnackBar(true);
  };

  const handleCardClick = () => {
    audioError.pause();
  };

  const isSubmitReady = useMemo(
    () => {
      switch (action) {
        case 'dp':
        case 'wd':
          return !!(username && amount);
        case 'new':
          return true;
        case 'pwd':
          return !!(username && newPassword);
        case 'bal':
          return !!(username);
        case 'log':
          return !!(username && startDate && endDate);
        default:
          return false;
      };
    }, [action, username, amount, newPassword, startDate, endDate]
  );

  const isIdle = useMemo(
    () => {
      return status === 'idle';
    }, [status]
  );

  const generateCaptchaForm = () => {
    if (!captchaRequest) return null;
    return (
      <Box sx={{ p: 1 }} component='form' noValidate>
        <Paper elevation={8} sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                sx={{ width: '100%' }}
                component='img'
                alt={`Captcha ${captchaRequest.type} ${captchaRequest._id}`}
                src={`data:image/png;base64,${captchaRequest.captcha}`}
                title={`Captcha ${captchaRequest.type} ${captchaRequest._id}`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField required id='captcha' fullWidth label={t('Captcha')} value={captchaAnswer} onChange={handleCaptchaAnswerChange} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button variant='contained' color='secondary' type='submit' onClick={handleCaptchaSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )
  };

  const generateActionForm = () => {
    if (action === null) return null;

    const hasUsername = includes(['dp', 'wd', 'pwd', 'bal', 'log'], action);
    const hasAmount = includes(['dp', 'wd'], action);
    const hasPassword = includes(['pwd'], action);
    const hasGameLogView = includes(['log'], action);

    return (
      <Box component='form' noValidate>
        {
          !!hasUsername &&
          <TextField margin='normal' required id='username' label={t('Username')} fullWidth value={username} onChange={handleUsernameChange} />
        }
        {
          !!hasAmount &&
          <TextField margin='normal' required id='amount' label={t('Amount')} fullWidth type='number' InputProps={{ inputProps: { min: 0 } }} value={amount} onChange={handleAmountChange} />
        }
        {
          !!hasPassword &&
          <TextField margin='normal' required id='password' label={t('New Password')} fullWidth value={newPassword}
            onChange={handleNewPasswordChange}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleNewRandomPassword}
                  size="large"><LoopIcon /></IconButton>
              </InputAdornment>,
            }}
          />
        }
        {
          !!hasGameLogView &&
          <>
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ButtonGroup color='warning' size='small' variant='contained'>
                <Button onClick={handleDateRangeClick('1h')}>{t('1H')}</Button>
                <Button onClick={handleDateRangeClick('3h')}>{t('3H')}</Button>
                <Button onClick={handleDateRangeClick('6h')}>{t('6H')}</Button>
                <Button onClick={handleDateRangeClick('12h')}>{t('12H')}</Button>
                <Button onClick={handleDateRangeClick('today')}>{t('Today')}</Button>
                <Button onClick={handleDateRangeClick('yesterday')}>{t('Yesterday')}</Button>
              </ButtonGroup>
            </Box>
            <DateTimePicker
              variant='inline'
              ampm={false}
              label={t('Start Date')}
              value={startDate}
              onChange={handleStartDateChange}
              views={['day', 'hours', 'minutes', 'seconds']}
              inputFormat="YYYY/MM/DD HH:mm:ss"
              invalidDateMessage={t('Invalid Date Format')}
              renderInput={(params) => <TextField margin='normal' fullWidth {...params} />}
            />
              <DateTimePicker
              variant='inline'
              ampm={false}
              label={t('End Date')}
              value={endDate}
              onChange={handleEndDateChange}
              views={['day', 'hours', 'minutes', 'seconds']}
              inputFormat="YYYY/MM/DD HH:mm:ss"
              invalidDateMessage={t('Invalid Date Format')}
              renderInput={(params) => <TextField margin='normal' fullWidth {...params} />}
            />
          </>
        }
        <Box sx={{ my: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant='contained' color={ action === 'wd' ? 'error' : 'primary' } type='submit' onClick={handleActionSubmit} disabled={!isSubmitReady || !isIdle}>
            {submitButtonText}
          </Button>
          {!isIdle && <CircularProgress size={24} sx={{ ml: 2 }} />}
          {
            !!hasGameLogView &&
            <Button variant='contained' color='success' onClick={handleViewGameLog} disabled={!hasGameLog || !isIdle}>
              {t('Last Download')}
            </Button>
          }
        </Box>
      </Box>
    );
  };

  const isErrorOccurred = useMemo(
    () => {
      return (localKioskMessage && localKioskMessage.type === 'error');
    }, [localKioskMessage]
  );

  const collapseInIsNeeded = () => {
    return (action !== null || isErrorOccurred);
  };

  return (
    <>
      <GameDialog open={hasGameLog} data={gameLog} onClose={handleGameDialogClose} />
      <Card elevation={8} isErrorOccurred={isErrorOccurred} onClick={handleCardClick}>
        {
           generateCaptchaForm()
        }
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 3 }}>
              <Chip
                size='small'
                icon={<RefreshIcon />}
                label={`${localKioskBalance}`}
                clickable
                color='success'
                disabled={!isIdle}
                onClick={handleBalanceRefresh}
              />
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 700 }} color='text.secondary'>
                {`🔗 `}
                <Link href={kioskSetting.url} target='_blank' rel='noreferrer'>
                  {kioskSetting.type.toUpperCase()}
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ my: 2, textAlign: 'center' }}>
            <Typography variant='h5'>
              {kioskSetting.name}
            </Typography>
          </Box>
          {
            !!showKioskCredential &&
            <Grid container spacing={0.5}>
              <Grid item>
                <CopyToClipboard text={kioskSetting.username} onCopy={handleOnCopy}>
                  <Chip
                    size='small'
                    icon={<PersonIcon />}
                    label={kioskSetting.username}
                    clickable
                    color='primary'
                  />
                </CopyToClipboard>
              </Grid>
              <Grid item>
                <CopyToClipboard text={kioskSetting.password} onCopy={handleOnCopy}>
                  <Chip
                    size='small'
                    icon={<LockIcon />}
                    label={kioskSetting.password}
                    clickable
                    color='primary'
                  />
                </CopyToClipboard>
              </Grid>
              {
                !!kioskSetting.secondPassword &&
                <Grid item>
                  <CopyToClipboard text={kioskSetting.secondPassword} onCopy={handleOnCopy}>
                    <Chip
                      size='small'
                      icon={<EnhancedEncryptionIcon />}
                      label={kioskSetting.secondPassword}
                      clickable
                      color='primary'
                    />
                  </CopyToClipboard>
                </Grid>
              }
            </Grid>
          }
        </CardContent>
        <CardActions>
          <ButtonGroup sx={{
            '& .MuiButton-containedSuccess': {
              fontWeight: 700
            }
          }} color='success' fullWidth>
            <Button variant={action === 'dp' ? 'contained' : 'outlined'} id='dp' onClick={handleButtonClickCb} disabled={!isIdle}>{t('DP')}</Button>
            <Button variant={action === 'wd' ? 'contained' : 'outlined'} id='wd' onClick={handleButtonClickCb} disabled={!isIdle}>{t('WD')}</Button>
            <Button variant={action === 'new' ? 'contained' : 'outlined'} id='new' onClick={handleButtonClickCb} disabled={!isIdle}>{t('NEW')}</Button>
            <Button variant={action === 'pwd' ? 'contained' : 'outlined'} id='pwd' onClick={handleButtonClickCb} disabled={!isIdle}>{t('PWD')}</Button>
            <Button variant={action === 'bal' ? 'contained' : 'outlined'} id='bal' onClick={handleButtonClickCb} disabled={!isIdle}>{t('BAL')}</Button>
            {
              !hideGameLogOperation &&
              <Button variant={action === 'log' ? 'contained' : 'outlined'} id='log' onClick={handleButtonClickCb} disabled={!isIdle}>{t('LOG')}</Button>
            }
          </ButtonGroup>
        </CardActions>
        <Collapse in={collapseInIsNeeded()} timeout='auto' unmountOnExit>
          <Divider />
          <CardContent>
            {
              generateActionForm()
            }
            {
              localKioskMessage && localKioskMessage.message &&
              (
                <Box sx={{ mt: 2 }}>
                  <Alert
                    severity={localKioskMessage.type}
                    action={
                      <CopyToClipboard text={clipboardText} onCopy={handleOnCopy}>
                        <Button color='inherit' size='small'>
                          {t('COPY')}
                        </Button>
                      </CopyToClipboard>
                    }
                  >
                    {localKioskMessage.message}
                  </Alert>
                </Box>
              )
            }
          </CardContent>
        </Collapse>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          message={t(`Copied`)}
        />
      </Card>
    </>
  );
};

OperationCard.propTypes = {
  kioskBalance: PropTypes.any.isRequired,
  kioskSetting: PropTypes.object.isRequired,
  remoteRefresh: PropTypes.bool.isRequired,
};