import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import useFeathersService from 'hooks/useFeathersService';
import { ceil, get, head, orderBy, reduce, sumBy } from 'lodash';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function LastTransactionViewer (props) {
  const { t } = useTranslation();
  const {
    profile,
    setVoidAmount,
    setVoidTransactions,
    voidAmount,
    voidTransactions,
  } = props;
  const [ alertType, setAlertType ] = useState('info');
  const [ alertMessage, setAlertMessage ] = useState('');
  const profileId = get(profile, '_id', null);

  const params = {
    query: {
      profileId: profileId,
      isReversed: false,
      $populate: [{ path: 'bonusId', select: ['name'] }],
      $limit: 5,
      $sort: {
        transactionDt: -1
      }
    }
  };

  const predicate = (data) => {
    return data.profileId === profileId && data.isReversed === false;
  };

  const { data, ready, error } = useFeathersService('transactions', params, predicate);

  useEffect(() => {
    if (!ready) return;

    if (!data.length) {
      setAlert('info', t('No transaction records found'));
    } else {
      const last = head(orderBy(data, ['transactionDt'], ['desc']));
      const lastAction = get(last, 'action');
      const amount = get(last, 'amount.$numberDecimal', '0');
      const products = get(last, 'products', []);
      const point = sumBy(products, product => {
        const point = get(product, 'point.$numberDecimal', '0');
        return parseFloat(point);
      });

      const bonus = point - amount;

      if (lastAction === 'deposit' && bonus > 0) setVoidTransactions([last._id]);
      setAlert('info', '');
    }
  }, [data, ready, t, setVoidTransactions]);

  useEffect(() => {
    if (!error) return;
    const errorMessage = get(error, 'message', 'Unknown error');
    setAlert('error', errorMessage);
  }, [error]);

  useEffect(() => {
    if (!voidTransactions.length || !data.length) {
      setVoidAmount(0);
      return;
    }

    const totalVoid = reduce(data, (acc, transaction) => {
      const uid = transaction._id;
      const find = voidTransactions.indexOf(uid);

      if (find >= 0) {
        const amount = get(transaction, 'amount.$numberDecimal', '0');
        const products = get(transaction, 'products', []);
        const point = sumBy(products, product => {
          const point = get(product, 'point.$numberDecimal', '0');
          return parseFloat(point);
        });
        const bonus = point - amount;
        return acc += bonus;
      }
      return acc;
    }, 0);
    setVoidAmount(ceil(totalVoid, 2));
  }, [voidTransactions, data, setVoidAmount]);

  const handleToggle = (transaction) => () => {
    const uid = transaction._id;
    const currentIndex = voidTransactions.indexOf(uid);

    const newVoidTransactions = [ ...voidTransactions ];

    if (currentIndex === -1) {
      newVoidTransactions.push(uid);
    } else {
      newVoidTransactions.splice(currentIndex, 1);
    }

    setVoidTransactions(newVoidTransactions);
  };

  const setAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
  };

  const generatePrimaryLabel = (transaction) => {
    const transactionDt = get(transaction, 'transactionDt', '');
    const action = get(transaction, 'action');
    const formattedDt = dayjs(transactionDt).format('YYYY/MM/DD hh:mmA');
    const bonusName = get(transaction, 'bonusId.name', '');

    if (action === 'deposit') return (
      <div>
      {
        `${formattedDt} `
      }
        <span>
          <b style={{color: '#4caf50'}}>
            {`${t('DP+')}`}
          </b>
          <em>
            {` ${bonusName}`}
          </em>
        </span>
      </div>
    )
    return (
      <div>
      {
        `${formattedDt} `
      }
        <span>
          <b style={{color: '#e91e63'}}>
            {`${t('WD-')}`}
          </b>
        </span>
      </div>
    );
  };

  const generateSecondaryLabel = (transaction) => {
    const action = get(transaction, 'action');
    const amount = get(transaction, 'amount.$numberDecimal', '0');
    const products = get(transaction, 'products', []);
    const point = sumBy(products, product => {
      const point = get(product, 'point.$numberDecimal', '0');
      return parseFloat(point);
    });

    const bonus = point - amount;

    if (action === 'deposit') {
      return `${t('Amt')}: ${amount} ${t('Pts')}: ${point} ${t('Bonus')}: ${bonus.toFixed(2)}`;
    }
    return `${t('Amt')}: ${amount} ${t('Pts')}: ${point} ${t('Voided Amt')}: ${bonus.toFixed(2)}`;
  };

  return (
    <Box>
      <List
        dense
        aria-labelledby='transactions'
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            { `${t('Latest Transactions')} ` }
            <Chip size='small' color='secondary' label={`${t('Void')}: ${voidAmount.toFixed(2)}`} />
          </ListSubheader>
        }
      >
      {
        orderBy(data, ['transactionDt'], ['desc']).map(transaction => {
          const uid = transaction._id;
          const action = get(transaction, 'action');
          const amount = get(transaction, 'amount.$numberDecimal', '0');
          const products = get(transaction, 'products', []);
          const point = sumBy(products, product => {
            const point = get(product, 'point.$numberDecimal', '0');
            return parseFloat(point);
          });

          const bonus = point - amount;

          if (action === 'deposit' && bonus > 0) {
            const labelId = `cb-list-label-${uid}`;
            return (
              <ListItem alignItems='flex-start' key={uid} button onClick={handleToggle(transaction)}>

                <ListItemText id={uid} primary={generatePrimaryLabel(transaction)} secondary={generateSecondaryLabel(transaction)} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    checked={voidTransactions.indexOf(uid) !== -1}
                    onChange={handleToggle(transaction)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )
          };
          return (
            <ListItem alignItems='flex-start' key={uid}>
              <ListItemText id={uid} primary={generatePrimaryLabel(transaction)} secondary={generateSecondaryLabel(transaction)} />
              <ListItemSecondaryAction>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })
      }
      </List>
      {
        alertMessage && <Alert severity={alertType}>{alertMessage}</Alert>
      }
    </Box>
  );
};

LastTransactionViewer.propTypes = {
  profile: PropTypes.object.isRequired,
  voidAmount: PropTypes.number.isRequired,
  setVoidAmount: PropTypes.func.isRequired,
  voidTransactions: PropTypes.array.isRequired,
  setVoidTransactions: PropTypes.func.isRequired,
};
