import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import feathers from 'services/feathers';
import Alert from '@mui/material/Alert';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function DeleteDialog (props) {
  const { t } = useTranslation();
  const {
    gameId,
    open,
    setOpen,
  } = props;

  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isSubmitting, setSubmitting ] = useState(false);

  const _id = get(gameId, '_id');
  const username = get(gameId, 'username');

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    if (!_id) return;
    try {
      setErrorMessage('');
      await feathers.service('game-ids').remove(_id);
      setSubmitting(false);
      setOpen(false);
    } catch (err) {
      setSubmitting(false);
      setErrorMessage(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{t('Delete Game Id')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="game-id-deletion-dialog">
            {t(`Are you sure to delete?`, { subject: username })}
          </DialogContentText>
          {
            errorMessage && <Alert severity='error'>{errorMessage}</Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={handleClose} color='primary'>
            {t('No')}
          </Button>
          <Button disabled={isSubmitting} color='secondary' onClick={handleDelete}>
            {t('Yes')}
          </Button>
        </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  gameId: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
