import {
  setSelectedBankId,
  setSelectedGameId,
  setSelectedProductId,
  setTransactionDt,
  setAmount,
  setVoidAmount,
  setPoint,
  setRemark,
  setStatus,
  setPendingMode,
  setTransactionId,
  setVoidTransactions,
  increaseSuccessCount,
  reset,
} from './slice';
import {
  getVoidAmount,
  getPoint,
} from './selectors';
import { toNumber } from 'lodash';

export const updateSelectedBankId = id => dispatch => {
  dispatch(setSelectedBankId(id));
  dispatch(setTransactionDt(new Date()));
};

export const updateSelectedGameId = id => dispatch => {
  dispatch(setSelectedGameId(id));
};

export const updateDefaultGameId = (gameId, productId) => dispatch => {
  dispatch(setSelectedGameId(gameId));
  dispatch(setSelectedProductId(productId));
};

export const updateSelectedProductId = id => dispatch => {
  dispatch(setSelectedProductId(id));
  dispatch(setSelectedGameId(null));
};

export const updateTransactionDt = (dt) => dispatch => {
  dispatch(setTransactionDt(dt));
};

export const updateAmount = (amountStr) => (dispatch, getState) => {
  const amount = toNumber(amountStr);
  dispatch(setAmount(amount));
};

export const updateVoidAmount = (voidAmountStr) => (dispatch, getState) => {
  const voidAmount = toNumber(voidAmountStr);
  dispatch(setVoidAmount(voidAmount));
  dispatch(calculateFinalAmount());
};

export const updatePoint = (pointStr) => dispatch => {
  const point = toNumber(pointStr);
  dispatch(setPoint(point));
  dispatch(calculateFinalAmount());
};

const calculateFinalAmount = () => (dispatch, getState) => {
  const voidAmount = getVoidAmount(getState());
  const point = getPoint(getState());
  const finalAmount = point - voidAmount;
  dispatch(setAmount(finalAmount));
};

export const updateRemark = (remark) => dispatch => {
  dispatch(setRemark(remark));
};

export const restorePendingTxn = (pid, gid, pts, dt, remark) => async dispatch => {
  dispatch(setSelectedProductId(pid));
  dispatch(setSelectedGameId(gid));
  dispatch(setPoint(pts));
  dispatch(setTransactionDt(dt));
  dispatch(setRemark(remark));
};

export const updateStatus = status => dispatch => {
  dispatch(setStatus(status));
};

export const updatePendingMode = isPending => dispatch => {
  dispatch(setPendingMode(isPending));
};

export const updateTransactionId = id => dispatch => {
  dispatch(setTransactionId(id));
  if (id) dispatch(setPendingMode(true));
};

export const updateVoidTransactions = list => dispatch => {
  dispatch(setVoidTransactions(list));
};

export const updateSuccessCount = () => dispatch => {
  dispatch(increaseSuccessCount());
};

export const resetUi = () => dispatch => {
  dispatch(reset());
};
