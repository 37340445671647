import { get } from 'lodash';
import { initialState } from './slice';

export const getSelectedBonus = state => get(state, 'withdrawal.selectedBonus', initialState.selectedBonus);
export const getSelectedBankId = state => get(state, 'withdrawal.selectedBankId', initialState.selectedBankId);
export const getTransactionDt = state => get(state, 'withdrawal.transactionDt', initialState.transactionDt);
export const getAmount = state => get(state, 'withdrawal.amount', initialState.amount);
export const getVoidAmount = state => get(state, 'withdrawal.voidAmount', initialState.voidAmount);
export const getPoint = state => get(state, 'withdrawal.point', initialState.point);
export const getRemark = state => get(state, 'withdrawal.remark', initialState.remark);
export const getSelectedProductId = state => get(state, 'withdrawal.selectedProductId', initialState.selectedProductId);
export const getSelectedGameId = state => get(state, 'withdrawal.selectedGameId', initialState.selectedGameId);
export const getIsIdle = state => get(state, 'withdrawal.status', initialState.status) === 'idle';
export const getPendingMode = state => get(state, 'withdrawal.pendingMode', initialState.pendingMode);
export const getTransactionId = state => get(state, 'withdrawal.transactionId', initialState.transactionId);
export const getVoidTransactions = state => get(state, 'withdrawal.voidTransactions', initialState.voidTransactions);
export const getSuccessCount = state => get(state, 'withdrawal.successCount', initialState.successCount);