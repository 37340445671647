import React, { useMemo } from 'react';
import Select from 'features/formikControl/Select';
import {
  MenuItem
} from '@mui/material';
import lookup from 'features/lookup/Kiosk.json';

export default function FormikSelectKioskType(props) {
  const options = useMemo(
    () => {
      return Object.keys(lookup).map((k) => {
        return {
          text: lookup[k],
          value: k
        }
      });
    }, []
  );

  return (
    <Select
      { ...props }
    >
    {
      options.map(({ text, value }) => {
        return (
          <MenuItem key={`${value}`} value={value}>
            {
              text
            }
          </MenuItem>
        );
      })
    }
    </Select>
  );
}