import KioskUrlsContext from './kioskUrlsContext';
import React from 'react';
import useFeathersService from 'hooks/useFeathersService';

function KioskUrlsProvider (props) {
  const queryFetchAll = {
    query: {
      $limit: -1
    }
  };

  const { data, ready, error } = useFeathersService('kiosk-urls', queryFetchAll);

  return (
    <KioskUrlsContext.Provider value={{
      data,
      ready,
      error
    }}>
      {props.children}
    </KioskUrlsContext.Provider>
  );
};

export default KioskUrlsProvider;