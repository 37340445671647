import React, { useEffect, useState } from 'react';
import feathers from 'services/feathers';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useAuth } from 'hooks/useAuth';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { find, get, reduce } from 'lodash';
import DateRangePicker from 'features/dateRangePicker';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function WinLoss () {
  const { t } = useTranslation();
  const [ data, setData ] = useState(null);
  const [ total, setTotal ] = useState(null);
  const [ status, setStatus ] = useState('idle');
  const [ selectedStaffIds, setSelectedStaffIds] = useState([]);
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const staffIds = get(user, 'staffIds', []);
  const [ dateRange, onDateRangeChange ] = useState([new Date(), new Date()]);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const handleSelectedStaffIdsChange = (event) => {
    setSelectedStaffIds(event.target.value);
  };

  const onGenerateClicked = async (event) => {
    event.preventDefault();
    try {
      setStatus('loading');
      const report = await feathers.service('/transactions').find({
        query: {
          $aggReport: 'winLoss',
          dateRange: dateRange,
          staffIds: selectedStaffIds
        }
      });

      setData(report || []);

      const total = reduce(report, function (total, rowData) {
        const dpCount = get(rowData, 'dpCount', '0');
        const dpAmount = parseFloat(get(rowData, 'dpAmount.$numberDecimal', '0'));
        const dpPtAmount = parseFloat(get(rowData, 'dpPtAmount.$numberDecimal', '0'));
        const bonusPaid = parseFloat(get(rowData, 'bonusPaid.$numberDecimal', '0'));
        const wdCount = get(rowData, 'wdCount', '0');
        const wdAmount = parseFloat(get(rowData, 'wdAmount.$numberDecimal', '0'));
        const wdPtAmount = parseFloat(get(rowData, 'wdPtAmount.$numberDecimal', '0'));
        const bonusVoided = parseFloat(get(rowData, 'bonusVoided.$numberDecimal', '0'));
        const grossProfit = parseFloat(get(rowData, 'grossProfit.$numberDecimal', '0'));
        const commission = parseFloat(get(rowData, 'commission.$numberDecimal', '0'));
        const bonusLoss = parseFloat(get(rowData, 'bonusLoss.$numberDecimal', '0'));
        const nettProfit = parseFloat(get(rowData, 'nettProfit.$numberDecimal', '0'));
        total.dpCount += dpCount;
        total.dpAmount += dpAmount;
        total.dpPtAmount += dpPtAmount;
        total.bonusPaid += bonusPaid;
        total.wdCount += wdCount;
        total.wdAmount += wdAmount;
        total.wdPtAmount += wdPtAmount;
        total.bonusVoided += bonusVoided;
        total.grossProfit += grossProfit;
        total.commission += commission;
        total.bonusLoss += bonusLoss;
        total.nettProfit += nettProfit;
        return total;
      }, {
        dpCount: 0,
        dpAmount: 0,
        dpPtAmount: 0,
        bonusPaid: 0,
        wdCount: 0,
        wdAmount: 0,
        wdPtAmount: 0,
        bonusVoided: 0,
        grossProfit: 0,
        commission: 0,
        bonusLoss: 0,
        nettProfit: 0
      });
      setTotal(total);
      setStatus('idle');
    } catch (err) {
      setStatus('idle');
      setGlobalErrorMessage(err);
    }
  };

  const onResetClicked = (event) => {
    event.preventDefault();
    setData(null);
    setTotal(null);
  };

  useEffect(() => {
    if (!staffIds.length) return;
    let allStaffIds = [];
    staffIds.forEach(s => {
      allStaffIds.push(s._id);
    });
    setSelectedStaffIds(allStaffIds);
  }, [staffIds]);

  function generateContent () {
    if (status !== 'idle') {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color='secondary' />
        </Box>
      );
    }

    if (data !== null) {
      return (
        <Box>
          <Box sx={{ mb: 1 }}>
            <DateRangePicker
              id='date-range-picker-readonly'
              value={dateRange}
              disabled={true}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button variant='contained' color='secondary' onClick={onResetClicked}>
              {t('Reset')}
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label='winloss table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center' colSpan={userRole === 'user' ? 1 : 2} />
                  <TableCell sx={{ borderBottom:  theme => `3px solid ${theme.palette.success.light}` }} align='center' colSpan={3}>
                    {t('Deposit')}
                  </TableCell>
                  <TableCell align='center' colSpan={1} />
                  <TableCell sx={{ borderBottom:  theme => `3px solid ${theme.palette.error.light}` }} align='center' colSpan={3}>
                    {t('Withdrawal')}
                  </TableCell>
                  <TableCell align='center' colSpan={5} />
                </TableRow>
                <TableRow>
                  {
                    userRole === 'user' ? null : <TableCell>{t('Executer')}</TableCell>
                  }
                  <TableCell>{t('Product')}</TableCell>
                  <TableCell align='right'>{t('Count')}</TableCell>
                  <TableCell align='right'>{t('Amount')}</TableCell>
                  <TableCell align='right'>{t('Point')}</TableCell>
                  <TableCell align='right'>{t('Bonus Paid')}</TableCell>
                  <TableCell align='right'>{t('Count')}</TableCell>
                  <TableCell align='right'>{t('Amount')}</TableCell>
                  <TableCell align='right'>{t('Point')}</TableCell>
                  <TableCell align='right'>{t('Bonus Voided')}</TableCell>
                  <TableCell align='right'>{t('Gross Profit')}</TableCell>
                  <TableCell align='right'>{t('Commission')}</TableCell>
                  <TableCell align='right'>{t('Bonus Loss')}</TableCell>
                  <TableCell align='right'>{t('Nett Profit')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                data.map(rowData => {
                  const productName = get(rowData, 'product.name', 'N/A');
                  const productPercentage = get(rowData, 'product.percentage', '?');
                  const dpCount = get(rowData, 'dpCount', '0');
                  const dpAmount = parseFloat(get(rowData, 'dpAmount.$numberDecimal', '0'));
                  const dpPtAmount = parseFloat(get(rowData, 'dpPtAmount.$numberDecimal', '0'));
                  const bonusPaid = parseFloat(get(rowData, 'bonusPaid.$numberDecimal', '0'));
                  const wdCount = get(rowData, 'wdCount', '0');
                  const wdAmount = parseFloat(get(rowData, 'wdAmount.$numberDecimal', '0'));
                  const wdPtAmount = parseFloat(get(rowData, 'wdPtAmount.$numberDecimal', '0'));
                  const bonusVoided = parseFloat(get(rowData, 'bonusVoided.$numberDecimal', '0'));
                  const grossProfit = parseFloat(get(rowData, 'grossProfit.$numberDecimal', '0'));
                  const commission = parseFloat(get(rowData, 'commission.$numberDecimal', '0'));
                  const bonusLoss = parseFloat(get(rowData, 'bonusLoss.$numberDecimal', '0'));
                  const nettProfit = parseFloat(get(rowData, 'nettProfit.$numberDecimal', '0'));

                  return (
                    <TableRow key={rowData.product._id}>
                      {
                        userRole === 'user' ? null : <TableCell>{rowData.executer.name}</TableCell>
                      }
                      <TableCell>{`${productName} (${productPercentage}%)`}</TableCell>
                      <TableCell align='right'>{`${dpCount}`}</TableCell>
                      <TableCell align='right'>{`${dpAmount.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${dpPtAmount.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${bonusPaid.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${wdCount}`}</TableCell>
                      <TableCell align='right'>{`${wdAmount.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${wdPtAmount.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${bonusVoided.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${grossProfit.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${commission.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${bonusLoss.toFixed(2)}`}</TableCell>
                      <TableCell align='right'>{`${nettProfit.toFixed(2)}`}</TableCell>
                    </TableRow>
                  )
                })
              }
              <TableRow>
                <TableCell align='right' colSpan={userRole === 'user' ? 1 : 2}>
                  {t('Total')}
                </TableCell>
                <TableCell align='right'>{`${total.dpCount}`}</TableCell>
                <TableCell align='right'>{`${total.dpAmount.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.dpPtAmount.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.bonusPaid.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.wdCount}`}</TableCell>
                <TableCell align='right'>{`${total.wdAmount.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.wdPtAmount.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.bonusVoided.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.grossProfit.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.commission.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.bonusLoss.toFixed(2)}`}</TableCell>
                <TableCell align='right'>{`${total.nettProfit.toFixed(2)}`}</TableCell>
              </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    } else {
      return (
        <Box>
          <Box sx={{ mb: 1 }}>
            <DateRangePicker
              id='date-range-picker'
              onChange={onDateRangeChange}
              value={dateRange}
              required={true}
            />
          </Box>
          <Box>
            {
              generateStaffIdsSelector()
            }
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button variant='contained' color='primary' onClick={onGenerateClicked}>
              {t('Generate')}
            </Button>
          </Box>
        </Box>
      );
    };
  };

  function generateStaffIdsSelector () {
    if (!staffIds.length) return null;
    return (
      <FormControl>
        <InputLabel id='staffIdsSelectorLabel'>Staff(s)</InputLabel>
        <Select
          labelId='staffIdsSelectorLabel'
          id='staffIdsSelector'
          multiple
          value={selectedStaffIds}
          onChange={handleSelectedStaffIdsChange}
          input={<Input />}
          renderValue={(selected) => {
            let selectedName = [];
            selected.forEach(s => {
              const name = get(find(staffIds, { _id: s }), 'name');
              if (name) selectedName.push(name);
            });
            return selectedName.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {staffIds.map((staffId) => (
            <MenuItem key={staffId._id} value={staffId._id}>
              <Checkbox checked={selectedStaffIds.indexOf(staffId._id) > -1} />
              <ListItemText primary={staffId.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  };

  return (
    <Box>
      {
        generateContent()
      }
    </Box>
  );
};
