export const isPhoneNumber = (input) => {
  const reg = /^\+?([0-9]{2})[-. ]?([0-9]{2})[-. ]?([0-9]{3,4})[-. ]?([0-9]{4})$/;
  if(input.match(reg)) return true;
  return false;
}

export const sanitizePhoneNumber = (phoneNumber = '') => {
  let sanPhoneNumber = phoneNumber.replace(/-| |\+/g, '');
  const firstDigit = sanPhoneNumber[0];
  if (firstDigit === '6') sanPhoneNumber = sanPhoneNumber.substring(1);
  else if(firstDigit !== '0') sanPhoneNumber = `0${sanPhoneNumber}`;
  return sanPhoneNumber;
};

export const formatPossiblePhoneNumber = (data = '') => {
  let sanPhoneNumber = data.replace(/-| |\+|[^0-9]/g, '');
  const firstDigit = sanPhoneNumber[0];
  if (firstDigit !== '6') sanPhoneNumber = `6${sanPhoneNumber}`;

  if (sanPhoneNumber.length === 11) {
    return `${sanPhoneNumber.substring(0,2)} ${sanPhoneNumber.substring(2,4)}-${sanPhoneNumber.substring(4,7)} ${sanPhoneNumber.substring(7,11)}`;
  } else if (sanPhoneNumber.length === 12) {
    return `${sanPhoneNumber.substring(0,2)} ${sanPhoneNumber.substring(2,4)}-${sanPhoneNumber.substring(4,8)} ${sanPhoneNumber.substring(8,12)}`;
  }
  return data;
};
