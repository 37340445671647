import React, { useContext, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import feathers from 'services/feathers';
import { get, isEmpty, includes } from 'lodash';
import { AbilityContext } from 'casl/Can';
import { subject } from '@casl/ability';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useAuth } from 'hooks/useAuth';
import Decimal from 'decimal.js';
import EditModal from 'features/crudModal/Edit.js';
import * as Yup from 'yup';
import { toMongoFilter, toMongoSort } from 'utils/query';
import GameIdDialog from './GameIdDialog';
import dayjs from 'dayjs';

function Transactions(props) {
  const { selectedBankId } = props;
  const { t } = useTranslation();
  const { user } = useAuth();
  const userRole = get(user, 'role');
  const relocatePendingDp = user?.settings?.relocatePendingDp ?? false;
  const relocateAdjustment = user?.settings?.relocateAdjustment ?? false;
  const ability = useContext(AbilityContext);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const tableInstanceRef = useRef(null);

  const validationSchema = Yup.object().shape({
    transactionDt: Yup.date().required(t("Required")),
    capitalMode: Yup.bool().nullable(),
    isReversed: Yup.bool().required(t("Required")),
    remark: Yup.string().nullable(),
  });

  const [ gameIds, setGameIds ] = useState([]);
  const [ openGameIdDialog, setOpenGameIdDialog ] = useState(false);
  const [ editModalOpen, setEditModalOpen ] = useState(false);
  const [ editData, setEditData ] = useState(null);
  const [columnFilters, setColumnFilters] = useState([{ id: 'isReversed', value: false }]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{ id: 'transactionDt', desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const showBankId = useMemo(
    () => {
      if (selectedBankId?.indexOf('pending-deposit') === 0 || selectedBankId === 'capital-adjustment') return true;
      return false;
    }, [selectedBankId]
  );

  const isLegitBankId = useMemo(
    () => {
      const notLegit = [
        null,
        'pending-deposit',
        'pending-withdrawal',
        'capital-adjustment',
        'deposit-free',
        'transfer',
      ];

      return !includes(notLegit, selectedBankId);
    }, [selectedBankId]
  );

  const handleOpenGameIdDialog = useCallback(
    (products) => (event) => {
      event?.preventDefault();
      setGameIds(products);
      setOpenGameIdDialog(true);
    }, []
  );

  useEffect(
    () => {
      if (!tableInstanceRef?.current) return;
      tableInstanceRef?.current?.setColumnVisibility({
        _id: false,
        bankId: showBankId,
      });
    }, [showBankId, tableInstanceRef]
  );

  const presetFilters = useMemo(
    () => {
      if (isEmpty(selectedBankId)) return { $limit: 0 }

      switch (selectedBankId) {
        case 'pending-withdrawal':
          return {
            bankId: {
              $exists: false
            },
            action: 'withdrawal',
            isPending: true
          };

        case 'pending-deposit':
          return {
            action: 'deposit',
            isPending: true,
          };

        case 'deposit-free':
          return {
            bankId: {
              $exists: false
            },
            action: 'deposit',
            amount: 0
          }

        case 'transfer':
          return {
            bankId: {
              $exists: false
            },
            action: 'transfer'
        };

        case 'capital-adjustment':
          return {
            bankId: {
              $exists: true
            },
            isAdjustment: true,
            capitalMode: true,
          }

        default:
          return {
            bankId: selectedBankId
          };
      }
    }, [selectedBankId]
  );

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
      presetFilters,
    ],
    queryFn: async () => {
      const filters = toMongoFilter(columnFilters);

      const sort = toMongoSort(sorting);
      const query = {
        ...(
          !!globalFilter && {
            '$text': {
              '$search': `${globalFilter}`
            }
          }
        ),
        ...(
          !isEmpty(filters) && {
            ...filters
          }
        ),
        $skip: pagination.pageIndex * pagination.pageSize,
        $limit: pagination.pageSize,
        $populate: [{ path: 'executeBy', select: ['_id', 'username'] }, { path: 'bankId', select: ['name', 'colorTag'] }],
        $sort: sort,
        ...presetFilters,
      };

      try {
        const response = await feathers.service('transactions').find({ query });
        return response;
      } catch (err) {
        setGlobalErrorMessage(err);
        return null;
      }
    },
    keepPreviousData: true,
  });

  const actionOptions = useMemo(
    () => {
      return [
        { text: t('deposit'), value: 'deposit' },
        { text: t('withdrawal'), value: 'withdrawal' },
        { text: t('transfer'), value: 'transfer' },
      ];
    }, [t]
  );

  const booleanOptions = useMemo(
    () => {
      return [
        { text: t('true'), value: 'true' },
        { text: t('false'), value: 'false' },
      ];
    }, [t]
  );

  const columns = useMemo(
    () => {
      const ownerColumn = (userRole === 'user') ? null : {
        accessorFn: (row) => {
          return get(row, 'executeBy.username');
        },
        id: 'executeBy.username',
        header: t('Owner'),
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          enableCreating: false
        }
      };

      const commonColumns = [
        {
          accessorKey: '_id',
          header: t('Id'),
          enableSorting: false,
          enableEditing: false,
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'bankId',
          header: t('Bank'),
          enableSorting: false,
          enableColumnFilter: false,
          Cell: ({ renderedCellValue, row }) => {
            if (!renderedCellValue) return null;
            const bankName = renderedCellValue?.name ?? '';
            const colorTag = renderedCellValue?.colorTag ?? '#FFF';
            return (
              <Box component='span' sx={{
                bgcolor: colorTag,
                color: theme => theme.palette.getContrastText(colorTag),
                padding: 0.5,
                fontWeight: 700,
              }}>{t(bankName)}</Box>
            )
          },
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'action',
          header: t('Type'),
          filterVariant: 'select',
          filterSelectOptions: actionOptions,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'transactionDt',
          header: t('Datetime'),
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => {
            if (!renderedCellValue) return null;
            return <Box component='span'>{dayjs(renderedCellValue).format('YYYY-MM-DD HH:mm:ss')}</Box>
          },
          meta: {
            formikEditVariant: 'date-time',
          }
        },
        {
          accessorKey: 'amount',
          header: t('Amount'),
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ renderedCellValue, row, cell }) => {
            const txnAction = row.getValue('action');
            const balanceStr = renderedCellValue?.$numberDecimal ?? '0';
            let decBalance = new Decimal(balanceStr);
            if (txnAction === 'withdrawal') decBalance = decBalance.negated();
            return <Box component='span' sx={{ ...(txnAction === 'withdrawal' && { color: 'error.dark' }) }}>{decBalance.toFixed(2)}</Box>
          },
          meta: {
            inputMode: 'numeric',
            initialValue: 0
          }
        },
        {
          accessorKey: 'products',
          header: t('Points'),
          enableSorting: false,
          enableColumnFilter: false,
          Cell: ({ renderedCellValue, row }) => {
            if (!renderedCellValue?.length) return null;
            const txnAction = row.getValue('action');

            const decDp = renderedCellValue.reduce(
              (acc, current) => {
                const currentAction = current?.action ?? txnAction;
                if (currentAction !== 'deposit') return acc;
                const amountStr = current?.point?.$numberDecimal ?? '0';
                return new Decimal(amountStr).plus(acc);
              }, new Decimal(0)
            );

            const decWd = renderedCellValue.reduce(
              (acc, current) => {
                const currentAction = current?.action ?? txnAction;
                if (currentAction !== 'withdrawal') return acc;
                const amountStr = current?.point?.$numberDecimal ?? '0';
                return new Decimal(amountStr).plus(acc);
              }, new Decimal(0)
            );

            return (
              <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
                {
                  !decWd.eq(0) &&
                  <Box sx={{ mx: 0.5 }}>{decWd.toFixed(2)}</Box>
                }
                {
                  !decDp.eq(0) &&
                  <Box sx={{ mx: 0.5 }}>{decDp.negated().toFixed(2)}</Box>
                }
                <Box>
                  <IconButton
                    onClick={handleOpenGameIdDialog(renderedCellValue)}
                    size="large">
                    <ZoomInIcon />
                  </IconButton>
                </Box>
              </Box>
            );
          },
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'isPending',
          header: t('Pending'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue, row }) => {
            if (renderedCellValue === false) return <Box component='span'>{t(renderedCellValue)}</Box>

            const txnAction = row.getValue('action');
            const _id = row.getValue('_id');

            return (
              <Box component='span'>
                <Link to={`/order/${txnAction}/${_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography component='span' variant='body2' gutterBottom
                    sx={{
                      textDecoration: 'underline',
                      textDecorationColor: theme => (theme.palette.success.light),
                      textDecorationThickness: 3,
                      cursor: 'pointer',
                    }}
                  >
                    {t('true')}
                  </Typography>
                </Link>
              </Box>
            );
          },
          meta: {
            enableCreating: false,
          }
        },
        {
          accessorKey: 'isAdjustment',
          header: t('Adjustment'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            enableCreating: false
          }
        },
        {
          accessorKey: 'capitalMode',
          header: t('Capital Mode'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            formikEditVariant: 'checkbox',
            enableCreating: true
          }
        },
        {
          accessorKey: 'isReversed',
          header: t('Reversal'),
          enableColumnFilter: true,
          enableSorting: true,
          filterSelectOptions: booleanOptions,
          filterVariant: 'select',
          Cell: ({ renderedCellValue }) => {
            return <Box component='span'>{t(renderedCellValue)}</Box>
          },
          meta: {
            formikEditVariant: 'checkbox',
            initialValue: false
          }
        },
        {
          accessorKey: 'remark',
          header: t('Remark'),
          enableSorting: false,
          enableColumnFilter: false,
        },
      ];

      return ownerColumn ? [ownerColumn, ...commonColumns] : commonColumns;
    }, [t, userRole, booleanOptions, actionOptions, handleOpenGameIdDialog]
  );

  const handleUpdateRow = useCallback(
    async (values) => {
      const { _id, ...data } = values;
      try {
        await feathers.service('transactions').patch(_id, data);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [refetch, setGlobalErrorMessage]
  );

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(t('Delete confirmation', { text: row.getValue('name') }))
      ) {
        return;
      }

      const kioskId = row.getValue('_id');
      try {
        await feathers.service('transactions').remove(kioskId);
        refetch();
      } catch (err) {
        setGlobalErrorMessage(err);
      }
    }, [t, refetch, setGlobalErrorMessage]
  );

  const handleCloseGameIdDialog = useCallback(
    (event) => {
      event?.preventDefault();
      setGameIds([]);
      setOpenGameIdDialog(false);
    }, []
  );

  return (
    <Box>
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        columns={columns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: t('Actions'),
          },
        }}
        data={data?.data ?? []}
        initialState={{
          columnVisibility: {
            _id: false,
            capitalMode: false,
          },
          showColumnFilters: false,
        }}
        manualFiltering
        manualPagination
        manualSorting
        enableRowActions
        muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: t('Error loading data'),
            }
          : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={() => (
          <Stack direction='row' spacing={1}>
            <Tooltip arrow title={t('Refresh Data')}>
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            {
              (isLegitBankId && relocatePendingDp) && <Button size='small' color='secondary' variant='contained'>
                <Link to={`/order/pending-deposit/${selectedBankId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {t('Pending DP')}
                </Link>
              </Button>
            }
            {
              (isLegitBankId && relocateAdjustment) && <Button size='small' color='secondary' variant='contained'>
                <Link to={`/order/adjustment/${selectedBankId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {t('Adjustment')}
                </Link>
              </Button>
            }
          </Stack>
        )}
        renderRowActions={({ row, table }) => {
          const canEdit = ability.can('update', subject('transactions', row?.original));
          const canDelete = ability.can('delete', subject('transactions', row?.original));

          return (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {
                canEdit && (
                 <Tooltip arrow placement="left" title={t('Edit')}>
                    <IconButton onClick={() => {
                      setEditData(row?.original);
                      setEditModalOpen(true);
                    }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
              {
                canDelete && (
                 <Tooltip arrow placement="right" title={t('Delete')}>
                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Box>
          );
        }}
        onEditingRowSave={handleUpdateRow}
        rowCount={data?.total ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <EditModal
        modelName='transactions'
        validationSchema={validationSchema}
        editData={editData}
        open={editModalOpen}
        columns={columns}
        onClose={() => setEditModalOpen(false)}
        onSubmit={handleUpdateRow}
      />
      <GameIdDialog open={openGameIdDialog} onClose={handleCloseGameIdDialog} products={gameIds}/>
    </Box>
  );
};

const queryClient = new QueryClient();

const TransactionsWithReactQueryProvider = (props) => (
  <QueryClientProvider client={queryClient}>
    <Transactions { ...props } />
  </QueryClientProvider>
);

export default TransactionsWithReactQueryProvider;
